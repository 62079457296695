import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { ChangePasswordStatus } from './constants';

// User detail related
const changePasswordStatus = createReducer({
  [actions.SET_CHANGEPASSWORD_STATUS]: (state, { payload }) => payload
}, ChangePasswordStatus.IDLE);


export default combineReducers({
  changePasswordStatus
});
