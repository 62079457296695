import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import(/* webpackChunkName: "order-builder-model" */ "./model.scss");

import testModel from "../../../../assets/img/ModelTest.png";

const Model = ({ setModelForOrder, modelTypeCodes }) => {
  const [activeModel, setActiveModel] = useState();

  //Sets default active model code
  useEffect(() => {
    setActive();
  }, []);

  //Sets active model for local state and for order
  const setActive = (model) => {
    setActiveModel(model);
    setModelForOrder(model);
  };

  return (
    <div>
      {modelTypeCodes && modelTypeCodes[0] && (
        <div className="model-component-main-div order-builder-component">
          <h2 className="order-builder-component-title">Choose a model</h2>
          <Row className="">
            {modelTypeCodes.map((model, index) => (
              <Col key={index} md={6} lg={3} className='border-right'>
                <a
                  onClick={() => {
                    setActive(model.Code);
                  }}
                >
                  <div
                    className={`model-box ${
                      activeModel === model.Code ? "active" : ""
                    }`}
                  >
                    <div className="model-box-image">
                      <img src={model.ImagePath} className="img-fluid" />
                    </div>
                    <div className="model-box-text">
                      <p>{model.Name}</p>
                    </div>
                  </div>
                </a>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default Model;
