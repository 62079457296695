import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import { AddOrgUserStatus, OrgUserDetailStatus } from '../../store/constants';
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import Loading from '../../../../components/Loading/Loading';
import { get } from 'lodash';

import(/* webpackChunkName: "addOrgUser" */'./addOrgUser.scss');

const AddOrgUser = ({
  saveOrgUserStatus,
  orgUserDetailStatus,
  orgUserDetailData,
  loadOrgUserData,
  saveOrgUserData,
  currentUserDetails,
  organisationDetailData,
  loadOrganisationDetailData,
  errorStatus,
  resetSaveOrgUserStatus
}) => {
  let { orgUserId, orgCode } = useParams();
  const history = useHistory();

  // reset form data if success and redirect to org detail page
  useEffect(() => {
      if (saveOrgUserStatus === AddOrgUserStatus.SUCCESS) {
        resetSaveOrgUserStatus();
        history.push(`/admin/organisation/${orgCode}/detail`);
      }
  }, [resetSaveOrgUserStatus, saveOrgUserStatus]);
  
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    organisationCode: orgCode,
    // siteCodes: {},
    password: '',
    roleName: '',
    userId: '',
    isEditing: false
  });

  const [userSites, setUserSites] = useState({});

  const [roleList, setRoleList] = useState([]);
  const roleValues = {
    sonovasuperuser: 3,
    sonovawhs: 2,
    customeradmin: 1,
    customeruser: 0
  };

  const roles = [
    {role: 'sonovasuperuser', value: 3, display: 'Super User'},
    {role: 'sonovawhs', value: 2, display: 'WHS Admin'},
    {role: 'customeradmin', value: 1, display: 'Customer Admin'},
    {role: 'customeruser', value: 0, display: 'Customer User'}
  ];

  useEffect(() => {
    if (currentUserDetails) {
      setRoleList([{role: '', display: 'Please select'},...roles.filter(r => r.value <= roleValues[currentUserDetails.RoleName] )]);
    }
  }, [currentUserDetails]);

  // user data if editing
  useEffect(() => {
    if (orgUserId && loadOrgUserData) {
      loadOrgUserData(orgUserId);
    }
  }, [loadOrgUserData]);

  useEffect(() => {
    if (orgUserDetailData && orgUserId) {
      setUserData({
        firstName: get(orgUserDetailData, 'FirstName', '') ,
        lastName: get(orgUserDetailData, 'LastName', '') ,
        email: get(orgUserDetailData, 'EmailAddress', '') ,
        organisationCode: get(orgUserDetailData, 'OrganisationCode', ''),
        password: '',
        roleName: get(orgUserDetailData, 'RoleName', '') ,
        userId: get(orgUserDetailData, 'UserId', ''),
        isEditing: true 
      });
      setUserSites(
        [...get(orgUserDetailData, 'SiteCodes',[])].reduce((accum, siteCode) => ({...accum, [siteCode]: true}), {})
      );
    }
    else if (orgCode){
      setUserData({
        firstName: '',
        lastName: '',
        email: '',
        organisationCode: orgCode,
        // siteCodes: {},
        password: '',
        roleName: '',
        userId: '',
        isEditing: false
      })
    }
  }, [orgUserDetailData]);

  useEffect(() => {
    if (!organisationDetailData && loadOrganisationDetailData) {
      loadOrganisationDetailData(orgCode);
    }
  }, [organisationDetailData, loadOrganisationDetailData]);

  // site list
  const [siteList, setSiteList] = useState([]);
  useEffect(() => {
    if (organisationDetailData) {
      // setSiteList([{SiteCode: '', SiteName: 'Please select'}, ...organisationDetailData['Sites']]);
      setSiteList([...organisationDetailData['Sites']]);
    }
  }, [organisationDetailData]);

  function handleSubmit(e) {
    e.preventDefault();
    //console.log('user data being submitted',userData);
    saveOrgUserData({
      ...userData,
      siteCodes: Object.keys(userSites).filter(siteCode => userSites[siteCode])
    }, organisationDetailData);
    // history.push(`/admin/organisation/${orgCode}/detail`);
  }

  function handleChange(e) {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value 
    });
  }

  function handleCheckBoxChange(e) {
    let currentVal = get(userSites, e.target.name, false);
    setUserSites(
      {
        ...userSites,
        [e.target.name]: !currentVal
      }
    );
  }

  if (saveOrgUserStatus === AddOrgUserStatus.LOADING || 
    orgUserDetailStatus === OrgUserDetailStatus.LOADING) {
    return (
      <Col>
        <div>
          <Loading />
        </div>
      </Col>
    )
  }

  // if (saveOrgUserStatus === AddOrgUserStatus.SUCCESS) {
  //   return (
  //     <Redirect to={`/admin/organisation/${orgCode}/detail`} />
  //   );
  // }

  return (
    <div className="dashboard-title">
      <h1>{`${orgUserId ? 'Edit' : 'Add'} an Organisation User`}</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Row>
                <Col className="pb-4" xs={3}>
                  <Form.Label>First Name</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="firstName"
                    // defaultValue={""}
                    value={userData.firstName}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col className="pb-4" xs={3}>
                  <Form.Label>Last Name</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="lastName"
                    // defaultValue={""}
                    value={userData.lastName}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col className="pb-4" xs={3}>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="email"
                    name="email"
                    // defaultValue={""}
                    value={userData.email}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col>
                
                <Col className="pb-4" xs={3}>
                  <Form.Label>Site</Form.Label>
                </Col>
                <Col xs={9}>
                  {/* <Form.Select
                    name='siteCode'
                    value={userData.siteCode ? userData.siteCode : ''}
                    className='me-2 form-control'
                    aria-level='siteCode'
                    onChange={handleChange}
                    required
                  >
                    {
                      siteList &&
                      siteList.map((site, index) => (
                        <option value={site.SiteCode} key={index}>{site.SiteName}</option>
                      ))
                    }

                  </Form.Select> */}
                  {
                    siteList && 
                    siteList.map((site, index) => (
                      <Form.Check 
                        key={site.SiteCode}
                        type='checkbox'
                        label={site.SiteName}
                        onChange={handleCheckBoxChange}
                        // checked={get(userData['siteCodes'], site.SiteCode, false)}
                        checked={get(userSites, site.SiteCode, false)}
                        name={site.SiteCode}
                      />
                    ))
                  }
                  

                  
                </Col>

                {
                  !userData.isEditing &&
                  <Fragment>
                    <Col className="pb-4" xs={3}>
                      <Form.Label>Password</Form.Label>
                    </Col>
                    <Col xs={9}>
                      <Form.Control
                        type="password"
                        name="password"
                        // defaultValue={""}
                        value={userData.password}
                        className="me-2"
                        aria-label="Stock"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Fragment> 
                }
                <Col className="pb-4" xs={3}>
                  <Form.Label>Role</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Select
                    name='roleName'
                    value={userData.roleName ? userData.roleName : ''}
                    className='me-2 form-control'
                    aria-level='roleName'
                    onChange={handleChange}
                    required
                  >
                    {
                      roleList &&
                      roleList.map((role, index) => (
                        <option value={role.role} key={index}>{role.display}</option>
                      ))
                    }
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          {
            saveOrgUserStatus === AddOrgUserStatus.ERROR && 
            <Col xs={12} className='alert alert-danger mt-2'>
              <p>{errorStatus}</p>
            </Col>
          }
          <Col xs={12}>
            <div className="float-right">
              <Button type="submit" variant="success mt-2">
                Save
              </Button>
              <Link to={`/admin/organisation/${orgCode}/detail`}>
                <Button variant="warning mt-2 ml-2">
                  Cancel
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        
        
        
      </Form>
      
    </div>
  )
};

AddOrgUser.propTypes = {
  saveOrgUserData: PropTypes.func,
  saveOrgUserStatus: PropTypes.string,
  orgUserDetailStatus: PropTypes.string,
  orgUserDetailData: PropTypes.object,
  loadOrgUserData: PropTypes.func,
  currentUserDetails: PropTypes.object,
  organisationDetailData: PropTypes.object,
  loadOrganisationDetailData: PropTypes.func,
  errorStatus: PropTypes.string,
  resetSaveOrgUserStatus: PropTypes.func
};

export default AddOrgUser;
