import { createSelector } from 'reselect';

const login = state => state.modules.login;

export const getLoginStatus = createSelector(
  login,
  state => state.loginStatus
);

export const getErrorStatus = createSelector(
  login,
  state => state.errorStatus
);


export const getProfileData = createSelector(
  login,
  state => state.profileData
);