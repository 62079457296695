import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { OrganisationListStatus } from '../../store/constants';
import Loading from '../../../../components/Loading/Loading';
import { add, get } from 'lodash';
import { Link, Redirect, useHistory } from 'react-router-dom';
import(/* webpackChunkName: "organisationList" */'./organisationList.scss');

const OrganisationList = ({
    organisationListStatus,
    organisationListData,
    loadOrganisationListData,
    profileData
  }) => {
    // const [currentUser,setCurrentUser] = useState();
    // useEffect(() => {
    //   setCurrentUser(profileData);
    // }, [profileData]);
    const [pageProfileData,setPageProfileData] = useState();

    useEffect(() => {
      if (loadOrganisationListData) {
        loadOrganisationListData();
      }
    }, [loadOrganisationListData]);

    useEffect(() => {
      if (profileData) {
        setPageProfileData(profileData);
      }
    }, [profileData]);

    const history = useHistory();

    function handleClick(path) {
      history.push(path);
    }

    const OrganisationTableRow = ({ org }) => {
      const orgCode = get(org, "OrganisationCode", "default");
      const orgName = get(org, "OrganisationName", "default");
      const orgPhonakCode = get(org, "PhonakCatalogueCode", "default");
      const orgPhonakName = get(org, "PhonakCatalogueName", "default");
      const orgUnitronCode = get(org, "UnitronCatalogueCode", "default");

      return (
        <tr>
          <td><Link to={`/admin/organisation/${orgCode}/detail`}>{orgName}</Link></td>
          <td>{orgPhonakName}</td>
        </tr>
      );
    };

    if (organisationListStatus === OrganisationListStatus.LOADING) {
      return (
        <Col>
          <div>
            <Loading />
          </div>
        </Col>
      )
    }

    if (organisationListStatus === OrganisationListStatus.ERROR) {
      return (
        <Redirect to='/' />
      );
    }

    if (pageProfileData) {
        return (
          
          <div>
            {/* Dashboard: <span>{organisationListStatus}</span> */}
            <Row className='mt-5'>
              <Col xs={12}>
                <h1 style={{display: 'inline-block', 'marginRight': '1rem'}}>Organisations</h1>
                {
                  pageProfileData && (pageProfileData['RoleName'] ==='sonovasuperuser' || pageProfileData['RoleName'] === 'sonovawhs') &&
                  <Button
                    onClick={() => handleClick('/admin/organisation/add')}
                  >
                    Add
                  </Button>
                }
              </Col>
              <Col xs={12} className="mt-3">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Catalogue</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      organisationListData && 
                      organisationListData.map((org, index) => (
                        <OrganisationTableRow key={org['OrganisationCode']} org={org} />
                      ))
                    }
                  </tbody>
                </Table>
              </Col>
            </Row>
            
          </div>
          
        );
    }

    return (
      <div></div>
    );
};

OrganisationList.propTypes = {
  loadOrganisationListData: PropTypes.func,
  organisationListStatus: PropTypes.string,
  organisationListData: PropTypes.array,
  profileData: PropTypes.object
};

export default OrganisationList;
