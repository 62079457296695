import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { OrganisationDetailStatus, AddEditOrganisationStatus, CatalogueListStatus } from './constants';

// User detail related
const organisationDetailStatus = createReducer({
  [actions.SET_ORGANISATION_STATUS]: (state, { payload }) => payload
}, OrganisationDetailStatus.IDLE);

const organisationDetailData = createReducer({
  [actions.SET_ORGANISATION_DATA]: (state, { payload }) => payload
}, OrganisationDetailStatus.IDLE);

const saveOrganisationStatus = createReducer({
  [actions.SET_SAVE_ORGANISATION_STATUS]: (state, { payload }) => payload
}, AddEditOrganisationStatus.IDLE);

const catalogueList = createReducer({
  [actions.SET_CATALOGUELIST_DATA]: (state, { payload }) => payload
}, CatalogueListStatus.IDLE);

const catalogueListStatus = createReducer({
  [actions.SET_CATALOGUELIST_STATUS]: (state, { payload }) => payload
}, CatalogueListStatus.IDLE);


export default combineReducers({
  organisationDetailStatus,
  organisationDetailData,
  saveOrganisationStatus,
  catalogueList,
  catalogueListStatus
});
