import { connect } from 'react-redux';

import RelatedProducts from './relatedProducts';
import { getRelatedProductsStatus, getRelatedProductsData } from './store/selectors';
import { getOrderBuilderData, getCartData } from '../orderBuilder/store/selectors';
import { loadRelatedProducts } from './store/action-creators';
import { addOrderToCart } from '../orderBuilder/store/action-creators';

const mapStateToProps = (state) => ({
  relatedProductsStatus: getRelatedProductsStatus(state),
  relatedProductsData: getRelatedProductsData(state),
  allProductsData: getOrderBuilderData(state),
  cartData: getCartData(state),
});

const mapDispatchToProps = {
  loadRelatedProducts,
  addOrderToCart
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedProducts);