// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import * as actionCreators from './action-creators';
import { START_FORGOT_PASSWORD } from './actions';
import { ForgotPasswordStatus } from './constants';
import { get } from 'lodash';
import api from '../../../utilities/api';

export function* rootSaga() {
  yield takeLatest(START_FORGOT_PASSWORD, startForgotPassword);
}

export function* startForgotPassword(data) {
  try {
    yield put(actionCreators.setForgotPasswordStatus(ForgotPasswordStatus.LOADING));
    const email = get(data, "payload", "");
    
    const body = {
      Email: email
    }
    //console.log('body', body);

    yield call(api.post, '/api/account/forgotpassword', body);
  
    yield put(actionCreators.setForgotPasswordStatus(ForgotPasswordStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setForgotPasswordStatus(ForgotPasswordStatus.ERROR));
  }
}