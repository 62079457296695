import React, { useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { connect } from 'react-redux';
import Cookies from "universal-cookie";
import Dashboard from "../../../modules/dashboard/dashboard.container";
import Profile from "../../../modules/profile/profile.container";
import OrderDetail from "../../../modules/orderDetail/orderDetail.container";
import OrderBuilder from "../../../modules/orderBuilder/orderBuilder.container";
import TopLevelCategory from "../../../modules/storeHome/views/topLevelCategory/topLevelCategory.container";
import FamilyCategory from "../../../modules/storeHome/views/familyCategory/familyCategory.container";
import RelatedProducts from "../../../modules/relatedProducts/relatedProducts.container";
import ProductSummary from "../../../modules/productSummary/productSummary.container";
import Cart from "../../../modules/cart/cart.container";
import OrderConfirmation from "../../../modules/orderConfirmation/orderConfirmation.container";
import OrderSuccess from "../../../modules/orderSuccess/orderSuccess.container";
import OrganisationList from '../../../modules/organisations/views/organisationList/organisationList.container';
import OrganisationUserList from '../../../modules/organisations/views/organisationUserList/organisationUserList.container';
import OrganisationDetail from '../../../modules/organisations/views/organisationDetail/organisationDetail.container';

import PrivacyPolicy from "../../../modules/privacyPolicy/privacyPolicy";
import PrivacyPolicyUnitron from "../../../modules/privacyPolicyUnitron/privacyPolicyUnitron";
import ChangePassword from '../../../modules/changePassword/changePassword.container';

import AddOrgUser from '../../../modules/organisations/views/addOrgUser/addOrgUser.container';
import AddEditOrganisation from '../../../modules/addEditOrganisation/addEditOrganisation.container';
import AdministratorDetail from '../../../modules/administrators/views/adminstratorDetail/administratorDetail.container';
import SiteDetail from '../../../modules/sites/views/siteDetail/siteDetail.container';
import AddEditSite from '../../../modules/sites/views/addEditSite/addEditSite.container';
import AddSiteUser from '../../../modules/sites/views/addSiteUser/addSiteUser.container';

import AdministratorsList from '../../../modules/administrators/views/administratorsList/administratorsList.container';
import AddAdmin from '../../../modules/administrators/views/addAdmin/addAdmin.container';
import AdminDataChecker from '../../../modules/administrators/views/adminDataChecker/adminDataChecker.container';

import { setProfileDataFromLocalStorage } from '../../../modules/login/store/action-creators';
import { loadProducts } from '../../../modules/storeHome/store/action-creators';
import { addOrderToCart } from '../../../modules/orderBuilder/store/action-creators';
import { getCartData } from '../../../modules/orderBuilder/store/selectors';
import { Container, Row, Col } from "react-bootstrap";

import config from '../../../config.js'
//-----------------PHONAK HEADER & FOOTER------------------
 import PTopNavbar from "../../../components/TopNavbar/TopNavbar";
 import PFooter from "../../../components/Footer/Footer";

//-----------------UNITRON HEADER & FOOTER------------------
import UTopNavbar from "../../../components/UTopNavbar/TopNavbar";
import UFooter from "../../../components/UFooter/Footer";



import(/* webpackChunkName: "private-layout" */ "./private-layout.scss");
/*
  Here you'll want to make a Route of pages you'd want to see for
  Build and include header + footer here (/core/layouts/private/header/ etc)
*/

const PrivateLayout = ({
    setProfileDataFromLocalStorage,
    loadProducts,
    addOrderToCart,
    cartData
  }) => {
  let { path, url } = useRouteMatch();
  let history = useHistory();

  useEffect(() => {
    document.title = config.siteName
 }, []);

  useEffect(() => {
    const cookies = new Cookies();
    const loginToken = cookies.get("LoginToken");
    //console.log(loginToken);
    if (!loginToken) {
      history.push({ pathname: "/login" });
      return;
    }
    const profileData = localStorage.getItem("profileData");
    if (profileData == null) {
      // Initialize page views count
      // Go to login page
      cookies.remove("LoginToken");
      history.push({ pathname: "/login" });
    } else{
      //console.log("profileData", profileData);
      // Set profile data
      var obj = JSON.parse(profileData);

      setProfileDataFromLocalStorage(obj);
      // Load Inital Items
      loadProducts();

      const cartDataFromStorage = localStorage.getItem("cartData");
      if(cartDataFromStorage == null) {
      } else {
        var cartObj = JSON.parse(cartDataFromStorage);
        addOrderToCart(cartObj);
      }
    }
  }, []);

  useEffect(() => {
    if(cartData) {
      localStorage.setItem("cartData", JSON.stringify(cartData));
    }
  }, [cartData]);

  return (
    <div>
      {config.siteName && config.siteName==="UNITRON" &&  <UTopNavbar /> }
      {config.siteName && config.siteName==="PHONAK" && <PTopNavbar /> }
      <Container className="main-container-min-height">
        <Row>
          <Col>
            <div className="auth-view">
              <div className="content-container container">
                <Switch>
                  <Route exact path="/" component={TopLevelCategory} />
                  <Route exact path="/store/:productId" component={FamilyCategory} />
                  <Route exact path="/store/:categoryId/:productId/:itemId" component={OrderBuilder} />
                  <Route exact path="/store/:categoryId/:productId" component={OrderBuilder} />
                  {/* <Route
                    path="/order-builder/:productId"
                    component={OrderBuilder}
                  /> */}
                  <Route
                    path="/related-products/:productId"
                    component={RelatedProducts}
                  />
                  <Route exact path="/product-summary/:productCode" component={ProductSummary} />
                  <Route exact path="/cart" component={Cart} />
                  <Route
                    exact
                    path="/order-confirmation"
                    component={OrderConfirmation}
                  />
                  <Route exact path="/order-confirmation/success" component={OrderSuccess} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/profile" component={Profile} />
                  <Route
                    exact
                    path="/profile/order-history/:orderId"
                    component={OrderDetail}
                  />
                  <Route exact path="/admin/organisation" component={OrganisationList} />
                  <Route exact path="/admin/organisation/add" component={AddEditOrganisation} />
                  <Route exact path="/admin/organisation/:organisationCode/edit" component={AddEditOrganisation} />
                  <Route exact path="/admin/organisation/:organisationCode/detail" component={OrganisationDetail} />
                  <Route exact path="/admin/organisation/:organisationCode/users/:adminId/detail" component={AdministratorDetail} />
                  <Route exact path="/admin/organisation/:organisationCode/users/:userId/changepassword" component={ChangePassword} />

                  <Route exact path="/admin/organisation/:orgCode/users/add" component={AddOrgUser} />
                  <Route exact path="/admin/organisation/:orgCode/users/:orgUserId/edit" component={AddOrgUser} />

                  <Route exact path="/admin/adminusers" component={AdministratorsList} />
                  <Route exact path="/admin/adminusers/add" component={AddAdmin} />
                  <Route exact path="/admin/adminusers/:adminId/edit" component={AddAdmin} />
                  <Route exact path="/admin/adminusers/:adminId/detail" component={AdministratorDetail} />
                  <Route exact path="/admin/adminusers/:userId/changepassword" component={ChangePassword} />

                  <Route exact path="/admin/site/:siteCode/detail" component={SiteDetail} />
                  <Route exact path="/admin/organisation/:orgCode/sites/:siteCode/edit" component={AddEditSite} />
                  <Route exact path="/admin/organisation/:orgCode/sites/add" component={AddEditSite} />
                  {/* <Route exact path="/admin/site/:siteCode/users/add" component={AddSiteUser} /> */}
                  <Route exact path="/admin/site/:siteCode/users/:adminId/detail" component={AdministratorDetail} />
                  <Route exact path="/admin/site/:siteCode/users/:userId/changepassword" component={ChangePassword} />
                  
                  <Route exact path="/admin/datachecker/:categoryId/:productId" component={AdminDataChecker} />
                  {config.siteName && config.siteName==="UNITRON" &&  <Route exact path="/privacy-policy" component={PrivacyPolicyUnitron} /> }
                  {config.siteName && config.siteName==="PHONAK" &&  <Route exact path="/privacy-policy" component={PrivacyPolicy} /> }
                  
                </Switch>
              </div>
              {/*Footer here */}
            </div>
          </Col>
        </Row>
      </Container>
      {config.siteName && config.siteName==="UNITRON" &&  <UFooter /> }
      {config.siteName && config.siteName==="PHONAK" && <PFooter /> }

    </div>
  );
};

const mapStateToProps = (state) => ({
  cartData: getCartData(state),
});

const mapDispatchToProps = {
  setProfileDataFromLocalStorage,
  loadProducts,
  addOrderToCart,
}

// export default PrivateLayout;
export default connect(mapStateToProps, mapDispatchToProps)(PrivateLayout);