import { create } from '../../../utilities/action-helpers';
import * as actions from './actions';

export const loadAdministratorsListData = create(actions.LOAD_ADMINISTRATORSLIST_DATA);
export const setAdministratorsListStatus = create(actions.SET_ADMINISTRATORSLIST_STATUS);
export const setAdminstratorsListData = create(actions.SET_ADMINISTRATORSLIST_DATA);

export const loadAdministratorDetailData = create(actions.LOAD_ADMINISTRATORDETAIL_DATA);
export const setAdministratorDetailStatus = create(actions.SET_ADMINISTRATORDETAIL_STATUS);
export const setAdminstratorDetailData = create(actions.SET_ADMINISTRATORDETAIL_DATA);

export const saveAdminData = create(actions.SAVE_ADMIN_DATA);
export const setSaveAdminStatus = create(actions.SET_SAVE_ADMIN_STATUS);
export const setErrorStatus = create(actions.SET_ERROR_STATUS);

export const resetSaveAdminForm = create(actions.RESET_SAVEADMIN_FORM);


