import { create } from '../../../utilities/action-helpers';
import * as actions from './actions';

export const loadLogin = create(actions.LOAD_LOGIN_DATA);
export const setLoginStatus = create(actions.SET_LOGIN_STATUS);
export const setErrorStatus = create(actions.SET_ERROR_STATUS);
export const startLogin = create(actions.START_LOGIN);
export const setProfileData = create(actions.SET_PROFILE_DATA);
export const setProfileDataFromLocalStorage = create(actions.SET_PROFILE_DATA_FROM_LOCAL_STORAGE);

