import { connect } from 'react-redux';
import AdministratorDetail from './administratorDetail';
import { getAdministratorDetailStatus, getAdministratorDetailData } from '../../store/selectors';
import { loadAdministratorDetailData } from '../../store/action-creators';


const mapStateToProps = (state) => ({
  administratorDetailStatus: getAdministratorDetailStatus(state),
  administratorDetailData: getAdministratorDetailData(state)
});

const mapDispatchToProps = {
  loadAdministratorDetailData
};

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorDetail);