// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import * as actionCreators from './action-creators';
import * as orgActionCreators from '../../organisations/store/action-creators';
import { CHANGE_PASSWORD, SET_CHANGEPASSWORD_STATUS } from './actions';
import { ChangePasswordStatus } from './constants';
import api from '../../../utilities/api';
import { get } from 'lodash';

export function* rootSaga() {
  yield takeLatest(CHANGE_PASSWORD, changePassword);
}

// user detail related
export function* changePassword(data) {
  try {
    yield put(actionCreators.setChangePasswordStatus(ChangePasswordStatus.LOADING));
    const userDetails = get(data, "payload", {});
    const body = {
      NewPassword: userDetails['newPassword']
    };
    
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };
    
    //console.log('userdetails',userDetails);
    //console.log('password body',body);
    const response = yield call(api.put, `/api/account/${userDetails['userId']}/savenewpassword`, body, {headers:headerParams});
    
    
    yield put(actionCreators.setChangePasswordStatus(ChangePasswordStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setChangePasswordStatus(ChangePasswordStatus.ERROR));
  }
}

