import { connect } from 'react-redux';

import Profile from './profile';
import { getProfileStatus, getOrderData, getChangePasswordStatus } from './store/selectors';
import { getProfileData } from '../login/store/selectors';
import { loadProfile, startEditProfile, startChangePassword,  } from './store/action-creators';


const mapStateToProps = (state) => ({
  profileStatus: getProfileStatus(state),
  orderData: getOrderData(state),
  profileData: getProfileData(state),
  changePasswordStatus: getChangePasswordStatus(state),
});

const mapDispatchToProps = {
  loadProfile,
  startEditProfile,
  startChangePassword
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);