import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { RelatedProductsStatus } from './constants';

const relatedProductsStatus = createReducer({
  [actions.SET_RELATED_PRODUCTS_STATUS]: (state, { payload }) => payload
}, RelatedProductsStatus.IDLE);

const relatedProductsData = createReducer({
  [actions.SET_RELATED_PRODUCTS_DATA]: (state, { payload }) => payload
}, RelatedProductsStatus.IDLE);

export default combineReducers({
  relatedProductsStatus,
  relatedProductsData
});
