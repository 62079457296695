import { createSelector } from 'reselect';

const orderBuilder = state => state.modules.orderBuilder;

export const getCartStatus = createSelector(
  orderBuilder,
  state => state.cartStatus
);

export const getCartData = createSelector(
  orderBuilder,
  state => state.cartData
);