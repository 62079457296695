// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import * as actionCreators from './action-creators';
import { START_RESET_PASSWORD } from './actions';
import { ResetPasswordStatus } from './constants';
import { get } from 'lodash';
import api from '../../../utilities/api';

export function* rootSaga() {
  yield takeLatest(START_RESET_PASSWORD, startResetPassword);
}

export function* startResetPassword(data) {
  try {
    yield put(actionCreators.setResetPasswordStatus(ResetPasswordStatus.LOADING));
    const password = get(data, "payload", "");
    const passwordResetToken = get(data, "0", "");
    
    const body = {
      PasswordResetToken: passwordResetToken,
      NewPassword: password
    }

    //console.log(body);

    yield call(api.put, '/api/account/changepassword', body);
  
    yield put(actionCreators.setResetPasswordStatus(ResetPasswordStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setResetPasswordStatus(ResetPasswordStatus.ERROR));
  }
}