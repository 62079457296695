// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import * as actionCreators from './action-creators';
import Cookies from 'universal-cookie';
import { LOAD_RELATED_PRODUCTS_DATA } from './actions';
import { RelatedProductsStatus } from './constants';
import { get } from 'lodash';
import api from '../../../utilities/api';

export function* rootSaga() {
  yield takeLatest(LOAD_RELATED_PRODUCTS_DATA, loadRelatedProducts);
}

export function* loadRelatedProducts(ItemCode) {
  try {
    //console.log("1");
    yield put(actionCreators.setRelatedProductsStatus(RelatedProductsStatus.LOADING));
    //console.log("Start load related products");
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };
    const ItemCodeForAPI = get(ItemCode, 'payload', "");
    //console.log("ItemCodeForAPI", ItemCodeForAPI);

    const response = yield call(api.get, `/api/relateditem/${ItemCodeForAPI}`, {headers:headerParams});
    //console.log("response", response);
    yield put(actionCreators.setRelatedProductsData(response.data));
    
    yield put(actionCreators.setRelatedProductsStatus(RelatedProductsStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setRelatedProductsStatus(RelatedProductsStatus.ERROR));
  }
}