import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { AdministratorDetailStatus } from '../../store/constants';
import Loading from '../../../../components/Loading/Loading';
import { useParams, useHistory, Link } from 'react-router-dom';
import { get } from 'lodash';

import(/* webpackChunkName: "administratorDetail" */'./administratorDetail.scss');

const AdministratorDetail = ({
  administratorDetailStatus,
  administratorDetailData,
  loadAdministratorDetailData
}) => {
  const { adminId, organisationCode, siteCode } = useParams();

  const [adminDetail, setAdminDetail] = useState({
    userId: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    organisationCode: '',
    organisationName: '',
    sites: [],
    roleName: '',
    canEdit: false
  });
  const [ pageAdministratorDetailData, setPageAdministratorDetailData ] = useState();
  
  const history = useHistory();

  function handleClick(path) {
    history.push(path);
   
  }

  useEffect(() => {
    if (adminId && loadAdministratorDetailData) {
      loadAdministratorDetailData(adminId);
    }
  }, [loadAdministratorDetailData, adminId]);

  useEffect(() => {
    if (administratorDetailData) {
      setPageAdministratorDetailData(administratorDetailData);
    }
  }, [administratorDetailData]);
  

  useEffect(() => {
    if (administratorDetailData) {
      setAdminDetail({
        userId: get(administratorDetailData, 'UserId', ''),
        firstName: get(administratorDetailData, 'FirstName', ''),
        lastName: get(administratorDetailData, 'LastName', ''),
        emailAddress: get(administratorDetailData, 'EmailAddress', ''),
        organisationCode: get(administratorDetailData, 'OrganisationCode', ''),
        organisationName: get(administratorDetailData, 'OrganisationName', ''),
        sites: get(administratorDetailData, 'Sites', []),
        roleName: get(administratorDetailData, 'RoleName', ''),
        canEdit: get(administratorDetailData, 'CanEdit', false)
      });
    }
  }, [administratorDetailData]);


  // const AdminTableRow = ({ admin }) => {
  //   const firstName = get(admin, "FirstName", "default");
  //   const lastName = get(admin, "LastName", "default");
  //   const emailAddress = get(admin, "EmailAddress", "default");
  //   const organisationCode = get(admin, "OrganisationCode", "default");
  //   const siteId = get(admin, "SiteId", "default");

  //   return (
  //     <tr>
  //       <td>{`${firstName} ${lastName}`}</td>
  //       <td>{emailAddress}</td>
  //       <td>{organisationCode}</td>
  //       <td>{siteId}</td>
  //     </tr>
  //   );
  // };
  

  if (administratorDetailStatus === AdministratorDetailStatus.LOADING) {
    return (
      <Col>
        <div>
          <Loading />
        </div>
      </Col>
    );
  }

  return (
    <div>
      {
        pageAdministratorDetailData &&
        <Fragment>
          <Row className='mt-5'>
            <Col xs={12} className='mb-3'>
            <Link to={organisationCode ? `/admin/organisation/${organisationCode}/detail` : 
              siteCode ? `/admin/site/${siteCode}/detail` :
              `/admin/adminusers`}>
                {
                  organisationCode ? 'Back to Organisation' :
                  siteCode ? 'Back to Site' :
                  'Back to Administrators'
                }
                  {/* Back to Administrators */}
                </Link>
            </Col>
            <Col xs={12}>
              <h1 style={{display: 'inline-block', 'marginRight': '1rem'}}>{`${adminDetail['firstName']} ${adminDetail['lastName']}`}</h1>
              {
                adminDetail.canEdit &&
                <>
                  <Button
                    onClick={() => handleClick(organisationCode ?
                      `/admin/organisation/${organisationCode}/users/${adminDetail['userId']}/edit` :
                      `/admin/adminusers/${adminDetail['userId']}/edit`
                      )}
                    className='mr-3'
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => handleClick(organisationCode ?
                      `/admin/organisation/${organisationCode}/users/${adminDetail['userId']}/changepassword` :
                      `/admin/adminusers/${adminDetail['userId']}/changepassword`
                      )}
                  >
                    Change Password
                  </Button>
                </>
              }
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <table className="userDetailTable mt-3">
                <tbody>
                  <tr>
                    <td>Email</td>
                    <td>{adminDetail['emailAddress']}</td>
                  </tr>
                  <tr>
                    <td>Organisation</td>
                    <td><Link to={`/admin/organisation/${adminDetail['organisationCode']}/detail`}>{adminDetail['organisationName']}</Link></td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>        
                  
          {/* <Row>
            <Col md={4}><p>Email</p></Col>
            <Col md={8}><p>{adminDetail['emailAddress']}</p></Col>
          </Row>

          <Row>
            <Col md={4}><p>Organisation</p></Col>
            <Col md={8}><p><Link to={`/admin/organisation/${adminDetail['organisationCode']}/detail`}>{adminDetail['organisationName']}</Link></p></Col>
          </Row> */}

          <Row className="mt-3">
            <Col xs={12}>
              <h2 style={{display: 'inline-block', 'marginRight': '1rem'}}>Sites</h2>
            </Col>
            <Col xs={12}>
              <table className="userDetailTable">
                {/* <thead>
                  <tr>
                    <th>Site Name</th>
                  </tr>
                </thead> */}
                <tbody>
                  {
                    adminDetail &&
                    adminDetail.sites.map((site, index) => (
                      <tr key={index}>
                        {/* <td>{siteCode}</td> */}
                        {/* <td><Link to={`/admin/site/${site.SiteCode}/detail`} >{site.SiteName}</Link></td> */}
                        <td>{site.SiteName}</td>

                      </tr>
                    ))
                  }
                  
                </tbody>
              </table>
            </Col>
          </Row>
        </Fragment>
      }
      
    </div>
  );
};

AdministratorDetail.propTypes = {
  administratorDetailStatus: PropTypes.string,
  administratorDetailData: PropTypes.object,
  loadAdministratorDetailData: PropTypes.func
};

export default AdministratorDetail;
