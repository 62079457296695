import { create } from 'lodash';
import { createSelector } from 'reselect';

// State is the application wide state
const changePassword = state => state.modules.changePassword; // this is from what you named the reducer in the global reducers.js

export const getChangePasswordStatus = createSelector(
  changePassword,
  state => state.changePasswordStatus
);
// export const getOrganisationDetailData = createSelector(
//   addEditOrganisation,
//   state => state.organisationDetailData
// );

