import { connect } from 'react-redux';

import OrderDetail from './orderDetail';
import { getOrderDetailStatus, getOrderDetailData } from './store/selectors';
import { getProfileData } from '../login/store/selectors';
import { loadOrderDetail } from './store/action-creators';


const mapStateToProps = (state) => ({
  orderDetailStatus: getOrderDetailStatus(state),
  orderDetailData: getOrderDetailData(state),
  profileData: getProfileData(state),
});

const mapDispatchToProps = {
  loadOrderDetail
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);