import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { get, find } from "lodash";
import(
  /* webpackChunkName: "order-builder-ear-pieces-family-model" */ "./earPiecesFamilyModel.scss"
);

import testModel from "../../../../assets/img/ModelTest.png";

const EarPiecesFamilyModel = ({ 
  productsData,
  selectedModel,
  initialProductCategory,
  setSelectedModel,
  initialModelCode
}) => {
  const [ricProductsArray, setRICProductsArray] = useState();
  const [selectedFamily, setSelectedFamily] = useState();
  const [modelsArray, setModelsArray] = useState();
  const [selectedLocalModel, setSelectedLocalModel] = useState();

  useEffect(() => {
    if (productsData) {
      // Filters products to only show RIC and BTE items for the family and mode dropdowns
      let tempRICProductsArray = [...productsData];

      tempRICProductsArray = find(tempRICProductsArray, {
        Name: "BTE/RICs",
      });
      tempRICProductsArray = get(
        tempRICProductsArray,
        "InverseParentCodeNavigation",
        []
      );
      setRICProductsArray(tempRICProductsArray);
      if(initialProductCategory !="Earpiece")
      {
        setSelectedFamily(initialProductCategory);
      }
      
    }
  }, [productsData]);

  useEffect(() => {
    // On family change update the model dropdown
    if (selectedFamily && selectedFamily != "Unknown") {
      const modelsArray = find(ricProductsArray, { Code: selectedFamily }, {});
      //console.log("On family change - selectedFamily",selectedFamily)
      //console.log(modelsArray.SoModelTypes);
      setModelsArray(modelsArray.SoModelTypes);
      if(initialModelCode)
      {
          // set initial model
          setSelectedLocalModel(initialModelCode);
          const modelObj = find(modelsArray.SoModelTypes, { Code: initialModelCode }, {});
          setSelectedModel(modelObj);
      }
    } else {
      setModelsArray([]);
      setSelectedModel("Unknown");
    }
  }, [selectedFamily]);

  const handleFamilyChange = (event) => {
    const family = event.target.value;
    setSelectedFamily(family);
  };

  const handleModelChange = (event) => {
    // On model dropdown change update selected model in both local state and item builder state.
    const model = event.target.value;
    setSelectedLocalModel(model);

    if(model != "Unknown"){
      const modelObj = find(modelsArray, { Code: model }, {});
      setSelectedModel(modelObj);
    }
    else {
      setSelectedModel("Unknown");
    }
  };

  return (
    <div className="family-model-dropdowns">
      <Row>
        <Col xs={4}>
          <h2>This item is for: </h2>
        </Col>
        <Col xs={4}>
          <div className="select">
              <p>Select Family</p>
              <select value={selectedFamily} onChange={handleFamilyChange}>
                <option value="Unknown">Unknown</option>
                {ricProductsArray &&
                  ricProductsArray.length > 0 &&
                  ricProductsArray.map((product, index) => {
                    return (
                      <option key={index} value={product.Code}>
                        {product.Name}
                      </option>
                    );
                  })}
              </select>
          </div>
        </Col>
        <Col xs={4}>
          <div className="select">
            <p>Select Model</p>
            <select value={selectedLocalModel} onChange={handleModelChange}>
              <option value="Unknown">Unknown</option>
              {modelsArray &&
                modelsArray.length > 0 &&
                modelsArray.map((product, index) => {
                  return (
                    <option key={index} value={product.Code}>
                      {product.Name}
                    </option>
                  );
                })}
            </select>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EarPiecesFamilyModel;
