import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Container, Row, Col } from 'react-bootstrap';
import(/* webpackChunkName: "loading" */'./loading.scss');

export default function Loading() {
	return (
		<div className='loading'>
			<CircularProgress color='success' />
		</div>
	);
}