import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import(/* webpackChunkName: "order-builder-ite-model" */ "./iteModel.scss");
import InsertLink from '@mui/icons-material/InsertLink';
import LinkOff from '@mui/icons-material/LinkOff';
import testModel from "../../../../assets/img/ModelTest.png";

const ITEModel = ({
  setITEModelForOrder,
  iteModelTypeCodes,
  leftSelected,
  rightSelected,
  setITEModelLinked,
  iteModelLinked,
  setSelectedModelRight,
  setSelectedModelLeft,
  selectedModel,
}) => {
  const [activeRightITEModel, setActiveRightITEModel] = useState();
  const [activeLeftITEModel, setActiveLeftITEModel] = useState();

  useEffect(() => {
    //Set default active model codes
    setActiveLeftITEModel();
    setActiveRightITEModel();
    // console.log("TESTING??");
    // console.log("iteModelTypeCodes", iteModelTypeCodes)
    if (iteModelTypeCodes && iteModelTypeCodes[0]) {
      setActiveLeftITEModel(iteModelTypeCodes[0].Code);
      setActiveRightITEModel(iteModelTypeCodes[0].Code);
      // setSelectedModelRight(iteModelTypeCodes[0].Code);
      // setSelectedModelLeft(iteModelTypeCodes[0].Code);
    }
  }, [iteModelTypeCodes, selectedModel]);

  useEffect(() => {
    //When new model type is selected update object for order and custom options
    const models = {
      right: activeRightITEModel,
      left: activeLeftITEModel,
    };
    // console.log("iteModelLinked - ", iteModelLinked, " models - ", models)
    setITEModelForOrder(models);
  }, [activeRightITEModel, activeLeftITEModel]);

  const setRightActive = (model) => {
    setActiveRightITEModel(model);
    if (iteModelLinked) {
      setActiveLeftITEModel(model);
    }
  };

  const setLeftActive = (model) => {
    setActiveLeftITEModel(model);
    if (iteModelLinked) {
      setActiveRightITEModel(model);
    }
  };

  return (
    <div>
      {iteModelTypeCodes && (rightSelected || leftSelected) && (
        <div className="ite-model-component-main-div order-builder-component">
          <Row className="order-builder-component-title">
            <Col xs={4}>
              <h2>Choose a model</h2>
            </Col>
            <Col xs={8}>
              <Row>
                <Col xs={5}>
                  {rightSelected && (
                    <p className="right-ear-title ear-impression-option ear-impression-option mb-0">
                      <span className="dot dot-right"></span>Right Ear
                    </p>
                  )}
                </Col>
                <Col xs={2}>
                  {rightSelected && leftSelected && (
                    <div className="text-center link-div">
                      {iteModelLinked ? <InsertLink className="linked" onClick={() => setITEModelLinked(!iteModelLinked)}/> : <LinkOff onClick={() => setITEModelLinked(!iteModelLinked)} className="unlinked" />}
                    </div>
                  )}
                </Col>
                <Col xs={5}>
                  {leftSelected && (
                    <p className="left-ear-title ear-impression-option ear-impression-option mb-0">
                      <span className="dot dot-left"></span>Left Ear
                    </p>
                  )}
                </Col>

                <Col md={6}>
                  {rightSelected && (
                    <Row className="ite-model-row-right">
                      {iteModelTypeCodes.map((model, index) => (
                        <Col key={index} xs={12}>
                          <a
                            onClick={() => {
                              setRightActive(model.Code);
                            }}
                          >
                            <div
                              className={`ite-model-box ${
                                activeRightITEModel === model.Code
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <Row>
                                <Col xs={2}>
                                  <div className="ite-model-box-image">
                                    <img
                                      src={model.ImagePath}
                                      className="img-fluid"
                                    />
                                  </div>
                                </Col>
                                <Col xs={8}>
                                  <div className="ite-model-box-text">
                                    <p>{model.Name}</p>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="active-tick"></div>
                                </Col>
                              </Row>
                            </div>
                          </a>
                        </Col>
                      ))}
                    </Row>
                  )}
                </Col>
                <Col md={6}>
                  {leftSelected && (
                    <Row className="ite-model-row-left left-side">
                      {iteModelTypeCodes.map((model, index) => (
                        <Col key={index} xs={12}>
                          <a
                            onClick={() => {
                              setLeftActive(model.Code);
                            }}
                          >
                            <div
                              className={`ite-model-box ${
                                activeLeftITEModel === model.Code
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <Row>
                                <Col xs={2}>
                                  <div className="ite-model-box-image">
                                    <img
                                      src={model.ImagePath}
                                      className="img-fluid"
                                    />
                                  </div>
                                </Col>
                                <Col xs={8}>
                                  <div className="ite-model-box-text">
                                    <p>{model.Name}</p>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="active-tick"></div>
                                </Col>
                              </Row>
                            </div>
                          </a>
                        </Col>
                      ))}
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ITEModel;
