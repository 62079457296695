import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { filter, uniqBy, findIndex, find } from "lodash";

import(/* webpackChunkName: "order-builder-receivers" */ "./receivers.scss");

import testModel from "../../../../assets/img/ModelTest.png";

const Receivers = ({ setReceiversForOrder, receiversForOrder, receivers, fullListOfReceivers }) => {
  const [activeRightReceiver, setActiveRightReceivers] = useState();
  const [activeLeftReceiver, setActiveLeftReceivers] = useState();
  const [activeRightLength, setActiveRightLength] = useState([]);
  const [activeLeftLength, setActiveLeftLength] = useState([]);
  
  const [rightLengths, setRightLengths] = useState();
  const [leftLengths, setLeftLengths] = useState();

  //Sets active recievers to NONE on load
  useEffect(() => {
    setActiveRightReceivers("None");
    setActiveLeftReceivers("None");
  }, []);

  // On change to active lengths (as multiple can be selected)
  // Run set active function
  useEffect(() => {
    setActive();
  }, [
    activeLeftLength,
    activeRightLength,
  ]);

  // Each time a new right receiver is selected set related lengths. And set active lengths to an empty array
  useEffect(() => {
    if(activeRightReceiver != "None") {
      let tempLengths = [...fullListOfReceivers];
      
      tempLengths = filter(tempLengths, {"PerformanceLevelCode": activeRightReceiver});
      tempLengths = uniqBy(tempLengths, "ModelTypeCode");
      setRightLengths(tempLengths);
    }
    else {
      setRightLengths([]);
    }
    setActiveRightLength([]);
  }, [activeRightReceiver]);

  // Each time a new left receiver is selected set related lengths. And set active lengths to an empty array
  useEffect(() => {
    if(activeLeftReceiver != "None") {
      let tempLengths = [...fullListOfReceivers];
      //console.log("Receivers.js - Filtering lengths for receiver", activeLeftReceiver );
      tempLengths = filter(tempLengths, {"PerformanceLevelCode": activeLeftReceiver});
      //console.log("Receivers.js - - found ", tempLengths.length );
      tempLengths = uniqBy(tempLengths, "ModelTypeCode");
      setLeftLengths(tempLengths);
    }
    else {
      setLeftLengths([]);
    }
    setActiveLeftLength([]);
  }, [activeLeftReceiver]);

  // Each time a new length is selected set them to the order.
  const setActive = () => {
    const activeForOrderReceivers = [];
    
    if(activeLeftLength) {
      if(activeLeftLength==0)
      {
        //console.log("Receivers.js - No left length selected - returning 0 receivers");
      }
      let tempLeftReceiver = [...fullListOfReceivers];
      
      // loop through array of lengths
      activeLeftLength.forEach((activeLength) => {
        //Find reciever matching receiver type and length
        //console.log("Receivers.js - finding for  ", activeLeftReceiver, ", " , activeLength);
        const tempLeftReceiverAfterFilter = filter(tempLeftReceiver, {"PerformanceLevelCode": activeLeftReceiver, "ModelTypeCode": activeLength});
        //console.log("Receivers.js - before getting left side - ", tempLeftReceiverAfterFilter);
        //Find side based on last character of name
        const tempLeftReceiverAfterFind = find(tempLeftReceiverAfterFilter, function(o) { return o.Name.charAt(o.Name.length - 1) === "L" });
        // push to array before setting it to the order
        activeForOrderReceivers.push(tempLeftReceiverAfterFind);
      })
      //console.log("Receivers.js - activeForOrderReceivers left - ", activeLeftLength.length, ':', activeForOrderReceivers);
    }

    if(activeRightLength) {
      let tempRightReceiver = [...fullListOfReceivers];
      //console.log("Receivers.js - # of receivers available - ", tempRightReceiver.length);
      
      activeRightLength.forEach(activeLength => {
        const tempRightReceiverAfterFilter = filter(tempRightReceiver, {"PerformanceLevelCode": activeRightReceiver, "ModelTypeCode": activeLength});
        //console.log("Receivers.js - before getting right side - ", tempRightReceiverAfterFilter);
        const tempRightReceiverAfterFind = find(tempRightReceiverAfterFilter, function(o) { return o.Name.charAt(o.Name.length - 1) === "R" });
        activeForOrderReceivers.push(tempRightReceiverAfterFind);
      })
      //console.log("Receivers.js - activeForOrderReceivers  right- ", activeRightLength.length, ':', activeForOrderReceivers);
    }

    setReceiversForOrder(activeForOrderReceivers);
  };

  const handleLengthSelectedRight = (ModelTypeCode) => {
    // find model code in array
    const result = findIndex(activeRightLength, (activeLength) => activeLength === ModelTypeCode);
    let tempActiveLengths = [...activeRightLength];
    if(result > -1) {
      // if in array remove it
      tempActiveLengths.splice(result, 1);
    }
    else {
      // if not in array add it
      tempActiveLengths.push(ModelTypeCode);
    }
    setActiveRightLength(tempActiveLengths);
    // Add array back to state
  }

  const handleLengthSelectedLeft = (ModelTypeCode) => {
    // find model code in array
    const result = findIndex(activeLeftLength, (activeLength) => activeLength === ModelTypeCode);
    let tempActiveLengths = [...activeLeftLength];
    if(result > -1) {
      // if in array remove it
      tempActiveLengths.splice(result, 1);
    }
    else {
      // if not in array add it
      tempActiveLengths.push(ModelTypeCode);
    }
    setActiveLeftLength(tempActiveLengths);
    // Add array back to state
  }


  return (
    <div>
      {receivers && (
        <div className="receivers-component-main-div order-builder-component">
          <h2 className="order-builder-component-title">Receivers</h2>
          <div className="alert alert-danger">  
            <p>Please note: If you are going to order a custom earpiece with your hearing aid, please select the receiver strength and length in the custom ear piece builder.</p>
          </div>
          <Row className="pt-4">
            <Col md={6}>
              <Row className="receivers-top-left-row">
                <Col xs={12}>
                  <div className="text-center">
                    <h3>Right Ear</h3>
                  </div>
                </Col>
                <Col md={6} lg={3}>
                  <a
                    onClick={() => {
                      setActiveRightReceivers("None");
                      setActiveRightLength(0);
                    }}
                  >
                    <div
                      className={`receivers-box ${activeRightReceiver === "None" ? "active" : ""}`}
                    >
                      <div className="receivers-box-content">
                        <div className="receivers-box-code">
                          <p>
                            None / Custom Earpiece
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </Col>
                {receivers.map((receiver, index) => (
                  <Col key={index} md={6} lg={3}>
                    <a
                      onClick={() => {
                        setActiveRightReceivers(receiver.PerformanceLevelCode);
                      }}
                    >
                      <div
                        className={`receivers-box ${activeRightReceiver === receiver.PerformanceLevelCode ? "active" : ""
                          }`}
                      >
                        <div className="receivers-box-content">
                          <div className="receivers-box-code">
                            <p>
                              {receiver.PerformanceLevelName}
                            </p>
                          </div>
                          {/* <div className="receivers-box-text">
                            <p>{receiver.PerformanceLevelCode && receiver.PerformanceLevelCode == "S" ? "(Standard)" : (receiver.PerformanceLevelCode == "M" ? "(Moderate)" : (receiver.PerformanceLevelCode == "P" ? "(Power)" : ""))}</p>
                          </div> */}
                        </div>
                      </div>
                    </a>
                  </Col>
                ))}
              </Row>
              <Row className="receivers-bottom-left-row receivers-bottom-row">
                {activeRightReceiver != "None" && activeRightReceiver && (
                  <Col xs={12}>
                    <div className="text-center">
                      <h3>{"Length(s)"}</h3>
                      <p>Multiple lengths maybe selected</p>
                    </div>
                  </Col>
                )}
                {activeRightReceiver != "None" &&
                  activeRightReceiver &&
                  rightLengths &&
                  rightLengths.map(
                    (length, index) => (
                      <Col key={index} md={6} sm={3}>
                        <a
                          onClick={() => {
                            handleLengthSelectedRight(length.ModelTypeCode);
                          }}
                        >
                          <div
                            className={`receivers-box ${(findIndex(activeRightLength, (activeLength) => activeLength === length.ModelTypeCode) > -1) ? "active" : ""}`}
                          >
                            <div className="receivers-box-content">
                              <div className="receivers-box-text">
                                <p>{length.ModelTypeName}</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </Col>
                    )
                  )}
              </Row>
            </Col>
            <Col md={6}>
              <Row className="receivers-top-right-row">
                <Col xs={12}>
                  <div className="text-center">
                    <h3>Left Ear</h3>
                  </div>
                </Col>
                <Col md={6} lg={3}>
                  <a
                    onClick={() => {
                      setActiveLeftReceivers("None");
                      setActiveLeftLength(0);
                    }}
                  >
                    <div
                      className={`receivers-box ${activeLeftReceiver === "None" ? "active" : ""}`}
                    >
                      <div className="receivers-box-content">
                        <div className="receivers-box-code">
                          <p>
                            None / Custom Earpiece
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </Col>
                {receivers.map((receiver, index) => (
                  <Col key={index} md={6} lg={3}>
                    <a
                      onClick={() => {
                        setActiveLeftReceivers(receiver.PerformanceLevelCode);
                        if (index == 0) {
                          setActiveLeftLength(0);
                        }
                      }}
                    >
                      <div
                        className={`receivers-box ${activeLeftReceiver === receiver.PerformanceLevelCode ? "active" : ""
                          }`}
                      >
                        <div className="receivers-box-content">
                          <div className="receivers-box-code">
                            <p>
                              {receiver.PerformanceLevelName}
                            </p>
                          </div>
                          {/* <div className="receivers-box-text">
                            <p>{receiver.PerformanceLevelCode && receiver.PerformanceLevelCode == "S" ? "(Standard)" : (receiver.PerformanceLevelCode == "M" ? "(Moderate)" : (receiver.PerformanceLevelCode == "P" ? "(Power)" : ""))}</p>
                          </div> */}
                        </div>
                      </div>
                    </a>
                  </Col>
                ))}
              </Row>
              <Row className="receivers-bottom-right-row receivers-bottom-row">
                {activeLeftReceiver != "None" && activeLeftReceiver && (
                  <Col xs={12}>
                    <div className="text-center">
                      <h3>{"Length(s)"}</h3>
                      <p>Multiple lengths maybe selected</p>
                    </div>
                  </Col>
                )}
                {activeLeftReceiver != "None" &&
                  activeLeftReceiver &&
                  leftLengths &&
                  leftLengths.map((length, index) => (
                    <Col key={index} md={6} sm={3}>
                      <a
                        onClick={() => {
                          handleLengthSelectedLeft(length.ModelTypeCode);
                        }}
                      >
                        <div
                          // For active, check if length.ModelTypeCode is in array.
                          className={`receivers-box ${(findIndex(activeLeftLength, (activeLength) => activeLength === length.ModelTypeCode) > -1) ? "active" : ""}`}
                        >
                          <div className="receivers-box-content">
                            <div className="receivers-box-text">
                              <p>{length.ModelTypeName}</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Receivers;
