import { connect } from 'react-redux';
import ChangePassword from './changePassword';
import { getChangePasswordStatus } from './store/selectors';
import { changePassword } from './store/action-creators';
// import { getOrganisationListData } from '../organisations/store/selectors';
// import { loadOrganisationListData } from '../organisations/store/action-creators';


const mapStateToProps = (state) => ({
  changePasswordStatus: getChangePasswordStatus(state)
});

const mapDispatchToProps = {
  changePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);