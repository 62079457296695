import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { LoginStatus } from './store/constants';
import Loading from '../../components/Loading/Loading';
import Cookies from 'universal-cookie';

import logoPhonak from "../../assets/img/logo-phonak.svg";
import logoUnitron from "../../assets/img/logo-unitron.svg";
import config from '../../config.js'

import(/* webpackChunkName: "login" */'./login.scss');

const Login = ({
  loadLogin,
  loginStatus,
  startLogin,
  errorStatus,
}) => {
  let history = useHistory();

  useEffect(() => {
    const cookies = new Cookies();
    const loginToken = cookies.get('LoginToken');
    if(loginToken && loginStatus === LoginStatus.SUCCESS) {
      // If there is a loginToken in the cookies, redirect to dashboard screen
      loadLogin();
      history.push({ pathname: "/" });
    }
  }, [loginStatus]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = document.forms[0];

    if (email.value && password.value) {
      // loadLogin();
      // Send email and password entered to store/saga
      startLogin(email.value, password.value);
    }
  };

  
  // While the API is waiting for a response, show loading animation
  if (loginStatus === LoginStatus.LOADING) {
    return (
      <Col>
        <div className="Auth-form-container login-loading">
          <Loading />
        </div>
      </Col>
    )
  }

  return (
    <Col>
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={handleSubmit}>
          <div className="Auth-form-content">
            <div className="Auth-form-logo">
              {/* SITE LOGOS */}
              {config.siteName && config.siteName==="PHONAK" && <img src={logoPhonak} className="img-fluid" /> }
              {config.siteName && config.siteName==="UNITRON" && <img src={logoUnitron} className="img-fluid" /> }
            </div>

            <div className="Auth-form-title-div">
              <h3 className="Auth-form-title">Sign In</h3>
              <p>to continue</p>
            </div>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                type="email"
                name="email"
                className="form-control mt-1"
                placeholder="Enter email"
                required
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                name="password"
                className="form-control mt-1"
                placeholder="Enter password"
                required
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            {loginStatus === LoginStatus.ERROR &&
              <div className='alert alert-danger mt-3'>
                <p>{errorStatus}</p>
              </div>
            }
            <p className="text-center mt-2">
              <a href="/forgot-password">Forgot password?</a>
            </p>
          </div>
        </form>
      </div>
    </Col>
  )
};

Login.propTypes = {
  loadLogin: PropTypes.func,
  startLogin: PropTypes.func,
  loginStatus: PropTypes.string,
  errorStatus: PropTypes.string,
}

export default Login;
