import { connect } from 'react-redux';

import ResetPassword from './resetPassword';
import { getResetPasswordStatus } from './store/selectors';
import { startResetPassword } from './store/action-creators';


const mapStateToProps = (state) => ({
  resetPasswordStatus: getResetPasswordStatus(state),
});

const mapDispatchToProps = {
  startResetPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);