import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import { SaveSiteUserStatus, SiteDetailStatus } from '../../store/constants';
import { Link, useHistory, useParams } from "react-router-dom";
import Loading from '../../../../components/Loading/Loading';
import { get } from 'lodash';

import(/* webpackChunkName: "addSiteUser" */'./addSiteUser.scss');

const AddSiteUser = ({
  saveSiteUserStatus,
  siteDetailStatus,
  siteDetailData,
  saveSiteUserData,
  loadSiteDetailData,
  currentUserDetails
}) => {
  const history = useHistory();
  let { siteCode } = useParams();
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    organisationCode: '',
    siteCode: siteCode ? siteCode : '',
    password: '',
    roleName: '',
    userId: ''
    // isEditing: false
  });

  // list of roles valid for user
  const [roleList, setRoleList] = useState([]);
  const roleValues = {
    sonovasuperuser: 3,
    sonovawhs: 2,
    customeradmin: 1,
    customeruser: 0
  };

  const roles = [
    {role: 'sonovasuperuser', value: 3, display: 'Super User'},
    {role: 'sonovawhs', value: 2, display: 'WHS Admin'},
    {role: 'customeradmin', value: 1, display: 'Customer Admin'},
    {role: 'customeruser', value: 0, display: 'Customer User'}
  ];

  useEffect(() => {
    if (currentUserDetails) {
      setRoleList([{role: '', display: 'Please select'},...roles.filter(r => r.value <= roleValues[currentUserDetails.RoleName] )]);
    }
  }, [currentUserDetails]);


  useEffect(() => {
    if (loadSiteDetailData) {
      loadSiteDetailData(siteCode);
    }
  }, [loadSiteDetailData]);

  useEffect(() => {
    if (siteDetailData) {
      setUserData({
        ...userData,
        organisationCode: get(siteDetailData, 'OrganisationCode', ''),
        siteCode: get(siteDetailData, 'SiteCode', '')
      });
    }
  }, [siteDetailData]);

  function handleSubmit(e) {
    e.preventDefault();
    // //console.log(userData);
    saveSiteUserData(userData);
    // setUserData({
    //   firstName: '',
    //   lastName: '',
    //   email: '',
    //   organisationCode: '',
    //   siteCode: '',
    //   password: '',
    //   roleName: '',
    //   userId: ''
    //   // isEditing: false
    // });
    history.push(`/admin/site/${siteCode}/detail`);
  }

  function handleChange(e) {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value 
    });
  }

  if (saveSiteUserStatus === SaveSiteUserStatus.LOADING || 
    siteDetailStatus === SiteDetailStatus.LOADING) {
    return (
      <Col>
        <div>
          <Loading />
        </div>
      </Col>
    )
  }

  return (
    <div className="dashboard-title">
      <h1>Add a Site User</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Row>
                <Col className="pb-4" xs={3}>
                  <Form.Label>First Name</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="firstName"
                    // defaultValue={""}
                    value={userData.firstName}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col className="pb-4" xs={3}>
                  <Form.Label>Last Name</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="lastName"
                    // defaultValue={""}
                    value={userData.lastName}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col className="pb-4" xs={3}>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="email"
                    name="email"
                    // defaultValue={""}
                    value={userData.email}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col className="pb-4" xs={3}>
                  <Form.Label>Organisation</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="organisationName"
                    // defaultValue={""}
                    value={siteDetailData['OrganisationName']}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    disabled
                  />
                </Col>
                <Col className="pb-4" xs={3}>
                  <Form.Label>Site</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="siteName"
                    // defaultValue={""}
                    value={siteDetailData['SiteName']}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    disabled
                    // required
                  />
                </Col>
                
                <Col className="pb-4" xs={3}>
                  <Form.Label>Password</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="password"
                    name="password"
                    // defaultValue={""}
                    value={userData.password}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col>
                {/* <Col className="pb-4" xs={3}>
                  <Form.Label>Role Name</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="roleName"
                    // defaultValue={""}
                    value={userData.roleName}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col> */}
                <Col className="pb-4" xs={3}>
                  <Form.Label>Role</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Select
                    name='roleName'
                    value={userData.roleName ? userData.roleName : ''}
                    className='me-2 form-control'
                    aria-level='roleName'
                    onChange={handleChange}
                    required
                  >
                    {
                      roleList &&
                      roleList.map((role, index) => (
                        <option value={role.role} key={index}>{role.display}</option>
                      ))
                    }

                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <div className="float-right">
              <Button type="submit" variant="success mt-2">
                Save
              </Button>
              <Link to={`/admin/site/${siteCode}/detail`}>
                <Button variant="warning mt-2 ml-2">
                  Cancel
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        
      </Form>
      
    </div>
  )
};

AddSiteUser.propTypes = {
  saveOrgUserData: PropTypes.func,
  saveSiteUserStatus: PropTypes.string,
  siteDetailStatus: PropTypes.string,
  orgUserDetailData: PropTypes.object,
  loadOrgUserData: PropTypes.func,
  siteDetailData: PropTypes.object,
  saveSiteUserData: PropTypes.func,
  loadSiteDetailData: PropTypes.func,
  currentUserDetails: PropTypes.object
};

export default AddSiteUser;
