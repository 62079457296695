// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import * as actionCreators from './action-creators';
import * as orgActionCreators from '../../organisations/store/action-creators';
import { LOAD_SITEDETAIL_DATA, SAVE_SITE_DATA, SAVE_SITEUSER_DATA, SET_SAVE_SITE_STATUS  } from './actions';
import { SiteDetailStatus, AddEditSiteStatus, SaveSiteUserStatus } from './constants';
import api from '../../../utilities/api';
import { get } from 'lodash';
// import { SAVE_ADMIN_DATA } from '../../administrators/store/actions';

export function* rootSaga() {
  yield takeLatest(LOAD_SITEDETAIL_DATA, loadSiteDetailData);
  yield takeLatest(SAVE_SITE_DATA, saveSiteData);
  yield takeLatest(SAVE_SITEUSER_DATA, saveSiteUserData);
  // yield takeLatest(SET_SAVE_SITE_STATUS, ResetSaveSiteStatus);
  
}

export function* ResetSaveSiteStatus() {
  try {
    yield put(actionCreators.setSaveSiteStatus(AddEditSiteStatus.IDLE)); 
  } catch {
    yield put(actionCreators.setSaveSiteStatus(AddEditSiteStatus.IDLE)); 
  }
};

export function* loadSiteDetailData(siteCodePayload) {
  try {
    yield put(actionCreators.setSiteDetailStatus(SiteDetailStatus.LOADING));
    const siteCode = get(siteCodePayload, "payload", {});
    
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken?
    const headerParams = {
      Authorization: `Bearer ${token}`
    };
    
    yield delay(300);
    const response = yield call(api.get, `/api/site/${siteCode}`, {headers:headerParams});
    
    yield put(actionCreators.setSiteDetailData(response.data));
    yield put(actionCreators.setSiteDetailStatus(SiteDetailStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setSiteDetailStatus(SiteDetailStatus.ERROR));
  }
};


export function* saveSiteData(data) {
  try {
    yield put(actionCreators.setSaveSiteStatus(AddEditSiteStatus.LOADING));
    let orgDetail = get(data, "0", {});
    let siteData = get(data, "payload", {});
    let body = {
      'OrganisationCode': get(siteData, "organisationCode", null),
      'SiteName': get(siteData, "siteName", null)
    };
    
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken?
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    let response;
    if (!siteData.isEditing) {
      //console.log('creating site', body);
      response = yield call(api.post, '/api/site', body, {headers:headerParams});
      orgDetail['Sites'].push(response.data);
    } else {
      body.SiteCode = get(siteData, 'siteCode', null);
      //console.log('editing site', body);
      response = yield call(api.put, '/api/site', body, {headers:headerParams});
      let siteList = orgDetail['Sites'].map(x => {
        if (x.SiteCode == response.data.SiteCode) {
          return response.data
        }
        return x;
      });
      orgDetail['Sites'] = siteList;
    }
    //console.log('new site details',response.data);
    //console.log('new org details', orgDetail);

    yield put(orgActionCreators.setOrganisationDetailData(orgDetail));
    yield put(actionCreators.setSaveSiteStatus(AddEditSiteStatus.SUCCESS)); 
  }
  catch (error) {
    yield put(actionCreators.setSaveSiteStatus(AddEditSiteStatus.ERROR));
  }
}

export function* saveSiteUserData(data) {
  try {
    yield put(actionCreators.setSaveSiteUserStatus(SaveSiteUserStatus.LOADING));
    let userData = get(data, "payload", {});
    let body = {
      'FirstName': get(userData, "firstName", ""),
      'LastName': get(userData, "lastName", ""),
      'EmailAddress': get(userData, "email", ""),
      'OrganisationCode': get(userData, "organisationCode", ""),
      'SiteCode': get(userData, "siteCode", ""),
      'Password': get(userData, "password", ""),
      'RoleName': get(userData, "roleName", ""),
    };    

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken?
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    // //console.log(body);
    let response = yield call(api.post, '/api/site/register', body, {headers:headerParams});
    //console.log(response.data);
    yield put(actionCreators.setSaveSiteUserStatus(SaveSiteUserStatus.SUCCESS));
  }
  catch (error) {
    
    yield put(actionCreators.setSaveSiteUserStatus(SaveSiteUserStatus.ERROR));
  }
    
}



