import { create } from '../../../utilities/action-helpers';
import * as actions from './actions';

// make one creator for each action in actions.js


// user related

export const loadOrganisationData = create(actions.LOAD_ORGANISATION_DATA);
export const setOrganisationStatus = create(actions.SET_ORGANISATION_STATUS);
export const setOrganisationData = create(actions.SET_ORGANISATION_DATA);

export const saveOrganisationData = create(actions.SAVE_ORGANISATION_DATA);
export const setSaveOrganisationStatus = create(actions.SET_SAVE_ORGANISATION_STATUS);

export const loadCatalogueListData = create(actions.LOAD_CATALOGUELIST_DATA);
export const setCatalogueListData = create(actions.SET_CATALOGUELIST_DATA);
export const setCatalogueListStatus = create(actions.SET_CATALOGUELIST_STATUS);
