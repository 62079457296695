import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import InputNumber from "react-input-number";
import { Line, getElementsAtEvent } from "react-chartjs-2";
import dragdataPlugin from "chartjs-plugin-dragdata";
import { Chart, registerables } from "chart.js";
import config from "../../../../config";

Chart.register(...registerables, dragdataPlugin);
import(/* webpackChunkName: "order-builder-graph" */ "./graph.scss");

const Graph = ({ 
    rightSelected,
    leftSelected,
    errorMessageRight,
    errorMessageLeft,
    rightAudiogram,
    setRightAudiogram,
    leftAudiogram,
    setLeftAudiogram,
    manualVent,
    setManualVent,
  }) => {
  const chartRefRight = useRef();
  const chartRefLeft = useRef();

  // I DON'T UNDERSTAND THIS SECTION FULLY
  // ASK PHIL FOR HELP AS HE BUILT THIS COMPONENT
  const setRightData = (index, newvalue) => {
    if (!newvalue) {
      rightAudiogram[index] = undefined;
      //console.log("null");
    } else {
      rightAudiogram[index] = roundNumber(newvalue);
    }
    setRightAudiogram([...rightAudiogram]);
  };
  const setLeftData = (index, newvalue) => {
    if (!newvalue) {
      leftAudiogram[index] = undefined;
      //console.log("null");
    } else {
      leftAudiogram[index] = roundNumber(newvalue);
    }
    setLeftAudiogram([...leftAudiogram]);
  };
  let isDraggingPoint;
  const roundNumber = (number) => {
    let roundedNumber = number;
    roundedNumber = Math.round(roundedNumber / 5) * 5;

    if (roundedNumber > 120) {
      roundedNumber = 120;
    }
    if (roundedNumber < -1) {
      roundedNumber = 0;
    }
    return roundedNumber;
  };

  const data = {
    labels: [
      "125",
      "",
      "250",
      "",
      "500",
      "",
      "1k",
      "",
      "2k",
      "",
      "4k",
      "",
      "8k",
    ],
    datasets: [
      {
        label: "",
        data: rightAudiogram,
        lineTension: 0,
        borderWidth: 1,
        pointHitRadius: 25,
        skipNullValues: true,
        spanGaps: true,
        fill: false,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        min: 0,
        max: 120,
        reverse: true,
      },
    },
    plugins: {
      dragData: {
        round: 0,
        magnet: {
          to: (value) => Math.round(value / 5) * 5, //Math.round // to: (value) => value + 5
        },
        showTooltip: true,
        onDragStart: (e, datasetIndex, index, value) => {
          //console.log("drag start", e, datasetIndex, index, value);
          isDraggingPoint = true;
        },
        onDragEnd: (e, datasetIndex, index, value) => {
          //console.log("Drag end", e, datasetIndex, index, value);
          //console.log("Index of dataset: ", index, " new value:", value);
          setRightData(index, value);
          isDraggingPoint = false;
        },
      },
    },
  };
  const dataLeft = {
    labels: [
      "125",
      "",
      "250",
      "",
      "500",
      "",
      "1k",
      "",
      "2k",
      "",
      "4k",
      "",
      "8k",
    ],
    datasets: [
      {
        label: "",
        data: leftAudiogram,
        lineTension: 0,
        borderWidth: 1,
        pointHitRadius: 25,
        skipNullValues: true,
        spanGaps: true,
        fill: false,
      },
    ],
  };

  const optionsLeft = {
    scales: {
      y: {
        min: 0,
        max: 120,
        reverse: true,
      },
    },
    //https://codesandbox.io/examples/package/chartjs-plugin-dragdata
    //https://codesandbox.io/s/lvhxf?file=/src/App.js:304-309
    plugins: {
      dragData: {
        round: 0,
        magnet: {
          to: (value) => Math.round(value / 5) * 5, //Math.round // to: (value) => value + 5
        },
        showTooltip: true,
        onDragEnd: (e, datasetIndex, index, value) => {
          setLeftData(index, value);
        },
      },
    },
  };
  // mapping items in an array https://codesandbox.io/s/o54n9zwnly?file=/src/index.js:622-624

  const copyLeftEarToRightEar = () => {
    setRightAudiogram(leftAudiogram);
  };

  const copyRightEarToLeftEar = () => {
    setLeftAudiogram(rightAudiogram);
  };

  const handleManualVent = () => {
    setManualVent(!manualVent);
  };

  return (
    <div className="graph-component-main-div order-builder-component">
      <Row className="order-builder-component-title">
        <Col xs={12}>
          <h2>Audiogram</h2>
        </Col>
        <Col xs={12} className="select-sides-component-main-div">
          <div className="form-group">
            <input type="checkbox" id="manual-vent" checked={manualVent} onChange={handleManualVent} />
            <label htmlFor="manual-vent">{`I'm selecting a vent manually / It's a paediatric fitting (${config.siteName == "UNITRON" ? 'Non-Intellivent' : 'Non-AOV Vent'})`}</label>
          </div>
        </Col>
        <Col xs={12}>
          {rightSelected && leftSelected && (
            <div className="text-center">
              <p>
                <a className="copy-button" onClick={copyLeftEarToRightEar}>
                  {"<"}
                </a>{" "}
                Copy{" "}
                <a className="copy-button" onClick={copyRightEarToLeftEar}>
                  {">"}
                </a>
              </p>
            </div>
          )}
        </Col>
        <Col md={6} className="graph-right-ear">
          {rightSelected && (
            <div>
              <Line
                ref={chartRefRight}
                data={data}
                options={options}
                // onClick={onChartClick}
              />
              <Row>
                {rightAudiogram.map((element, index) => {
                  if (index % 2 === 0) {
                    return (
                      <Col
                        xs={1}
                        key={index}
                        className={`right-graph-input-${index}`}
                      >
                        <InputNumber
                          min={0}
                          max={120}
                          step={5}
                          value={element}
                          onChange={(e) => {
                            if (e.target) {
                              setRightData(index, e.target.value);
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target) {
                              setRightData(index, e.target.value);
                            }
                          }}
                        />
                      </Col>
                    );
                  } else return null;
                })}
              </Row>

              <Row className="bottom-row">
                {rightAudiogram.map((element, index) => {
                  if (index % 2 === 1 && index > 3) {
                    return (
                      <Col
                        xs={1}
                        key={index}
                        className={`right-graph-input-${index}`}
                      >
                        <InputNumber
                          min={0}
                          max={120}
                          step={5}
                          value={element}
                          onChange={(e) => {
                            if (e.target) {
                              setRightData(index, e.target.value);
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target) {
                              setRightData(index, e.target.value);
                            }
                          }}
                        />
                      </Col>
                    );
                  } else return null;
                })}
              </Row>
              {/* {errorMessageRight && (
                <Row>
                  <Col xs={12}>
                    <div className="error-message">{errorMessageRight}</div>
                  </Col>
                </Row>
              )} */}
            </div>
          )}
        </Col>
        <Col md={6} className="graph-left-ear left-side">
          {leftSelected && (
            <div>
              <Line
                ref={chartRefLeft}
                data={dataLeft}
                options={optionsLeft}
                // onClick={onChartClick}
              />
              <Row>
                {leftAudiogram.map((element, index) => {
                  if (index % 2 === 0) {
                    return (
                      <Col
                        xs={1}
                        key={index}
                        className={`left-graph-input-${index}`}
                      >
                        <InputNumber
                          min={0}
                          max={120}
                          step={5}
                          value={element}
                          onChange={(e) => {
                            if (e.target) {
                              setLeftData(index, e.target.value);
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target) {
                              setLeftData(index, e.target.value);
                            }
                          }}
                        />
                      </Col>
                    );
                  } else return null;
                })}
              </Row>

              <Row className="bottom-row">
                {leftAudiogram.map((element, index) => {
                  if (index % 2 === 1 && index > 3) {
                    return (
                      <Col
                        xs={1}
                        key={index}
                        className={`left-graph-input-${index}`}
                      >
                        <InputNumber
                          min={0}
                          max={120}
                          step={5}
                          value={element}
                          onChange={(e) => {
                            if (e.target) {
                              setLeftData(index, e.target.value);
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target) {
                              setLeftData(index, e.target.value);
                            }
                          }}
                        />
                      </Col>
                    );
                  } else return null;
                })}
              </Row>
              {/* {errorMessageLeft && (
                <Row>
                  <Col xs={12}>
                    <div className="error-message">{errorMessageLeft}</div>
                  </Col>
                </Row>
              )} */}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Graph;
