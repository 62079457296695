import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { ForgotPasswordStatus } from './constants';

const forgotPasswordStatus = createReducer({
  [actions.SET_FORGOT_PASSWORD_STATUS]: (state, { payload }) => payload
}, ForgotPasswordStatus.IDLE);

export default combineReducers({
  forgotPasswordStatus
});
