import { connect } from 'react-redux';

import Login from './login';
import { getLoginStatus, getErrorStatus } from './store/selectors';
import { loadLogin, startLogin } from './store/action-creators';


const mapStateToProps = (state) => ({
  loginStatus: getLoginStatus(state),
  errorStatus: getErrorStatus(state),
});

const mapDispatchToProps = {
  loadLogin,
  startLogin,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);