import { connect } from 'react-redux';
import AddSiteUser from './addSiteUser';
import { getSaveSiteUserStatus, getSiteDetailStatus, getSiteDetailData } from '../../store/selectors';
import { saveSiteUserData, loadSiteDetailData } from '../../store/action-creators';
import { getProfileData } from '../../../login/store/selectors';


const mapStateToProps = (state) => ({
  saveSiteUserStatus: getSaveSiteUserStatus(state),
  siteDetailStatus: getSiteDetailStatus(state),
  siteDetailData: getSiteDetailData(state),
  currentUserDetails: getProfileData(state)
});

const mapDispatchToProps = {
  saveSiteUserData,
  loadSiteDetailData
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSiteUser);