export const OrderBuilderStatus = Object.freeze({
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
})

export const ConfigurationGroupStatus = Object.freeze({
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
})

export const CartStatus = Object.freeze({
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
})

export const CustomOptionsStatus = Object.freeze({
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
})

export const ColourConstants = Object.freeze({
  H0: {
    name: "Biege",
    colour: "#f5f5dc",
  },
  P1: {
    name: "Sand Biege",
    colour: "#ae9b7d",
  },
  P3: {
    name: "Sandalwood",
    colour: "#C89878",
  },
  P4: {
    name: "Chestnut",
    colour: "#954535",
  },
  P5: {
    name: "Champagne",
    colour: "#F7E7CE",
  },
  P6: { 
    name: "Silver Grey",
    colour: "#C0C0C0",
  },
  P7: {
    name: "Graphite Grey",
    colour: "#53565b",
  },
  P8: {
    name: "Velvet Black",
    colour: "#ACAAB3",
  }
})