import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../store/sagas';
import rootReducer from '../store/reducers';
import history from './history';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const allMiddleware = applyMiddleware(
  routerMiddleware(history),
  thunk,
  sagaMiddleware,
);
const rootEnhancer = composeEnhancers(allMiddleware);
const store = createStore(rootReducer, rootEnhancer);
sagaMiddleware.run(rootSaga);
export default store;