// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import * as actionCreators from './action-creators';
import { LOAD_LOGIN_DATA, START_LOGIN, SET_PROFILE_DATA_FROM_LOCAL_STORAGE } from './actions';
import { LoginStatus } from './constants';
import { get } from 'lodash';
import Cookies from 'universal-cookie';
import api from '../../../utilities/api';

export function* rootSaga() {
  yield takeLatest(LOAD_LOGIN_DATA, loadLogin);
  yield takeLatest(START_LOGIN, startLogin);setProfileDataFromLocalStorage
  yield takeLatest(SET_PROFILE_DATA_FROM_LOCAL_STORAGE, setProfileDataFromLocalStorage);
}

export function* loadLogin() {
  try {
    //CHECK IF USER IS ALREADY LOGGED IN BY CHECKING COOKIE
    //IF SO NAVIGATE TO STORE PAGE

    yield put(actionCreators.setLoginStatus(LoginStatus.LOADING));
    // yield delay(2600);
    // // const response = yield call(api.get, '/private/login');
    // // yield put(actionCreators.setLoginAge(response.data));
    // yield put(actionCreators.setLoginStatus(LoginStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setLoginStatus(LoginStatus.ERROR));
  }
}

export function* startLogin(data) {
  try {
    yield put(actionCreators.setLoginStatus(LoginStatus.LOADING));
    const cookies = new Cookies();
    
    // get data passed in to saga
    const email = get(data, "payload", "");
    const password = get(data, "0", "");
    const body = {
      Email: email,
      Password: password,
    }
    
    // Send to API
    const response = yield call(api.post, '/api/account/login', body);

    //SET login token in COOKIEs
    cookies.set('LoginToken', response.data.Token, { path: '/', expires: new Date(Date.now()+8640000) });
    // //console.log(JSON.stringify(response.data))
    //Set profile data in local storage
    localStorage.removeItem("profileData");
    localStorage.setItem("profileData", JSON.stringify(response.data));

    //Set role name in cookies
    cookies.set('RoleName', response.data.RoleName, { path: '/' });

    yield put(actionCreators.setProfileData(response.data));
    yield put(actionCreators.setLoginStatus(LoginStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setErrorStatus(error.response.data));
    yield put(actionCreators.setLoginStatus(LoginStatus.ERROR));
  }
}

export function* setProfileDataFromLocalStorage(data) {
  try {
    yield put(actionCreators.setLoginStatus(LoginStatus.LOADING));
    const profileData = get(data, "payload", {});
    
    yield put(actionCreators.setProfileData(profileData));
    yield put(actionCreators.setLoginStatus(LoginStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setLoginStatus(LoginStatus.ERROR));
  }
}