import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';

import store from './store';
import AppComponent from './app';

moment.tz.setDefault('Pacific/Auckland');

moment.updateLocale('en-nz', {
  week: {
    dow: 0
  }
});

moment.locale('en-nz');

/*
  HANDLE LOADING AND AUTH HERE
  Create initApp action and dispatch
  const initApp = () => async (dispatch, getState) => {
    try{
      load and get user details
    }
    catch {
      error
    }
  }

  store.dispatch(initApp());
*/

ReactDOM.render(
  <Provider store={store}>
    <AppComponent />
  </Provider>,
  document.getElementById('root'),
);