import { connect } from 'react-redux';
import AddAdmin from './addAdmin';
import { getSaveAdminStatus, getAdministratorDetailStatus, getAdministratorDetailData, getErrorStatus, getAdministratorsListData } from '../../store/selectors';
import { getOrganisationListData, getOrganisationListStatus, getOrganisationDetailData, getOrganisationDetailStatus } from '../../../organisations/store/selectors';
import { saveAdminData, loadAdministratorDetailData, loadAdministratorsListData, resetSaveAdminForm } from '../../store/action-creators';
import { loadOrganisationListData, loadOrganisationDetailData } from '../../../organisations/store/action-creators';
import { getProfileData } from '../../../login/store/selectors';
import { AddAdminStatus } from '../../store/constants';


const mapStateToProps = (state) => ({
  saveAdminStatus: getSaveAdminStatus(state),
  administratorDetailStatus: getAdministratorDetailStatus(state),
  administratorDetailData: getAdministratorDetailData(state),
  administratorDetailData: getAdministratorDetailData(state),
  organisationListData: getOrganisationListData(state),
  organisationListStatus: getOrganisationListStatus(state),
  organisationDetailData: getOrganisationDetailData(state),
  organisationDetailStatus: getOrganisationDetailStatus(state),
  currentUserDetails: getProfileData(state),
  errorStatus: getErrorStatus(state),
  administratorsListData: getAdministratorsListData(state)
});

const mapDispatchToProps = {
  saveAdminData,
  loadAdministratorDetailData,
  loadOrganisationListData,
  loadOrganisationDetailData,
  loadAdministratorsListData,
  resetSaveAdminForm
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAdmin);