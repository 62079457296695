import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import RICBTEItemBuilder from "./components/RICBTEItemBuilder/RICBTEItemBuilder";
import ITEItemBuilder from "./components/ITEItemBuilder/ITEItemBuilder";
import EarPiecesItemBuilder from "./components/EarPiecesItemBuilder/EarPiecesItemBuilder";
import { find } from 'lodash';
import(/* webpackChunkName: "orderBuilder" */ "./orderBuilder.scss");

const OrderBuilder = ({
  loadOrderBuilder,
  orderBuilderStatus,
  orderBuilderData,
  configurationGroupStatus,
  configurationGroupData,
  configurationGroupLeft,
  configurationGroupRight,
  loadConfigurationGroup,
  customOptionsGroupStatus,
  customOptionsGroupData,
  customOptionsGroupLeft,
  customOptionsGroupRight,
  loadCustomOptionsGroup,
  productsData,
  cartStatus,
  addOrderToCart,
  cartData,
}) => {
  const { categoryId, productId, itemId } = useParams();
  const [ builderType, setBuilderType] = useState();
  //console.log("Cat and prod:", categoryId,"-",productId, "-",itemId);
  useEffect(() => { 
    if (loadOrderBuilder && productsData) {
      const productArray = find(productsData, {"Code": categoryId})

      if(productArray.InverseParentCodeNavigation.length > 0) {
        const productType = find(productArray.InverseParentCodeNavigation, function(o) {
          return o.Code === productId;
        })
        //console.log("before load order builder", productType.Type);
        loadOrderBuilder(productType.Type, productId);
      }
      else {
        loadOrderBuilder(categoryId, productId);
      }
    }
  }, [loadOrderBuilder, productsData]);

  useEffect(() => {
    if(orderBuilderData) {
      if(orderBuilderData.Category) {
        if(orderBuilderData.Category.Type){
          setBuilderType(orderBuilderData.Category.Type);
        }
      }
    }
  }, [orderBuilderData])
  
  //if (productId === "Earpiece") {
    if (categoryId=== "Earpieces") {
    // IF earpieces selected from the home page / navbar, displays ear piece builder
    return (
      <EarPiecesItemBuilder
        productsData={productsData}
        cartData={cartData}
        cartStatus={cartStatus}
        addOrderToCart={addOrderToCart}
        orderBuilderStatus={orderBuilderStatus}
        orderBuilderData={orderBuilderData}
        configurationGroupStatus={configurationGroupStatus}
        configurationGroupData={configurationGroupData}
        configurationGroupLeft={configurationGroupLeft}
        configurationGroupRight={configurationGroupRight}
        loadConfigurationGroup={loadConfigurationGroup}
        customOptionsGroupStatus={customOptionsGroupStatus}
        customOptionsGroupData={customOptionsGroupData}
        customOptionsGroupLeft={customOptionsGroupLeft}
        customOptionsGroupRight={customOptionsGroupRight}
        loadCustomOptionsGroup={loadCustomOptionsGroup}
        loadOrderBuilder={loadOrderBuilder}
      />
    );
  }

  if (builderType === "ITE") {
    // IF item is an ITE hearing aid displayed ITE builder
    return (
      <ITEItemBuilder
        cartData={cartData}
        cartStatus={cartStatus}
        addOrderToCart={addOrderToCart}
        orderBuilderStatus={orderBuilderStatus}
        orderBuilderData={orderBuilderData}
        configurationGroupStatus={configurationGroupStatus}
        configurationGroupData={configurationGroupData}
        configurationGroupLeft={configurationGroupLeft}
        configurationGroupRight={configurationGroupRight}
        loadConfigurationGroup={loadConfigurationGroup}
        customOptionsGroupStatus={customOptionsGroupStatus}
        customOptionsGroupData={customOptionsGroupData}
        customOptionsGroupLeft={customOptionsGroupLeft}
        customOptionsGroupRight={customOptionsGroupRight}
        loadCustomOptionsGroup={loadCustomOptionsGroup}
      />
    );
  }


  // If item is not an ITE hearing aid or ear piece, displays RIC/BTE builder
  return (
    <RICBTEItemBuilder
      cartData={cartData}
      cartStatus={cartStatus}
      addOrderToCart={addOrderToCart}
      orderBuilderStatus={orderBuilderStatus}
      orderBuilderData={orderBuilderData}
    />
  );
};

OrderBuilder.propTypes = {
  loadOrderBuilder: PropTypes.func,
  orderBuilderStatus: PropTypes.string,
  orderBuilderData: PropTypes.any,
  configurationGroupStatus: PropTypes.string,
  configurationGroupData: PropTypes.object,
  configurationGroupLeft: PropTypes.object,
  configurationGroupRight: PropTypes.object,
  loadConfigurationGroup: PropTypes.func,
  customOptionsGroupStatus: PropTypes.string,
  customOptionsGroupData: PropTypes.object,
  customOptionsGroupLeft: PropTypes.array,
  customOptionsGroupRight: PropTypes.array,
  loadCustomOptionsGroup: PropTypes.func,
  productsData: PropTypes.array,
  cartStatus: PropTypes.string,
  addOrderToCart: PropTypes.func,
  cartData: PropTypes.array,
};

export default OrderBuilder;
