import { fork } from 'redux-saga/effects';
import { rootSaga as orderBuilderSaga } from '../modules/orderBuilder/store/sagas.js';
import { rootSaga as storeHomeSaga } from '../modules/storeHome/store/sagas.js';
import { rootSaga as loginSaga } from '../modules/login/store/sagas.js';
import { rootSaga as profileSaga } from '../modules/profile/store/sagas.js';
import { rootSaga as relatedProductsSaga } from '../modules/relatedProducts/store/sagas.js';
import { rootSaga as orderConfirmationSaga } from '../modules/orderConfirmation/store/sagas.js';
import { rootSaga as forgotPasswordSaga } from '../modules/forgotPassword/store/sagas.js';
import { rootSaga as resetPasswordSaga } from '../modules/resetPassword/store/sagas.js';
import { rootSaga as orderDetailSaga } from '../modules/orderDetail/store/sagas.js';
import { rootSaga as dashboardSaga } from '../modules/dashboard/store/sagas.js';
import { rootSaga as administratorsSaga } from '../modules/administrators/store/sagas.js';
import { rootSaga as organisationsSaga } from '../modules/organisations/store/sagas.js';
import { rootSaga as addEditOrganisationsSaga } from '../modules/addEditOrganisation/store/sagas.js';
import { rootSaga as sitesSaga } from '../modules/sites/store/sagas.js';
import { rootSaga as changePasswordSaga } from '../modules/changePassword/store/sagas.js';


export function* rootSaga() {
  yield fork(dashboardSaga);
  yield fork(orderBuilderSaga);
  yield fork(storeHomeSaga);
  yield fork(loginSaga);
  yield fork(profileSaga);
  yield fork(relatedProductsSaga);
  yield fork(orderConfirmationSaga);
  yield fork(forgotPasswordSaga);
  yield fork(resetPasswordSaga);
  yield fork(orderDetailSaga);
  yield fork(administratorsSaga);
  yield fork(organisationsSaga);
  yield fork(addEditOrganisationsSaga);
  yield fork(sitesSaga);
  yield fork(changePasswordSaga);
};