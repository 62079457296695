import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { ResetPasswordStatus } from './constants';

const resetPasswordStatus = createReducer({
  [actions.SET_RESET_PASSWORD_STATUS]: (state, { payload }) => payload
}, ResetPasswordStatus.IDLE);

export default combineReducers({
  resetPasswordStatus
});
