import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import(/* webpackChunkName: "dashboard" */'./dashboard.scss');

const Dashboard = ({
  loadDashboard,
  dashboardStatus,
}) => {
  useEffect(() => {
    if (loadDashboard) {
      loadDashboard();
    }
  }, [loadDashboard]);

  return (
    <div className="dashboard-title">
      Dashboard: <span>{dashboardStatus}</span>
    </div>
  )
};

Dashboard.propTypes = {
  loadDashboard: PropTypes.func,
  dashboardStatus: PropTypes.string,
}

export default Dashboard;
