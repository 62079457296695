import { create } from 'lodash';
import { createSelector } from 'reselect';

// State is the application wide state
const addEditOrganisation = state => state.modules.addEditOrganisation; // this is from what you named the reducer in the global reducers.js

export const getOrganisationDetailData = createSelector(
  addEditOrganisation,
  state => state.organisationDetailData
);

export const getOrganisationDetailStatus = createSelector(
  addEditOrganisation,
  state => state.organisationDetailStatus
);

export const getSaveOrganisationStatus = createSelector(
  addEditOrganisation,
  state => state.saveOrganisationStatus
);

export const getCatalogueList = createSelector(
  addEditOrganisation,
  state => state.catalogueList
);

export const getCatalogueListStatus = createSelector(
  addEditOrganisation,
  state => state.catalogueListStatus
);
