import { createSelector } from 'reselect';

const profile = state => state.modules.profile;

export const getProfileStatus = createSelector(
  profile,
  state => state.profileStatus
);

export const getProfileData = createSelector(
  profile,
  state => state.profileData
);

export const getOrderData = createSelector(
  profile,
  state => state.orderData
);

export const getChangePasswordStatus = createSelector(
  profile,
  state => state.changePasswordStatus
);