import { create } from '../../../utilities/action-helpers';
import * as actions from './actions';

// make one creator for each action in actions.js

// list related
export const loadOrganisationListData = create(actions.LOAD_ORGANISATIONLIST_DATA);
export const setOrganisationListStatus = create(actions.SET_ORGANISATIONLIST_STATUS);
export const setOrganisationListData = create(actions.SET_ORGANISATIONLIST_DATA);

export const loadOrganisationUserListData = create(actions.LOAD_ORGANISATIONUSERLIST_DATA);
export const setOrganisationUserListStatus = create(actions.SET_ORGANISATIONUSERLIST_STATUS);
export const setOrganisationUserListData = create(actions.SET_ORGANISATIONUSERLIST_DATA);

// user related

export const loadOrgUserData = create(actions.LOAD_ORGUSER_DATA);
export const setOrgUserStatus = create(actions.SET_ORGUSER_STATUS);
export const setOrgUserData = create(actions.SET_ORGUSER_DATA);

export const saveOrgUserData = create(actions.SAVE_ORGUSER_DATA);
export const setSaveOrgUserStatus = create(actions.SET_SAVE_ORGUSER_STATUS);
export const setErrorStatus = create(actions.SET_ERROR_STATUS);

// detail related
export const loadOrganisationDetailData = create(actions.LOAD_ORGANISATIONDETAIL_DATA);
export const setOrganisationDetailStatus = create(actions.SET_ORGANISATIONDETAIL_STATUS);
export const setOrganisationDetailData = create(actions.SET_ORGANISATIONDETAIL_DATA);

// status related
export const resetSaveOrgUserStatus = create(actions.RESET_SAVEORGUSER_STATUS);