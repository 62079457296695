import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import(
  /* webpackChunkName: "order-builder-ear-impression" */ "./earImpression.scss"
);

const EarImpression = ({
  leftSelected,
  rightSelected,
  setEarImpressionForOrder,
}) => {
  const [rightImpressOnFile, setRightImpressOnFile] = useState(false);
  const [leftImpressOnFile, setLeftImpressOnFile] = useState(false);
  const [earImpressionRightText, setEarImpressionRightText] = useState("");
  const [earImpressionLeftText, setEarImpressionLeftText] = useState("");

  const handleChangeRight = (event) => {
    const myValue = event.target.value;

    if (myValue === "useAnImpressionOnFile") {
      setRightImpressOnFile(true);
    } else {
      setRightImpressOnFile(false);
    }
    // setEarImpressionForOrder(myValue);
  };

  const handleChangeLeft = (event) => {
    const myValue = event.target.value;

    if (myValue === "useAnImpressionOnFile") {
      setLeftImpressOnFile(true);
    } else {
      setLeftImpressOnFile(false);
    }
    // setEarImpressionForOrder(myValue);
  };

  useEffect(() => {
    // Checks for changes in component or leftSelected / rightSelected and adds the selected options to an object
    // that is sent to the ite or earmold order builder component.
    let earImpressionForOrder = {};

    if (rightSelected && rightImpressOnFile) {
      earImpressionForOrder.right = {
        type: "impressionOnFile",
        serialNumber: earImpressionRightText,
      };
    }
    if (leftSelected && leftImpressOnFile) {
      earImpressionForOrder.left = {
        type: "impressionOnFile",
        serialNumber: earImpressionLeftText,
      };
    }
    if (rightSelected && !rightImpressOnFile) {
      earImpressionForOrder.right = {
        type: "mailImpression",
      };
    }
    if (leftSelected && !leftImpressOnFile) {
      earImpressionForOrder.left = {
        type: "mailImpression",
      };
    }

    setEarImpressionForOrder(earImpressionForOrder);
  }, [
    rightImpressOnFile,
    leftImpressOnFile,
    earImpressionRightText,
    earImpressionLeftText,
    rightSelected,
    leftSelected,
  ]);

  return (
    <div className="ear-impression-component-main-div order-builder-component">
      <div className="ear-impression-div">
        <div>
          <Row className="order-builder-component-title pb-4">
            <Col xs={4}>
              <h2>Ear Impressions</h2>
            </Col>
            <Col md={4}>
              {rightSelected && (
                <Row>
                  <Col xs={12}><p className="right-ear-title ear-impression-option mb-0"><span className="dot dot-right"></span>Right Ear</p></Col>
                  <Col xs={12}>
                    <div className="ear-impression-option">
                      <label>
                        <input
                          defaultChecked={true}
                          onChange={handleChangeRight}
                          id="ear-impression-2"
                          type="radio"
                          value="mailInAnImpression"
                          name="earImpressionRight"
                        />
                        Mail in an impression
                      </label>
                      {!rightImpressOnFile && (
                        <div className="ear-impression-on-file-input">
                          <p>
                            Upon checkout, you will be able to download an Order
                            Summary document to include in your mailing.
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="ear-impression-option">
                      <label>
                        <input
                          onChange={handleChangeRight}
                          id="ear-impression-2"
                          type="radio"
                          value="useAnImpressionOnFile"
                          name="earImpressionRight"
                        />
                        Use an impression on file
                      </label>
                      {rightImpressOnFile && (
                        <div className="ear-impression-on-file-input">
                          <p>
                            <input
                              type="text"
                              name="earImpressionRightText"
                              value={earImpressionRightText}
                              onChange={(event) => {
                                setEarImpressionRightText(event.target.value);
                              }}
                              placeholder="Enter serial number"
                            />
                          </p>
                          <p>
                            We are only able to use existing impressions for new
                            orders.
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            {leftSelected && (
              <Col md={4} className="left-side">
                <Row>
                  <Col xs={12}><p className="left-ear-title ear-impression-option ear-impression-option mb-0"><span className="dot dot-left"></span>Left Ear</p></Col>
                  <Col xs={12}>
                    <div className="ear-impression-option">
                      <label>
                        <input
                          defaultChecked={true}
                          onChange={handleChangeLeft}
                          id="ear-impression-2"
                          type="radio"
                          value="mailInAnImpression"
                          name="earImpressionLeft"
                        />
                        Mail in an impression
                      </label>
                      {!leftImpressOnFile && (
                        <div className="ear-impression-on-file-input">
                          <p>
                            Upon checkout, you will be able to download an Order
                            Summary document to include in your mailing.
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="ear-impression-option">
                      <label>
                        <input
                          onChange={handleChangeLeft}
                          id="ear-impression-2"
                          type="radio"
                          value="useAnImpressionOnFile"
                          name="earImpressionLeft"
                        />
                        Use an impression on file
                      </label>
                      {leftImpressOnFile && (
                        <div className="ear-impression-on-file-input">
                          <p>
                            <input
                              type="text"
                              name="earImpressionLeftText"
                              value={earImpressionLeftText}
                              onChange={(event) => {
                                setEarImpressionLeftText(event.target.value);
                              }}
                              placeholder="Enter serial number"
                            />
                          </p>
                          <p>
                            We are only able to use existing impressions for new
                            orders.
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default EarImpression;
