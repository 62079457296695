import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from "react-bootstrap";
import { ForgotPasswordStatus } from './store/constants';
import Loading from '../../components/Loading/Loading';
import Cookies from 'universal-cookie';

import(/* webpackChunkName: "forgotPassword" */'./forgotPassword.scss');

const ForgotPassword = ({
  forgotPasswordStatus,
  startForgotPassword,
}) => {
  useEffect(() => {

  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    var { email } = document.forms[0];

    if (email.value) {
      // loadForgotPassword();
      startForgotPassword(email.value);
    }
  };



  if (forgotPasswordStatus === ForgotPasswordStatus.LOADING) {
    return (
      <Col>
        <div className="Auth-form-container forgot-password-loading">
          <Loading />
        </div>
      </Col>
    )
  }

  if (forgotPasswordStatus === ForgotPasswordStatus.SUCCESS) {
    return (
      <Col xs={12}>
        <Row>
          <Col xs={4}></Col>
          <Col xs={4}>
            <div className="forgot-password-success">
              <h2>Password reset email sent</h2>
              <p>Please check your email for a link to change your password.</p>
            </div>
          </Col>
          <Col xs={4}></Col>
        </Row>
      </Col>
    )
  }

  return (
    <Col>
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={handleSubmit}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Forgot Password</h3>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                type="email"
                name="email"
                className="form-control mt-1"
                placeholder="Enter email"
                required
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            {forgotPasswordStatus === ForgotPasswordStatus.ERROR &&
              <div className='alert alert-danger mt-3'>
               <p>We couldn't find that email in our database.</p>
                <p>Please check you have entered your email correctly.</p> 
              </div>
            }
            <p className="text-center mt-2">
              <a href="/login">Login</a>
            </p>
          </div>
        </form>
      </div>
    </Col>
  )
};

ForgotPassword.propTypes = {
  startForgotPassword: PropTypes.func,
  forgotPasswordStatus: PropTypes.string,
}

export default ForgotPassword;
