// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import * as actionCreators from './action-creators';
import { LOAD_DASHBOARD_DATA } from './actions';
import { DashboardStatus } from './constants';
import api from '../../../utilities/api';

export function* rootSaga() {
  yield takeLatest(LOAD_DASHBOARD_DATA, loadDashboard);
}

export function* loadDashboard() {
  try {
    yield put(actionCreators.setDashboardStatus(DashboardStatus.LOADING));
    yield delay(2600);
    // const response = yield call(api.get, '/private/dashboard');
    // yield put(actionCreators.setDashboardAge(response.data));
    yield put(actionCreators.setDashboardStatus(DashboardStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setDashboardStatus(DashboardStatus.ERROR));
  }
}