import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { LoginStatus } from './constants';

const loginStatus = createReducer({
  [actions.SET_LOGIN_STATUS]: (state, { payload }) => payload
}, LoginStatus.IDLE);

const errorStatus = createReducer({
  [actions.SET_ERROR_STATUS]: (state, { payload }) => payload
}, LoginStatus.IDLE);

const profileData = createReducer({
  [actions.SET_PROFILE_DATA]: (state, { payload }) => payload
}, LoginStatus.IDLE);

export default combineReducers({
  loginStatus,
  errorStatus,
  profileData,
});
