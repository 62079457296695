import { create } from '../../../utilities/action-helpers';
import * as actions from './actions';

// make one creator for each action in actions.js

// site detail
export const loadSiteDetailData = create(actions.LOAD_SITEDETAIL_DATA);
export const setSiteDetailData = create(actions.SET_SITEDETAIL_DATA);
export const setSiteDetailStatus = create(actions.SET_SITEDETAIL_STATUS);

// add/edit site
export const saveSiteData = create(actions.SAVE_SITE_DATA);
export const setSaveSiteStatus = create(actions.SET_SAVE_SITE_STATUS);

// add site user

export const saveSiteUserData = create(actions.SAVE_SITEUSER_DATA);
export const setSaveSiteUserStatus = create(actions.SET_SAVE_SITEUSER_STATUS);

