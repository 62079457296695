export const LOAD_ADMINISTRATORSLIST_DATA = 'administrators/LOAD_ADMINISTRATORSLIST_DATA';
export const SET_ADMINISTRATORSLIST_STATUS = 'administrators/SET_ADMINISTRATORSLIST_STATUS';
export const SET_ADMINISTRATORSLIST_DATA = 'administrators/SET_ADMINISTRATORSLIST_DATA';

export const LOAD_ADMINISTRATORDETAIL_DATA = 'administrators/LOAD_ADMINISTRATORDETAIL_DATA';
export const SET_ADMINISTRATORDETAIL_STATUS = 'administrators/SET_ADMINISTRATORDETAIL_STATUS';
export const SET_ADMINISTRATORDETAIL_DATA = 'administrators/SET_ADMINISTRATORDETAIL_DATA';

export const SAVE_ADMIN_DATA = 'administrators/SAVE_ADMIN_DATA';
export const SET_SAVE_ADMIN_STATUS = 'administrators/SET_SAVE_ADMIN_STATUS';
export const SET_ERROR_STATUS = 'administrators/SET_ERROR_STATUS';

export const RESET_SAVEADMIN_FORM = 'administrators/RESET_SAVEADMIN_FORM';