import { connect } from 'react-redux';
import OrganisationUserList from './organisationUserList';
import { getOrganisationUserListStatus, getOrganisationUserListData } from '../../store/selectors';
import { loadOrganisationUserListData } from '../../store/action-creators';


const mapStateToProps = (state) => ({
  organisationUserListStatus: getOrganisationUserListStatus(state),
  organisationUserListData: getOrganisationUserListData(state)
});

const mapDispatchToProps = {
  loadOrganisationUserListData
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationUserList);