import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { OrderDetailStatus } from './constants';

const orderDetailStatus = createReducer({
  [actions.SET_ORDER_DETAIL_STATUS]: (state, { payload }) => payload
}, OrderDetailStatus.IDLE);

const orderDetailData = createReducer({
  [actions.SET_ORDER_DETAIL_DATA]: (state, { payload }) => payload
}, OrderDetailStatus.IDLE);

export default combineReducers({
  orderDetailStatus,
  orderDetailData
});
