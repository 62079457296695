import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import { AddEditOrganisationStatus, CatalogueListStatus, OrganisationDetailStatus } from './store/constants';
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import Loading from '../../components/Loading/Loading';
import { get } from 'lodash';

import(/* webpackChunkName: "addEditOrganisation" */'./addEditOrganisation.scss');

const AddEditOrganisation = ({
  saveOrganisationStatus,
  organisationDetailStatus,
  organisationDetailData,
  catalogueList,
  catalogueListStatus,
  loadOrganisationData,
  saveOrganisationData,
  loadCatalogueListData,
  organisationListData,
  loadOrganisationListData
}) => {
  const history = useHistory();
  let { organisationCode } = useParams();
  const [organisationData, setOrganisationData] = useState({
    organisationCode: '',
    organisationName: '',
    phonakCatalogueCode: '',
    isEditing: false
  });

  const [localCatalogueList, setLocalCatalogueList] = useState([]);

  // catalogue list
  useEffect(() => {
    if (!catalogueList && loadCatalogueListData) {
      loadCatalogueListData();
    }
  }, [catalogueList, loadCatalogueListData]);

  useEffect(() => {
    if (catalogueList) {
      setLocalCatalogueList([{Code: '', Name: 'Please select'}, ...catalogueList]);
    }
  }, [catalogueList]);

  // organisationd detail
  useEffect(() => {
    if (organisationCode && loadOrganisationData) {
      loadOrganisationData(organisationCode);
    }
  }, [loadOrganisationData, organisationCode]);

  useEffect(() => {
    if (organisationCode && organisationDetailData) {
      setOrganisationData({
        organisationCode: get(organisationDetailData, 'OrganisationCode', '') ,
        organisationName: get(organisationDetailData, 'OrganisationName', '') ,
        phonakCatalogueCode: get(organisationDetailData, 'PhonakCatalogueCode', '') ,
        // unitronCatalogueCode: get(organisationDetailData, 'UnitronCatalogueCode', ''),
        isEditing: true 
      });
    }
  }, [organisationDetailData, organisationCode]);

  // organisation list
  useEffect(() => {
    if (!organisationListData && loadOrganisationListData) {
      loadOrganisationListData();
    }
  }, [organisationListData, loadOrganisationListData]);

  function handleSubmit(e) {
    e.preventDefault();
    // //console.log(organisationData);
    saveOrganisationData(organisationData, organisationListData);
    history.push('/admin/organisation')
    // setOrganisationData({
    //   organisationCode: '',
    //   organisationName: '',
    //   phonakCatalogueCode: '',
    //   unitronCatalogueCode: '',
    //   isEditing: false
    // });
  }

  function handleChange(e) {
    setOrganisationData({
      ...organisationData,
      [e.target.name]: e.target.value 
    });
  }

  if (catalogueListStatus === CatalogueListStatus.LOADING || saveOrganisationStatus === AddEditOrganisationStatus.LOADING || 
    organisationDetailStatus === OrganisationDetailStatus.LOADING) {
    return (
      <Col>
        <div>
          <Loading />
        </div>
      </Col>
    )
  }

  if (catalogueListStatus === CatalogueListStatus.ERROR || saveOrganisationStatus === AddEditOrganisationStatus.ERROR || 
    organisationDetailStatus === OrganisationDetailStatus.ERROR) {
    return (
      <Redirect to='/' />
    )
  }

  // if (saveOrganisationStatus === AddEditOrganisationStatus.SUCCESS ) {
  //   return (
  //     <Redirect to={`/admin/organisation/${organisationData.organisationCode}/detail`} />
  //   )
  // }

  return (
    <div className="dashboard-title">
      <h1 className='mt-5'>{`${organisationCode ? 'Edit' : 'Add'} an Organisation`}</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Row>
                {/* <Col className="pb-4" xs={3}>
                  <Form.Label>Organisation Code</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="organisationCode"
                    // defaultValue={""}
                    value={organisationData.organisationCode}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                    readOnly={organisationData.isEditing}
                  />
                </Col> */}
                <Col className="pb-4" xs={3}>
                  <Form.Label>Organisation Name</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="organisationName"
                    // defaultValue={""}
                    value={organisationData.organisationName}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col className="pb-4" xs={3}>
                  <Form.Label>Catalogue</Form.Label>
                </Col>
                <Col xs={9}>
                  {/* <Form.Control
                    type="text"
                    name="phonakCatalogueCode"
                    // defaultValue={""}
                    value={organisationData.phonakCatalogueCode}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                  /> */}
                  <Form.Select
                    name="phonakCatalogueCode"
                    // defaultValue={""}
                    value={organisationData.phonakCatalogueCode ? organisationData.phonakCatalogueCode : ''}
                    className="me-2 form-control"
                    aria-label="phonakCatalogueCode"
                    onChange={handleChange}
                    required
                  >
                    {
                      localCatalogueList && 
                      localCatalogueList.map((item, idx) => (
                        <option value={item['Code']} key={idx}>{item['Name']}</option>
                      ))
                    }
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <div className="float-right">
              <Button type="submit" variant="success mt-2">
                Save
              </Button>
              <Link to={organisationCode ? `/admin/organisation/${organisationCode}/detail` : '/admin/organisation'}>
                <Button variant="warning mt-2 ml-2">
                  Cancel
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        
      </Form>
      
    </div>
  )
};

AddEditOrganisation.propTypes = {
  saveOrganisationData: PropTypes.func,
  saveOrganisationStatus: PropTypes.string,
  organisationDetailStatus: PropTypes.string,
  organisationDetailData: PropTypes.object,
  catalogueList: PropTypes.array,
  loadOrganisationData: PropTypes.func,
  catalogueListStatus: PropTypes.string,
  loadCatalogueListData: PropTypes.func,
  organisationListData: PropTypes.array,
  loadOrganisationListData: PropTypes.func
};

export default AddEditOrganisation;
