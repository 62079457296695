import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { ProfileStatus } from './constants';

const profileStatus = createReducer({
  [actions.SET_PROFILE_STATUS]: (state, { payload }) => payload
}, ProfileStatus.IDLE);

const profileData = createReducer({
  [actions.SET_PROFILE_DATA]: (state, { payload }) => payload
}, ProfileStatus.IDLE);

const orderData = createReducer({
  [actions.SET_ORDER_DATA]: (state, { payload }) => payload
}, ProfileStatus.IDLE);

const changePasswordStatus = createReducer({
  [actions.SET_CHANGE_PASSWORD_STATUS]: (state, { payload }) => payload
}, ProfileStatus.IDLE);

export default combineReducers({
  profileStatus,
  profileData,
  orderData,
  changePasswordStatus
});
