import { createSelector } from 'reselect';

const orderDetail = state => state.modules.orderDetail;

export const getOrderDetailStatus = createSelector(
  orderDetail,
  state => state.orderDetailStatus
);


export const getOrderDetailData = createSelector(
  orderDetail,
  state => state.orderDetailData
);