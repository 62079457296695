import { connect } from 'react-redux';
import AddEditOrganisation from './addEditOrganisation';
import { getSaveOrganisationStatus, getOrganisationDetailStatus, getOrganisationDetailData, getCatalogueList, getCatalogueListStatus } from './store/selectors';
import { saveOrganisationData, loadOrganisationData, loadCatalogueListData } from './store/action-creators';
import { getOrganisationListData } from '../organisations/store/selectors';
import { loadOrganisationListData } from '../organisations/store/action-creators';


const mapStateToProps = (state) => ({
  saveOrganisationStatus: getSaveOrganisationStatus(state),
  organisationDetailStatus: getOrganisationDetailStatus(state),
  organisationDetailData: getOrganisationDetailData(state),
  catalogueList: getCatalogueList(state),
  catalogueListStatus: getCatalogueListStatus(state),
  organisationListData: getOrganisationListData(state)
});

const mapDispatchToProps = {
  saveOrganisationData,
  loadOrganisationData,
  loadCatalogueListData,
  loadOrganisationListData
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditOrganisation);