import { connect } from 'react-redux';
import AdministratorsList from './administratorsList';
import { getAdministratorsListStatus, getAdministratorsListData } from '../../store/selectors';
import { loadAdministratorsListData } from '../../store/action-creators';
import { getProfileData } from '../../../login/store/selectors';


const mapStateToProps = (state) => ({
  administratorsListStatus: getAdministratorsListStatus(state),
  administratorsListData: getAdministratorsListData(state),
  profileData: getProfileData(state)
});

const mapDispatchToProps = {
  loadAdministratorsListData
};

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorsList);