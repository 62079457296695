export const SAVE_SITE_DATA = 'sites/SAVE_SITE_DATA';
export const SET_SAVE_SITE_STATUS = 'sites/SET_SAVE_SITE_STATUS';

export const LOAD_SITEDETAIL_DATA = 'sites/LOAD_SITEDETAIL_DATA';
export const SET_SITEDETAIL_STATUS = 'sites/SET_SITEDETAIL_STATUS';
export const SET_SITEDETAIL_DATA = 'sites/SET_SITEDETAIL_DATA';

export const SAVE_SITEUSER_DATA = 'sites/SAVE_SITEUSER_DATA';
export const SET_SAVE_SITEUSER_STATUS = 'sites/SET_SAVE_SITEUSER_STATUS';





