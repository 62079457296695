import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { find } from 'lodash';
import ProductCard from '../../components/ProductCard/ProductCard';
import { Container, Row, Col } from "react-bootstrap";
import(/* webpackChunkName: "family-category" */'./familyCategory.scss');

const FamilyCategory = ({
  productsData
}) => {
  const [ productFamily, setProductFamily ] = useState();
  const [ familyName, setFamilyName ] = useState();
  const { productId } = useParams();
  
  useEffect(() => {
    if (productsData) {
      let temporaryProductFamily = find(productsData, { 'Code': productId })
      setProductFamily(temporaryProductFamily);
      setFamilyName(temporaryProductFamily.Name);
      temporaryProductFamily = temporaryProductFamily.InverseParentCodeNavigation
      setProductFamily(temporaryProductFamily);
    }
  }, [productId, productsData]);

  return (
    <div className="family-category-main-div">
      <div className="family-category-content">
        <h1>{familyName}</h1>
      </div>
      {productFamily &&
        <Row className="family-category-cards">
        {productFamily.map((product, index) => (
          <ProductCard product={product} key={index} index={index} />
        ))}
        </Row>
      }
    </div>
  )
};

FamilyCategory.propTypes = {
  loadFamilyCategory: PropTypes.func,
  productsData: PropTypes.array,
}

export default FamilyCategory;
