import { create } from 'lodash';
import { createSelector } from 'reselect';

// This code maps the application wide state to just the relevant pieces of state.
// The 'relevant pieces of state' are defined in reducers.
const administrators = state => state.modules.administrators;

export const getAdministratorsListStatus = createSelector(
  administrators,
  state => state.administratorsListStatus
);

export const getAdministratorsListData = createSelector(
  administrators,
  state => state.administratorsListData
);

export const getAdministratorDetailStatus = createSelector(
  administrators,
  state => state.administratorDetailStatus
);

export const getAdministratorDetailData = createSelector(
  administrators,
  state => state.administratorDetailData
);

export const getSaveAdminStatus = createSelector(
  administrators,
  state => state.saveAdminStatus
);

export const getErrorStatus = createSelector(
  administrators,
  state => state.errorStatus
);