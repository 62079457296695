import React, { Fragment, useEffect, useState } from 'react';
import PropTypes, { checkPropTypes } from 'prop-types';
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import { SiteDetailStatus, AddEditSiteStatus } from '../../store/constants';
import { OrganisationListStatus } from '../../../organisations/store/constants'
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
// import Loading from '../../components/Loading/Loading';
import Loading from '../../../../components/Loading/Loading';
import { get } from 'lodash';
// import { AddEditSiteStatus } from '../../store/constants';

import(/* webpackChunkName: "addEditSite" */'./addEditSite.scss');

const AddEditSite = ({
  siteDetailStatus,
  siteDetailData,
  saveSiteStatus,
  organisationListData,
  organisationListStatus,
  loadSiteDetailData,
  loadOrganisationListData,
  saveSiteData,
  organisationDetailData,
  loadOrganisationDetailData
  // setSaveSiteStatus
}) => {
  const history = useHistory();
  let { siteCode, orgCode } = useParams();
  const [siteData, setSiteData] = useState({
    siteCode: '',
    siteName: '',
    organisationCode: orgCode,
    isEditing: false
  });

  useEffect(() => {
    if (orgCode && loadOrganisationDetailData && !organisationDetailData) {
      loadOrganisationDetailData(orgCode);
    }
  },[organisationDetailData, loadOrganisationDetailData]);

  useEffect(() => {
    if (siteCode && loadSiteDetailData) {
      loadSiteDetailData(siteCode);
    }
  }, [loadSiteDetailData, siteCode]);

  useEffect(() => {
    if (siteCode && siteDetailData) {
      setSiteData({
        siteCode: get(siteDetailData, 'SiteCode', '') ,
        siteName: get(siteDetailData, 'SiteName', '') ,
        organisationCode: get(siteDetailData, 'OrganisationCode', ''),
        isEditing: true 
      });
    }
  }, [siteDetailData, siteCode]);

  function handleSubmit(e) {
    e.preventDefault();
    saveSiteData(siteData, organisationDetailData);
    history.push(`/admin/organisation/${siteData.organisationCode}/detail`);
  }

  function handleChange(e) {
    setSiteData({
      ...siteData,
      [e.target.name]: e.target.value 
    });
  }

  if (siteDetailStatus === SiteDetailStatus.LOADING || saveSiteStatus === AddEditSiteStatus.LOADING) {
    return (
      <Col>
        <div>
          <Loading />
        </div>
      </Col>
    )
  }

  if (siteDetailStatus === SiteDetailStatus.ERROR || saveSiteStatus === AddEditSiteStatus.ERROR ) {
    return (
      // <Redirect to='/admin/organisation' />
      <Redirect to={`/admin/organisation/${orgCode}/sites/add`} />

    )
  }

  // if (saveSiteStatus === AddEditSiteStatus.SUCCESS) {
  //   // setSaveSiteStatus();
  //   return (
  //     <Redirect to={`/admin/site/${siteData['siteCode']}/detail`} />
  //   );
  // }

  return (
    <div className="dashboard-title">
      <h1 className='mt-5'>{`${siteCode ? 'Edit' : 'Add'} a Site`}</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Row>
                {/* <Col className="pb-4" xs={3}>
                  <Form.Label>Site Code</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="siteCode"
                    // defaultValue={""}
                    value={siteData.siteCode}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                    readOnly={siteData.isEditing}
                  />
                </Col> */}
                <Col className="pb-4" xs={3}>
                  <Form.Label>Site Name</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="siteName"
                    // defaultValue={""}
                    value={siteData.siteName}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col>
                {/* <Col className="pb-4" xs={3}>
                  <Form.Label>Organisation Code</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Select
                    name='organisationCode'
                    value={siteData.organisationCode ? siteData.organisationCode : ''}
                    className='me-2 form-control'
                    aria-level='organisationCode'
                    onChange={handleChange}
                    required
                  >
                    {
                      orgList &&
                      orgList.map((org, index) => (
                        <option value={org['OrganisationCode']} key={org['OrganisationCode']}>{org['OrganisationName']}</option>
                      ))
                    }

                  </Form.Select>
                </Col> */}
              </Row>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <div className="float-right">
              <Button type="submit" variant="success mt-2">
                Save
              </Button>
              <Link to={siteCode ? `/admin/site/${siteCode}/detail`: `/admin/organisation/${orgCode}/detail`}>
                <Button variant="warning mt-2 ml-2">
                  Cancel
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        
      </Form>
      
    </div>
  );
};

AddEditSite.propTypes = {
  saveSiteData: PropTypes.func,
  // setSaveSiteStatus: PropTypes.func,
  saveSiteStatus: PropTypes.string,
  siteDetailStatus: PropTypes.string,
  siteDetailData: PropTypes.object,
  loadSiteDetailData: PropTypes.func,
  organisationListData: PropTypes.array,
  organisationListStatus: PropTypes.string,
  loadOrganisationListData: PropTypes.func,
  organisationDetailData: PropTypes.object,
  loadOrganisationDetailData: PropTypes.func
};

export default AddEditSite;
