import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { AdministratorsListStatus, AdministratorDetailStatus, AddAdminStatus } from './constants';


// This code defines the pieces of state that the module will store, and also deals with the actions defined in actions.js that will
// affect the application state.
const administratorsListStatus = createReducer({
  [actions.SET_ADMINISTRATORSLIST_STATUS]: (state, { payload }) => payload
}, AdministratorsListStatus.IDLE);

const administratorsListData = createReducer({
  [actions.SET_ADMINISTRATORSLIST_DATA]: (state, { payload }) => payload
}, AdministratorsListStatus.IDLE);

const administratorDetailStatus = createReducer({
  [actions.SET_ADMINISTRATORDETAIL_STATUS]: (state, { payload }) => payload
}, AdministratorDetailStatus.IDLE);

const administratorDetailData = createReducer({
  [actions.SET_ADMINISTRATORDETAIL_DATA]: (state, { payload }) => payload
}, AdministratorDetailStatus.IDLE);

const saveAdminStatus = createReducer({
  [actions.SET_SAVE_ADMIN_STATUS]: (state, { payload }) => payload
}, AddAdminStatus.IDLE);

const errorStatus = createReducer({
  [actions.SET_ERROR_STATUS]: (state, { payload }) => payload
}, AddAdminStatus.IDLE);

export default combineReducers({
  administratorsListStatus,
  administratorsListData,
  administratorDetailStatus,
  administratorDetailData,
  saveAdminStatus,
  errorStatus
});
