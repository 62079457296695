import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { sortBy } from "lodash";
import(
  /* webpackChunkName: "order-builder-ite-performance-level" */ "./itePerformanceLevel.scss"
);

const ITEPerformanceLevel = ({
  setITEPerformanceLevelForOrder,
  itePerformanceLevelCodes,
}) => {
  const [activeITEPerformanceLevel, setActiveITEPerformanceLevel] = useState();
  const [tempPerformanceLevelCodes, setTempPerformanceLevelCodes] = useState([]);

  useEffect(() => {
    // Set defualt active performance level
    if(itePerformanceLevelCodes && itePerformanceLevelCodes[0]) {
      const tempPerformanceLevelCodesArray = sortBy(itePerformanceLevelCodes, o => o.Code)
      setTempPerformanceLevelCodes(tempPerformanceLevelCodesArray);
    }
  }, [itePerformanceLevelCodes])

  const setActive = (itePerformanceLevel) => {
    setActiveITEPerformanceLevel(itePerformanceLevel);
    setITEPerformanceLevelForOrder(itePerformanceLevel);
  };

  return (
    <div>
      {tempPerformanceLevelCodes && (
        <div className="performance-level-component-main-div order-builder-component">
          <Row className="order-builder-component-title">
            <Col xs={12}>
              <h2 className="">
                Choose a performance level
              </h2>
            </Col>
            {tempPerformanceLevelCodes.map((itePerformanceLevel, index) => (
              <Col key={index} md={3}>
                <a
                  onClick={() => {
                    setActive(itePerformanceLevel.Code);
                  }}
                >
                  <div
                    className={`performance-level-box ${
                      activeITEPerformanceLevel === itePerformanceLevel.Code
                        ? "active"
                        : ""
                    }`}
                  >
                    <div className="performance-level-box-text">
                      <p>{itePerformanceLevel.Name}</p>
                      {/* <p>{itePerformanceLevel.Code}</p> */}
                    </div>
                  </div>
                </a>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default ITEPerformanceLevel;
