import { createSelector } from 'reselect';

const orderConfirmation = state => state.modules.orderConfirmation;

export const getOrderConfirmationStatus = createSelector(
  orderConfirmation,
  state => state.orderConfirmationStatus
);

export const getSentOrderConfirmationStatus = createSelector(
  orderConfirmation,
  state => state.sentOrderConfirmationStatus
);

export const getOrderNumber = createSelector(
  orderConfirmation,
  state => state.orderNumber
);