// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import * as actionCreators from './action-creators';
import * as orgActionCreators from '../../organisations/store/action-creators';
import { LOAD_CATALOGUELIST_DATA, LOAD_ORGANISATION_DATA, SAVE_ORGANISATION_DATA } from './actions';
import { AddEditOrganisationStatus, OrganisationDetailStatus, CatalogueListStatus } from './constants';
import api from '../../../utilities/api';
import { get } from 'lodash';

export function* rootSaga() {
  yield takeLatest(LOAD_ORGANISATION_DATA, loadOrganisationData);
  yield takeLatest(SAVE_ORGANISATION_DATA, saveOrganisationData);
  yield takeLatest(LOAD_CATALOGUELIST_DATA, loadCatalogueList);

}

export function* loadCatalogueList() {
  try {
    yield put(actionCreators.setCatalogueListStatus(CatalogueListStatus.LOADING));
    
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken? This will logout if there is no token. Handled in the private layout
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    const catalogueListResponse = yield call(api.get, '/api/catalogue', { headers: headerParams });
    //console.log('catalogue list', catalogueListResponse.data);
    
    yield put(actionCreators.setCatalogueListData(catalogueListResponse.data));
    yield put(actionCreators.setCatalogueListStatus(CatalogueListStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setCatalogueListStatus(CatalogueListStatus.ERROR));
  }
}

// user detail related
export function* loadOrganisationData(organisationIdPayload) {
  try {
    //console.log(organisationIdPayload);
    yield put(actionCreators.setOrganisationStatus(OrganisationDetailStatus.LOADING));
    //console.log(organisationIdPayload);
    const organisationId = get(organisationIdPayload, "payload", {});
    
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    yield delay(2000);
    
    const response = yield call(api.get, `/api/organisation/${organisationId}`, {headers:headerParams});
    //console.log('organisation data', response.data);
    
    yield put(actionCreators.setOrganisationData(response.data));
    yield put(actionCreators.setOrganisationStatus(OrganisationDetailStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setOrganisationStatus(OrganisationDetailStatus.ERROR));
  }
}

export function* saveOrganisationData(data) {
  try {
    yield put(actionCreators.setSaveOrganisationStatus(AddEditOrganisationStatus.LOADING));
    let orgList = get(data, "0", []);
    let userData = get(data, "payload", {});
    let body = {
      'OrganisationName': get(userData, "organisationName", null),
      'PhonakCatalogueCode': get(userData, "phonakCatalogueCode", null)
    };
    

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken?
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    // //console.log('creating organisation:',body);
    let response;
    if (!userData.isEditing) {
      //console.log('adding organisation', body);
      response = yield call(api.post, '/api/organisation', body, {headers:headerParams});
      orgList.push(response.data);
    } else {
      body.OrganisationCode = get(userData, 'organisationCode', null);
      //console.log('updating organisation', body);
      response = yield call(api.put, '/api/organisation', body, {headers:headerParams});
      orgList = orgList.map(o => {
        if (o.OrganisationCode == response.data.OrganisationCode) {
          return response.data;
        }
        return o;
      });
    }
    
    yield put(orgActionCreators.setOrganisationListData(orgList));
    yield put(actionCreators.setSaveOrganisationStatus(AddEditOrganisationStatus.SUCCESS));
    
  }
  catch (error) {
    
    //console.log('error', error);
    yield put(actionCreators.setSaveOrganisationStatus(AddEditOrganisationStatus.ERROR));
  }
}

