// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import * as actionCreators from './action-creators';
import { LOAD_ADMINISTRATORSLIST_DATA, LOAD_ADMINISTRATORDETAIL_DATA, SAVE_ADMIN_DATA, RESET_SAVEADMIN_FORM } from './actions';
import { AdministratorsListStatus, AdministratorDetailStatus, AddAdminStatus } from './constants';
import api from '../../../utilities/api';
import { get } from 'lodash';
import AddAdmin from '../views/addAdmin/addAdmin';

export function* rootSaga() {
  yield takeLatest(LOAD_ADMINISTRATORSLIST_DATA, loadAdministratorsList);
  yield takeLatest(LOAD_ADMINISTRATORDETAIL_DATA, loadAdministratorDetail);
  yield takeLatest(SAVE_ADMIN_DATA, saveAdminData);
  yield takeLatest(RESET_SAVEADMIN_FORM, resetSaveAdminForm);
}

// reset addAdmin status
export function* resetSaveAdminForm() {
  try {
    // reset form status and form admin detail (may change this)
    yield put(actionCreators.setSaveAdminStatus(AddAdminStatus.IDLE));
    yield put(actionCreators.setAdminstratorDetailData());
    yield put(actionCreators.setErrorStatus(''));
    //console.log('reset admin form');
  }
  catch(error) {
    yield put(actionCreators.setSaveAdminStatus(AddAdminStatus.ERROR));
  }
}

export function* loadAdministratorsList() {
  try {
    yield put(actionCreators.setAdministratorsListStatus(AdministratorsListStatus.LOADING));

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    const response = yield call(api.get, '/api/account/adminList', {headers:headerParams});
    
    
    yield delay(300);
    yield put(actionCreators.setAdminstratorsListData(response.data));
    yield put(actionCreators.setAdministratorsListStatus(AdministratorsListStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setAdministratorsListStatus(AdministratorsListStatus.ERROR));
  }
}

export function* loadAdministratorDetail(adminIdPayload) {
  try {
    //console.log(adminIdPayload);
    yield put(actionCreators.setAdministratorDetailStatus(AdministratorDetailStatus.LOADING));
    const adminId = get(adminIdPayload, "payload", {});
    
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    yield delay(300);
    const response = yield call(api.get, `/api/account/adminList/${adminId}`, {headers:headerParams});
    //console.log('admin data', response.data);
    
    yield put(actionCreators.setAdminstratorDetailData(response.data));
    yield put(actionCreators.setAdministratorDetailStatus(AdministratorDetailStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setAdministratorDetailStatus(AdministratorDetailStatus.ERROR));
  }
}

export function* saveAdminData(data) {
  try {
    yield put(actionCreators.setSaveAdminStatus(AddAdminStatus.LOADING));
    let adminList = get(data, "0", {});
    let userData = get(data, "payload", {});
    //console.log('adminlist', adminList);
    let body;
    if (userData.isEditing) {
      body = {
        'FirstName': get(userData, "firstName", ""),
        'LastName': get(userData, "lastName", ""),
        'EmailAddress': get(userData, "email", ""),
        'OrganisationCode': get(userData, "organisationCode", ""),
        'UserId': get(userData, "userId", ""),
        // 'SiteCodes': get(userData, "siteCodes", ""),
        'RoleName': get(userData, "roleName", ""),
      };
    } else {
      body = {
        'FirstName': get(userData, "firstName", ""),
        'LastName': get(userData, "lastName", ""),
        'EmailAddress': get(userData, "email", ""),
        'OrganisationCode': get(userData, "organisationCode", ""),
        // 'SiteCodes': get(userData, "siteCodes", ""),
        'Password': get(userData, "password", ""),
        'RoleName': get(userData, "roleName", ""),
      };
    }

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken?
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    let response;
    if (!userData.isEditing) {
      response = yield call(api.post, '/api/account/register', body, {headers:headerParams});
      adminList.push(response.data);
    } else {
      response = yield call(api.put, '/api/account', body, {headers:headerParams});
      adminList = adminList.map(u => {
        if (u.UserId == response.data.UserId) {
          return response.data;
        } 
        return u;
      });
    }
    // //console.log(response.data);
    yield put(actionCreators.setAdminstratorsListData(adminList));
    yield put(actionCreators.setSaveAdminStatus(AddAdminStatus.SUCCESS));
    
  }
  catch (error) {
    //console.log(error.response);
    yield put(actionCreators.setErrorStatus(error.response.data));
    yield put(actionCreators.setSaveAdminStatus(AddAdminStatus.ERROR));
  }
}

