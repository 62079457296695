import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import { AddAdminStatus, AdministratorDetailStatus } from '../../store/constants';
import { OrganisationListStatus } from '../../../organisations/store/constants';
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import Loading from '../../../../components/Loading/Loading';
import { get } from 'lodash';

import(/* webpackChunkName: "addAdmin" */'./addAdmin.scss');

const AddAdmin = ({
  saveAdminStatus,
  administratorDetailStatus,
  administratorDetailData,
  loadAdministratorDetailData,
  saveAdminData,
  organisationListData,
  organisationListStatus,
  loadOrganisationListData,
  organisationDetailData,
  organisationDetailStatus,
  loadOrganisationDetailData,
  currentUserDetails,
  administratorsListData,
  loadAdministratorsListData,
  errorStatus,
  resetSaveAdminForm
}) => {
  const history = useHistory();
  let { adminId } = useParams();
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    organisationCode: '',
    // siteCode: '',
    password: '',
    roleName: '',
    userId: '',
    isEditing: false
  });
  const [userSites, setUserSites] = useState({});
  const [orgList, setOrgList] = useState([{OrganisationCode: '', OrganisationName: 'Please Select'}]);
  // loading site dropdown options
  const [siteList, setSiteList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  // determining valid roles to create
  // const roles = {
  //   sonovasuperuser: [3, 'Super User'],
  //   sonovawhs: [2, 'WHS Admin'],
  //   customeradmin: [1, 'Customer Admin'],
  //   customeruser: [0, 'Customer User']
  // };
  const roleValues = {
    sonovasuperuser: 3,
    sonovawhs: 2,
    customeradmin: 1,
    customeruser: 0
  };

  const roles = [
    {role: 'sonovasuperuser', value: 3, display: 'Super User'},
    {role: 'sonovawhs', value: 2, display: 'WHS Admin'},
    // {role: 'customeradmin', value: 1, display: 'Customer Admin'},
    // {role: 'customeruser', value: 0, display: 'Customer User'}
  ];

  // redirect to admin list page if successfully saved
  useEffect(() => {
    //console.log(window.location.pathname);
    if (saveAdminStatus === AddAdminStatus.SUCCESS) {
      resetSaveAdminForm();
      history.push('/admin/adminusers');
    }
  }, [saveAdminStatus, resetSaveAdminForm]);
  
  // set list of roles for dropdown list
  useEffect(() => {
    if (currentUserDetails) {
      setRoleList([{role: '', display: 'Please select'},...roles.filter(r => r.value <= roleValues[currentUserDetails.RoleName] )]);
    }
  }, [currentUserDetails]);
  
  // DEPRECATED since form no longer has site list.

  // load organisation detail data to load sitelist. 
  // useEffect(() => {
  //   if (loadOrganisationDetailData) {
  //     loadOrganisationDetailData(1); // CHANGE THIS
  //   }
  // }, [loadOrganisationDetailData]);

  // useEffect(() => {
  //   if (organisationDetailData) {
  //     setSiteList([...organisationDetailData["Sites"]]);
  //   }
  // }, [organisationDetailData]);

  // load organisation list data to load orgList
  useEffect(() => {
    if (loadOrganisationListData) {
      loadOrganisationListData(); 
    }
  }, [loadOrganisationListData]);

  useEffect(() => {
    if (organisationListData) {
      setOrgList([{OrganisationCode: '', OrganisationName: 'Please Select'},...organisationListData]);
    }
  }, [organisationListData]);

  // admin list
  useEffect(() => {
    if (!administratorsListData && loadAdministratorsListData) {
      loadAdministratorsListData();
    }
  }, [administratorsListData, loadAdministratorsListData])

  // loading user details if editing
  useEffect(() => {
    if (adminId && loadAdministratorDetailData) {
      loadAdministratorDetailData(adminId);
    } else {
      setUserData({
        firstName: '',
        lastName: '',
        email: '',
        organisationCode: '', 
        // siteCode: '',
        password: '',
        roleName: '',
        userId: '',
        isEditing: false
      })
    }
  }, [loadAdministratorDetailData]);

  useEffect(() => {
    if (administratorDetailData && adminId) {
      setUserData({
        ...userData,
        firstName: get(administratorDetailData, 'FirstName', '') ,
        lastName: get(administratorDetailData, 'LastName', '') ,
        email: get(administratorDetailData, 'EmailAddress', '') ,
        organisationCode: get(administratorDetailData, 'OrganisationCode', '') ,
        // siteCode: get(administratorDetailData, 'SiteCode','') ,
        password: '',
        roleName: get(administratorDetailData, 'RoleName', '') ,
        userId: get(administratorDetailData, 'UserId', ''),
        isEditing: true        
      });
      setUserSites(
        [...get(administratorDetailData, 'SiteCodes',[])].reduce((accum, siteCode) => ({...accum, [siteCode]: true}), {})
      );
    }
    else {
      setUserData({
        firstName: '',
        lastName: '',
        email: '',
        organisationCode: '',
        // siteCode: '',
        password: '',
        roleName: '',
        userId: '',
        isEditing: false
      })
    }
  },[administratorDetailData]);

  function handleSubmit(e) {
    e.preventDefault();
    // //console.log(userData);
    // //console.log(Object.keys(userSites).filter(siteCode => userSites[siteCode]));
    // //console.log('admin list passing',administratorsListData);
    
    // console.log(userData);
    
    saveAdminData({
      ...userData,
      siteCodes: []
    }, administratorsListData);
  }

  function handleChange(e) {
    // //console.log(Object.keys(roles).filter(r => roles[r] <= roles[currentUserDetails.RoleName]));
    // console.log(orgList);
    setUserData({
      ...userData,
      [e.target.name]: e.target.value 
    });
    // //console.log(administratorDetailData);
  }

  function handleCheckBoxChange(e) {
    let currentVal = get(userSites, e.target.name, false);
    setUserSites(
      {
        ...userSites,
        [e.target.name]: !currentVal
      }
    );
  }

  if (saveAdminStatus === AddAdminStatus.LOADING || 
    administratorDetailStatus === AdministratorDetailStatus.LOADING ||
    organisationListStatus === OrganisationListStatus.ERROR) {
    return (
      <Col>
        <div>
          <Loading />
        </div>
      </Col>
    )
  }

  return (
    <div className="dashboard-title">
      {/* Dashboard: <span>{administratorsListStatus}</span> */}
      <h1 className='mt-5'>{`${adminId ? 'Edit' : 'Add'} Admin`}</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Row>
                <Col className="pb-4" xs={3}>
                  <Form.Label>First Name</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="firstName"
                    // defaultValue={""}
                    value={userData.firstName}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col className="pb-4" xs={3}>
                  <Form.Label>Last Name</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="lastName"
                    // defaultValue={""}
                    value={userData.lastName}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col className="pb-4" xs={3}>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="email"
                    name="email"
                    // defaultValue={""}
                    value={userData.email}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col className="pb-4" xs={3}>
                  <Form.Label>Organisation</Form.Label>
                </Col>
                <Col xs={9}>
                  
                  <Form.Select
                    name='organisationCode'
                    value={userData.organisationCode ? userData.organisationCode : ''}
                    className='me-2 form-control'
                    aria-level='organisationCode'
                    onChange={handleChange}
                    required
                  >
                    {
                      orgList &&
                      orgList.map((org, index) => (
                        <option value={org['OrganisationCode']} key={org['OrganisationCode']}>{org['OrganisationName']}</option>
                      ))
                    }

                  </Form.Select>
                </Col>
                {/* <Col className="pb-4" xs={3}>
                  <Form.Label>Site</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="siteCode"
                    // defaultValue={""}
                    value={userData.siteCode}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    // required
                  />
                </Col> */}
                {/* <Col className="pb-4" xs={3}>
                  <Form.Label>Site</Form.Label>
                </Col>
                <Col xs={9}>
                  {
                    siteList && 
                    siteList.map((site, index) => (
                      <Form.Check 
                        key={site.SiteCode}
                        type='checkbox'
                        label={site.SiteName}
                        onChange={handleCheckBoxChange}
                        // checked={get(userData['siteCodes'], site.SiteCode, false)}
                        checked={get(userSites, site.SiteCode, false)}
                        name={site.SiteCode}
                      />
                    ))
                  }
                </Col> */}
                {
                  !userData.isEditing &&
                  <Fragment>
                    <Col className="pb-4" xs={3}>
                      <Form.Label>Password</Form.Label>
                    </Col>
                    <Col xs={9}>
                      <Form.Control
                        type="password"
                        name="password"
                        // defaultValue={""}
                        value={userData.password}
                        className="me-2"
                        aria-label="Password"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Fragment>
                }
                
                {/* <Col className="pb-4" xs={3}>
                  <Form.Label>Role</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    name="roleName"
                    // defaultValue={""}
                    value={userData.roleName}
                    className="me-2"
                    aria-label="Stock"
                    onChange={handleChange}
                    required
                  />
                </Col> */}
                <Col className="pb-4" xs={3}>
                  <Form.Label>Role</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Select
                    name='roleName'
                    value={userData.roleName ? userData.roleName : ''}
                    className='me-2 form-control'
                    aria-level='roleName'
                    onChange={handleChange}
                    required
                  >
                    {
                      roleList &&
                      roleList.map((role, index) => (
                        <option value={role.role} key={index}>{role.display}</option>
                      ))
                    }

                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          {
            saveAdminStatus === AddAdminStatus.ERROR && 
            <Col xs={12} className='alert alert-danger mt-2'>
              <p>{errorStatus}</p>
            </Col>
          }
          <Col xs={12}>
            <div className="float-right">
              <Button type="submit" variant="success mt-2">
                Save
              </Button>
              <Link to="/admin/adminusers">
                <Button variant="warning mt-2 ml-2">
                  Cancel
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        
      </Form>
      
    </div>
  )
};

AddAdmin.propTypes = {
  saveAdminData: PropTypes.func,
  saveAdminStatus: PropTypes.string,
  administratorDetailStatus: PropTypes.string,
  administratorDetailData: PropTypes.object,
  loadAdministratorDetailData: PropTypes.func,
  organisationListData: PropTypes.array,
  organisationListStatus: PropTypes.string,
  loadOrganisationListData: PropTypes.func,
  organisationDetailData: PropTypes.object,
  organisationDetailStatus: PropTypes.string,
  loadOrganisationDetailData: PropTypes.func,
  currentUserDetails: PropTypes.object,
  administratorsListData: PropTypes.array,
  loadAdministratorsListData: PropTypes.func,
  errorStatus: PropTypes.string,
  resetSaveAdminForm: PropTypes.func
};

export default AddAdmin;
