import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { SiteDetailStatus } from '../../store/constants';
import Loading from '../../../../components/Loading/Loading';
import { get } from 'lodash';
import { useParams, Link, useHistory } from 'react-router-dom';
import(/* webpackChunkName: "siteDetail" */'./siteDetail.scss');

const SiteDetail = ({
    siteDetailStatus,
    siteDetailData,
    loadSiteDetailData
  }) => {
    const { siteCode } = useParams();
    const [ pageSiteDetailData, setPageSiteDetailData ] = useState();
    useEffect(() => {
      if (loadSiteDetailData) {
        loadSiteDetailData(siteCode);
      }
    }, [loadSiteDetailData]);

    useEffect(() => {
      if (siteDetailData) {
        setPageSiteDetailData(siteDetailData);
      }
    }, [siteDetailData]);

    const history = useHistory();

    function handleClick(path) {
      history.push(path);
    }

    

    const SiteUserTableRow = ({ user }) => {
      const firstName = get(user, "FirstName", "default");
      const lastName = get(user, "LastName", "default");
      const emailAddress = get(user, "EmailAddress", "default");
      const roleName = get(user, "RoleName", "default");
      const userId = get(user, 'UserId', 'default');

      return (
        <tr>
        <td><Link to={`/admin/site/${siteCode}/users/${userId}/detail`}>{`${firstName} ${lastName}`}</Link></td>
        <td>{roleName}</td>
        <td>{emailAddress}</td>
        {/* <td>{siteId}</td> */}
      </tr>
      );
    };

    if (siteDetailStatus === SiteDetailStatus.LOADING) {
      return (
        <Col>
          <div>
            <Loading />
          </div>
        </Col>
      )
    }
    return (
      
      <div>
        {
          pageSiteDetailData && 
          <Row className="mt-5">
            <Col xs={12} className='mb-3'>
            <Link to={`/admin/organisation/${pageSiteDetailData['OrganisationCode']}/detail`}
                >
                  Back to Organisation
                </Link>
              </Col>
            <Col xs={12}>
              <h1 style={{display: 'inline-block', 'marginRight': '1rem'}}>{pageSiteDetailData['SiteName']}</h1>
              {
                pageSiteDetailData['CanEdit'] &&
                <Button
                  onClick={() => handleClick(`/admin/organisation/${pageSiteDetailData['OrganisationCode']}/sites/${pageSiteDetailData['SiteCode']}/edit`)}
                >
                  Edit
                </Button>
              }
              <h2 style={{margin: 0}}>{pageSiteDetailData['OrganisationName']}</h2>
            </Col>
          </Row>
        }
        <Row className="mt-3">
          <Col xs={12}>
            <h2 style={{display: 'inline-block', marginRight: '1rem'}}>Users</h2>
            {/* <Button
              onClick={() => handleClick(`/admin/site/${pageSiteDetailData['SiteCode']}/users/add`)}
            >
              Add
            </Button> */}
          </Col>
          <Col xs={12}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {
                  pageSiteDetailData && 
                  pageSiteDetailData["Users"].map((user, index) => (
                    <SiteUserTableRow key={index} user={user} />
                  ))
                }
              </tbody>
            </Table>
          </Col>
        </Row>        
      </div>
      
    )
};

SiteDetail.propTypes = {
  loadSiteDetailData: PropTypes.func,
  siteDetailStatus: PropTypes.string,
  siteDetailData: PropTypes.object
};

export default SiteDetail;
