import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { AdministratorsListStatus } from '../../store/constants';
import Loading from '../../../../components/Loading/Loading';
import { useParams, Link, useHistory } from 'react-router-dom';
import { get } from 'lodash';

import(/* webpackChunkName: "administratorsList" */'./administratorsList.scss');

const AdministratorsList = ({
  administratorsListStatus,
  administratorsListData,
  loadAdministratorsListData,
  profileData
}) => {
  const [ pageProfileData, setPageProfileData ] = useState();

  useEffect(() => {
    if (loadAdministratorsListData) {
      loadAdministratorsListData();
    }
  }, [loadAdministratorsListData]);

  useEffect(() => {
    if (profileData) {
      setPageProfileData(profileData);
    }
  }, [profileData]);

  const history = useHistory();

  function handleClick(path) {
    history.push(path);
  }

  const AdminTableRow = ({ admin }) => {
    const firstName = get(admin, "FirstName", "default");
    const lastName = get(admin, "LastName", "default");
    const emailAddress = get(admin, "EmailAddress", "default");
    const organisationCode = get(admin, "OrganisationCode", "default");
    const organisationName = get(admin, "OrganisationName", "default");
    const siteId = get(admin, "SiteCode", "default");
    const siteName = get(admin, "SiteName", "default");
    const userId = get(admin, "UserId", "default");

    return (
      <tr>
        <td><Link to={`/admin/adminusers/${userId}/detail`}>{`${firstName} ${lastName}`}</Link></td>
        <td>{emailAddress}</td>
        {/* <td><Link to={`/admin/organisation/${organisationCode}/detail`}>{organisationName}</Link></td> */}
        {/* <td><Link to={`/admin/site/${siteId}/detail`}>{siteName}</Link></td> */}
      </tr>
    );
  };

  if (administratorsListStatus === AdministratorsListStatus.LOADING) {
    return (
      <Col>
        <div>
          <Loading />
        </div>
      </Col>
    )
  }

  if (pageProfileData) {
    return (
      <div>
      <Row className='mt-5'>
        <Col xs={12}>
          <h1 style={{display: 'inline-block', marginRight: '1rem'}}>Site Administrators</h1>
          {
            
            pageProfileData['RoleName'] === 'sonovasuperuser' &&
            <Button
              onClick={() => handleClick('/admin/adminusers/add')}
            >
              Add
            </Button>
          }
        </Col>
        <Col xs={12} className="mt-3">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                {/* <th>Organisation</th> */}
                {/* <th>Site</th> */}
              </tr>
            </thead>
            <tbody>
              {
                administratorsListData && 
                administratorsListData.map((admin, index) => (
                  <AdminTableRow key={index} admin={admin} />
                ))
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      
    </div>
    );
  }

  return (
      <div></div>
  )
};

AdministratorsList.propTypes = {
  administratorsListStatus: PropTypes.string,
  administratorsListData: PropTypes.array,
  loadAdministratorsListData: PropTypes.func,
  profileData: PropTypes.object
};

export default AdministratorsList;
