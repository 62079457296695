import { create } from '../../../utilities/action-helpers';
import * as actions from './actions';

export const loadOrderBuilder = create(actions.LOAD_ORDER_BUILDER_DATA);
export const setOrderBuilderStatus = create(actions.SET_ORDER_BUILDER_STATUS);
export const setOrderBuilderData = create(actions.SET_ORDER_BUILDER_DATA);

export const loadConfigurationGroup = create(actions.LOAD_CONFIGURATION_GROUP_DATA);
export const setConfigurationGroupStatus = create(actions.SET_CONFIGURATION_GROUP_STATUS);
export const setConfigurationGroupData = create(actions.SET_CONFIGURATION_GROUP_DATA);
export const setConfigurationGroupLeft = create(actions.SET_CONFIGURATION_GROUP_LEFT);
export const setConfigurationGroupRight = create(actions.SET_CONFIGURATION_GROUP_RIGHT);

export const loadCustomOptionsGroup = create(actions.LOAD_CUSTOM_OPTIONS_GROUP_DATA);
export const setCustomOptionsGroupStatus = create(actions.SET_CUSTOM_OPTIONS_GROUP_STATUS);
export const setCustomOptionsGroupData = create(actions.SET_CUSTOM_OPTIONS_GROUP_DATA);
export const setCustomOptionsGroupLeft = create(actions.SET_CUSTOM_OPTIONS_GROUP_LEFT);
export const setCustomOptionsGroupRight = create(actions.SET_CUSTOM_OPTIONS_GROUP_RIGHT);

export const addOrderToCart = create(actions.ADD_ORDER_TO_CART);
export const setCartStatus = create(actions.SET_CART_STATUS);
export const setCartData = create(actions.SET_CART_DATA);