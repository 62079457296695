import { connect } from 'react-redux';
import AddOrgUser from './addOrgUser';
import { getSaveOrgUserStatus, getOrgUserDetailStatus, getOrgUserDetailData, getErrorStatus } from '../../store/selectors';
import { getOrganisationDetailData, getOrganisationDetailStatus } from '../../store/selectors';
import { saveOrgUserData, loadOrgUserData, loadOrganisationDetailData, resetSaveOrgUserStatus } from '../../store/action-creators';
import { getProfileData } from '../../../login/store/selectors';
import { AddOrgUserStatus } from '../../store/constants';


const mapStateToProps = (state) => ({
  saveOrgUserStatus: getSaveOrgUserStatus(state),
  orgUserDetailStatus: getOrgUserDetailStatus(state),
  orgUserDetailData: getOrgUserDetailData(state),
  currentUserDetails: getProfileData(state),
  organisationDetailData: getOrganisationDetailData(state),
  errorStatus: getErrorStatus(state)
});

const mapDispatchToProps = {
  saveOrgUserData,
  loadOrgUserData,
  loadOrganisationDetailData,
  resetSaveOrgUserStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrgUser);