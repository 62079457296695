import { connect } from 'react-redux';
import SiteDetail from './siteDetail';
import { getSiteDetailData, getSiteDetailStatus } from '../../store/selectors';
import { loadSiteDetailData } from '../../store/action-creators';


const mapStateToProps = (state) => ({
  siteDetailStatus: getSiteDetailStatus(state),
  siteDetailData: getSiteDetailData(state)
});

const mapDispatchToProps = {
  loadSiteDetailData
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetail);