import { connect } from 'react-redux';
import AddEditSite from './addEditSite';
import { getSiteDetailStatus, getSiteDetailData, getSaveSiteStatus } from '../../store/selectors';
import { getOrganisationListStatus, getOrganisationListData, getOrganisationDetailData } from '../../../organisations/store/selectors';

import { loadOrganisationListData, loadOrganisationDetailData } from '../../../organisations/store/action-creators';
import { saveSiteData, loadSiteDetailData, setSaveSiteStatus } from '../../store/action-creators';




const mapStateToProps = (state) => ({
  siteDetailStatus: getSiteDetailStatus(state),
  siteDetailData: getSiteDetailData(state),
  saveSiteStatus: getSaveSiteStatus(state),
  organisationListData: getOrganisationListData(state),
  organisationListStatus: getOrganisationListStatus(state),
  organisationDetailData: getOrganisationDetailData(state)
});

const mapDispatchToProps = {
  saveSiteData,
  loadSiteDetailData,
  loadOrganisationListData,
  loadOrganisationDetailData
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSite);