// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import * as actionCreators from './action-creators';
import { LOAD_PROFILE_DATA, START_CHANGE_PASSWORD, START_EDIT_PROFILE } from './actions';
import { ProfileStatus } from './constants';
import { get } from "lodash";
import api from '../../../utilities/api';

export function* rootSaga() {
  yield takeLatest(LOAD_PROFILE_DATA, loadProfile);
  yield takeLatest(START_EDIT_PROFILE, startEditProfile);
  yield takeLatest(START_CHANGE_PASSWORD, startChangePassword);
}

export function* loadProfile() {
  try {
    yield put(actionCreators.setProfileStatus(ProfileStatus.LOADING));
    let profileData = localStorage.getItem('profileData');
    //console.log("Loading profile data", profileData);

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    const response = yield call(api.get, '/api/order/myorders', {headers:headerParams});
    
    yield put(actionCreators.setOrderData(response.data));

    yield put(actionCreators.setProfileStatus(ProfileStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setProfileStatus(ProfileStatus.ERROR));
  }
}

export function* startEditProfile(profileData) {
  try {
    yield put(actionCreators.setProfileStatus(ProfileStatus.LOADING));
    
    const body = get(profileData, "payload", {});
    //console.log(body);

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    const response = yield call(api.put, '/api/account', body, {headers:headerParams});
    localStorage.setItem("profileData", JSON.stringify(body));
    yield put(actionCreators.setProfileData(body));

    //console.log(response.data)
    yield put(actionCreators.setProfileStatus(ProfileStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setProfileStatus(ProfileStatus.ERROR));
  }
}

export function* startChangePassword(passwords) {
  try {
    yield put(actionCreators.setProfileStatus(ProfileStatus.LOADING));
    //console.log(passwords);
    
    const newPassword = get(passwords, "payload", {});
    const oldPassword = get(passwords, "0", {});

    const body = {
      NewPassword: newPassword,
      OldPassword: oldPassword,
    }

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    const response = yield call(api.put, '/api/account/savenewpassword', body, {headers:headerParams});
    //console.log(response.data)
    yield put(actionCreators.setProfileStatus(ProfileStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setChangePasswordStatus(error.response.data));
    yield put(actionCreators.setProfileStatus(ProfileStatus.ERROR));
  }
}