import { connect } from 'react-redux';
import OrganisationDetail from './organisationDetail';
import { getOrganisationDetailStatus, getOrganisationDetailData } from '../../store/selectors';
import { loadOrganisationDetailData } from '../../store/action-creators';


const mapStateToProps = (state) => ({
  organisationDetailStatus: getOrganisationDetailStatus(state),
  organisationDetailData: getOrganisationDetailData(state)
});

const mapDispatchToProps = {
  loadOrganisationDetailData
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationDetail);