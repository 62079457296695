import { connect } from 'react-redux';

import Dashboard from './dashboard';
import { getDashboardStatus } from './store/selectors';
import { loadDashboard } from './store/action-creators';


const mapStateToProps = (state) => ({
  dashboardStatus: getDashboardStatus(state),
});

const mapDispatchToProps = {
  loadDashboard
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);