import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { get } from "lodash";
import { ProfileStatus } from "./store/constants";
import Loading from "../../components/Loading/Loading";
import(/* webpackChunkName: "profile" */ "./profile.scss");

const Profile = ({
  loadProfile,
  profileStatus,
  orderData,
  profileData,
  startEditProfile,
  startChangePassword,
  changePasswordStatus,
}) => {
  const [changePassword, setChangePassword] = useState(false);
  const [changePasswordStatusError, setChangePasswordStatusError] =
    useState("");
  const [editable, setEditable] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (loadProfile) {
      loadProfile();
    }
  }, [loadProfile]);

  useEffect(() => {
    if (profileData) {
      setFirstName(profileData.FirstName);
      setLastName(profileData.LastName);
      setEmail(profileData.EmailAddress);
    }
  }, [profileData]);

  useEffect(() => {
    //console.log("orderData", orderData);
  }, [orderData]);

  //Opens and closes inputs
  const submitChangePassword = () => {
    setChangePassword(!changePassword);
  };

  

  // Nested object that displays a row in table based on data from looping over orders
  const OrderTableRow = ({ order }) => {
    const orderDate = get(order, "OrderDate", "");
    const orderId = get(order, "OrderId", "");
    const orderNumber = get(order, "OrderNumber", "");
    const trackingStatus = get(order, "Status", "");
    const clientName = get(order, "ClientName", "");
    const clientFullName = get(order, "ClientFullName", "");
    const userId = get(order, "UserId", "");

    return (
      <tr>
        <td>
          <Moment format="llll">{orderDate}</Moment>
        </td>
        <td>
          {/* <Link to={`/profile/order-history/${orderNumber}`}> */}
          <Link to={`/profile/order-history/${orderId}`}>
            {/* {orderNumber} */}
            {orderId}
          </Link>
        </td>
        <td>{trackingStatus}</td>
        <td>{clientFullName ? clientFullName : clientName}</td>
        <td>{userId}</td>
      </tr>
    );
  };

  const submitPasswordChangeForm = (event) => {
    setPasswordErrorMessage("");
    setChangePasswordStatusError("");
    event.preventDefault();
    //console.log("submitPasswordChangeForm");
    var { oldPassword, newPassword, confirmPassword } = document.forms[1];
    if (oldPassword != "" || oldPassword != null) {
      if (newPassword.value == confirmPassword.value) {
        //Hide editor
        //Save Password
        startChangePassword(newPassword.value, oldPassword.value);
        submitChangePassword();
        //Show Success Message
      } else {
        //Show error message
        setPasswordErrorMessage("Passwords do not match. Please try again");
      }
    } else {
      setPasswordErrorMessage("Please enter your old password");
    }
  };

  useEffect(() => {
    if (changePasswordStatus) {
      setChangePasswordStatusError(changePasswordStatus);
      setChangePassword(true);
    }
  }, [changePasswordStatus]);

  const submitEditChangesForm = (event) => {
    event.preventDefault();
    var { firstName, lastName, email } = document.forms[0];

    const tempProfileData = {
      UserId: profileData.UserId,
      RoleName: profileData.RoleName,
      OrganisationCode: profileData.OrganisationCode,
      SiteId: null,
      FirstName: firstName.value,
      LastName: lastName.value,
      EmailAddress: email.value,
    };
    startEditProfile(tempProfileData);
    setEditable(false);
  };

  const cancelEditChangesForm = () => {
    setFirstName(profileData.FirstName);
    setLastName(profileData.LastName);
    setEmail(profileData.EmailAddress);
    setEditable(false);
  };

  if (profileStatus === ProfileStatus.LOADING) {
    return (
      <Col>
        <div className="">
          <Loading />
        </div>
      </Col>
    );
  }

  return (
    <div className="profile-title">
      {profileData && (
        <div>
          <div className="sign-in-info mb-5">
            <Form className="mb-0" onSubmit={submitEditChangesForm}>
              <Row>
                <Col md={6}>
                  <h1>Sign in info</h1>
                </Col>
                <Col md={6}>
                  {!editable ? (
                    <a
                      onClick={() => setEditable(true)}
                      className="btn btn-primary float-right"
                    >
                      Edit Profile
                    </a>
                  ) : (
                    <div>
                      <Button
                        onClick={cancelEditChangesForm}
                        className="float-right"
                        variant="danger mt-2 ml-2"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="float-right"
                        variant="success mt-2"
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </Col>
                <Col xs={12}>
                  <table>
                    <tr>
                      <th>First Name</th>
                      <td>
                        {!editable ? (
                          firstName
                        ) : (
                          <Form.Control
                            type="text"
                            name="firstName"
                            defaultValue={firstName}
                            onChange={(event) => {
                              setFirstName(event.target.value);
                            }}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Last Name</th>
                      <td>
                        {!editable ? (
                          lastName
                        ) : (
                          <Form.Control
                            type="text"
                            name="lastName"
                            defaultValue={lastName}
                            onChange={(event) => {
                              setLastName(event.target.value);
                            }}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>
                        {!editable ? (
                          email
                        ) : (
                          <Form.Control
                            type="email"
                            name="email"
                            defaultValue={email}
                            onChange={(event) => {
                              setEmail(event.target.value);
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  </table>
                </Col>
                {/* <Col md={4} xs={12}>
                  <p>First Name</p>
                </Col>
                

                <Col md={4} xs={12}>
                  <p>Last Name</p>
                </Col>
                {!editable ? (
                  <Col md={8} xs={12}>
                    <p>{lastName}</p>
                  </Col>
                ) : (
                  <Col md={8} xs={12}>
                    <Form.Control
                      type="text"
                      name="lastName"
                      defaultValue={lastName}
                      onChange={(event) => {
                        setLastName(event.target.value);
                      }}
                    />
                  </Col>
                )} */}

                {/* <Col md={4} xs={12}>
                  <p>Email</p>
                </Col>
                {!editable ? (
                  <Col md={8} xs={12}>
                    <p>{email}</p>
                  </Col>
                ) : (
                  <Col md={8} xs={12}>
                    <Form.Control
                      type="email"
                      name="email"
                      defaultValue={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </Col>
                )} */}
              </Row>
            </Form>
            <Row>
              <Col xs={12}>
                <Form onSubmit={submitPasswordChangeForm}>
                  <table>
                    <tr>
                      <th>Password</th>
                      <td>
                        <a
                          className={`${!changePassword ? "show" : "hide"}`}
                          onClick={submitChangePassword}
                        >
                          Change password
                        </a>
                      </td>
                    </tr>
                    <tr
                      className={`change-password ${changePassword ? "show" : "hide"
                        }`}
                    >
                      <th>Old Password</th>
                      <td>
                        <Form.Control
                          type="password"
                          name="oldPassword"
                          className="me-2"
                          aria-label="Stock"
                          required
                        />
                      </td>
                    </tr>
                    <tr
                      className={`change-password ${changePassword ? "show" : "hide"
                        }`}
                    >
                      <th>New Password</th>
                      <td>
                        <Form.Control
                          type="password"
                          name="newPassword"
                          className="me-2"
                          aria-label="Stock"
                          required
                        />
                      </td>
                    </tr>
                    <tr
                      className={`change-password ${changePassword ? "show" : "hide"
                        }`}
                    >
                      <th>Confirm Password</th>
                      <td>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          className="me-2"
                          aria-label="Stock"
                          required
                        />
                      </td>
                    </tr>
                    <tr
                      className={`change-password ${changePassword ? "show" : "hide"
                        }`}
                    >
                      <th></th>
                      <td>
                        <Button type="submit" variant="success mt-2">
                          Save
                        </Button>
                        <Button
                          onClick={submitChangePassword}
                          variant="danger mt-2 ml-2"
                        >
                          Cancel
                        </Button>
                      </td>
                    </tr>
                  </table>
                </Form>
              </Col>
              {/* <Col md={4} xs={12}>
                <p>Password</p>
                </Col>
                <Col md={8} xs={12}>
                <p>
                <a
                className={`${!changePassword ? "show" : "hide"}`}
                onClick={submitChangePassword}
                  >
                  Change password
                  </a>
                </p>

                <div
                  className={`change-password ${
                    changePassword ? "show" : "hide"
                  }`}
                >
                  <Form onSubmit={submitPasswordChangeForm}>
                    <Row>
                      <Col className="pb-4" xs={3}>
                        <Form.Label>Old Password</Form.Label>
                      </Col>
                      <Col xs={9}>
                        <Form.Control
                          type="password"
                          name="oldPassword"
                          className="me-2"
                          aria-label="Stock"
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pb-4" xs={3}>
                        <Form.Label>New Password</Form.Label>
                      </Col>
                      <Col xs={9}>
                        <Form.Control
                          type="password"
                          name="newPassword"
                          className="me-2"
                          aria-label="Stock"
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pb-4" xs={3}>
                        <Form.Label>Confirm Password</Form.Label>
                      </Col>
                      <Col xs={9}>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          className="me-2"
                          aria-label="Stock"
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3}></Col>
                      <Col xs={9}>
                        <div>
                          {passwordErrorMessage && (
                            <p>{passwordErrorMessage}</p>
                          )}
                          {changePasswordStatusError && (
                            <p>{changePasswordStatusError}</p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Button type="submit" variant="success mt-2">
                      Save
                    </Button>
                    <Button
                      onClick={submitChangePassword}
                      variant="danger mt-2 ml-2"
                    >
                      Cancel
                    </Button>
                  </Form>
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="permisions mb-5">
            <h2>Permissions</h2>
            <Row>
              <Col xs={12}>
                <table>
                  <tr>
                    <th>Permissions</th>
                    <td>{profileData.RoleName}</td>
                  </tr>
                  <tr>
                    <th>Organisation</th>
                    <td>{profileData.OrganisationCode}</td>
                  </tr>
                </table>
              </Col>
            </Row>
          </div>
          <div className="order-history">
            <h2>Order History</h2>
            {orderData && (
              <Row>
                <Col xs={12}>
                  <p>Total number of orders: {orderData.length}</p>
                </Col>
                <Col xs={12}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Order Date</th>
                        <th>Order Number</th>
                        <th>Order Status</th>
                        <th>Client Name</th>
                        <th>Account Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderData &&
                        orderData.map((order, index) => (
                          <OrderTableRow key={index} order={order} />
                        ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Profile.propTypes = {
  loadProfile: PropTypes.func,
  profileStatus: PropTypes.string,
  orderData: PropTypes.array,
  profileData: PropTypes.object,
  startEditProfile: PropTypes.func,
  startChangePassword: PropTypes.func,
  changePasswordStatus: PropTypes.func,
};

export default Profile;
