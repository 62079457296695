import React, { useEffect } from 'react';
import { Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import(/* webpackChunkName: "order-success" */'./orderSuccess.scss');

const orderSuccess = ({
  addOrderToCart,
  loadOrderConfirmation
}) => {
  useEffect(() => {
    if (addOrderToCart) {
      addOrderToCart();
      loadOrderConfirmation();
    }
  }, [addOrderToCart]);

  return (
    <Col xs={12}>
      <Row>
        <Col xs={4}></Col>
        <Col xs={4}>
          <div className="order-submit-success">
            <h2>Order successfully submitted</h2>
            <p>Thank you for your order, we will be in touch soon.</p>
            <p>
              <Link className="btn btn-primary" to="/">
                Back to home
              </Link>
            </p>
          </div>
        </Col>
        <Col xs={4}></Col>
      </Row>
    </Col>
  )
};

export default orderSuccess;
