import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Table } from "react-bootstrap";
import { OrderConfirmationStatus } from "./store/constants";
import Loading from "../../components/Loading/Loading";
import { Link } from "react-router-dom";
import { setErrorStatus } from "../organisations/store/action-creators";
import { sortBy } from 'lodash';
import { parse, isValid, format } from 'date-fns';
import(/* webpackChunkName: "orderConfirmation" */ "./orderConfirmation.scss");

const OrderConfirmation = ({
  submitOrder,
  orderConfirmationStatus,
  sentOrderConfirmationStatus,
  orderNumber,
  cartData,
  addOrderToCart,
  loadOrderConfirmation,
  resetOrderNumber,
  profileData,
}) => {
  const [orderShippingRate, setOrderShippingRate] = useState("standard");
  const [selectedSite, setSelectedSite] = useState(0);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [requiredByDate, setRequiredByDate] = useState("");
  const [siteError, setSiteError] = useState();
  const [siteList, setSiteList] = useState([]);
  const [isHAFSorderChecked, setIsHAFSorderChecked] = useState(false);
  let history = useHistory();

  const handleChange = (event) => {
    const myValue = event.target.value;
    setOrderShippingRate(myValue);
  };

  const handleHAFSorderChange = (event) => {
    setIsHAFSorderChecked(event.target.checked);
};

  const handleSiteChange = (event) => {
    const myValue = event.target.value;
    //console.log(myValue);
    setSelectedSite(myValue);
  };

  const startGoBack = () => {
    history.push({ pathname: "/cart" });
  };

  useEffect(() => {
    // //console.log("profileData - ", profileData.Sites.length);
    if (loadOrderConfirmation) {
      loadOrderConfirmation();
    }
  }, [loadOrderConfirmation]);

  useEffect(() => {
    if (profileData) {
      //console.log("profileData - ", profileData.Sites);
      let tempSiteList = sortBy(profileData.Sites, [function (site) { return site.SiteName; }]);
      setSiteList(tempSiteList);
    }
  }, [profileData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Gets values from the form and sets to local variables
    const {
      purchaseOrderNumber,
      specialInstructions,
      firstName,
      lastName,
      // dateOfBirth,
      // requiredByDate,
      HAFSorder,
      tamperProofRequired,
    } = document.forms[0];

    let tempSite = "";
    // console.log("siteList - ", siteList);
    if (siteList.length == 1) {
      tempSite = siteList[0].SiteCode;
    } else {
      setSiteError();
      const { site } = document.forms[0];
      if (site.value) {
        tempSite = siteList[site.value].SiteCode;
      } else {
        setSiteError("Please select a site");
        return; 
      }
    }

    let tempCartData = [];
    cartData.map((item) => {
      item.ConfigurationGroup = JSON.stringify(item.ConfigurationGroup);
      item.CustomOptions = JSON.stringify(item.CustomOptions);
      item.Audiogram = JSON.stringify(item.Audiogram);
      tempCartData.push(item);
    });

    // Send order to API ./store/saga
    submitOrder(
      tempCartData,
      orderShippingRate,
      purchaseOrderNumber.value,
      specialInstructions.value,
      profileData.OrganisationCode,
      tempSite,
      firstName.value,
      lastName.value,
      // dateOfBirth.value,
      // requiredByDate.value,
      dateOfBirth,
      requiredByDate,
      HAFSorder.checked,
      tamperProofRequired.checked
    );
  };

  const goToSuccessScreen = () => {
    // console.log("order number in goToSuccessScreen 1- ", orderNumber);
    // console.log("sentOrderConfirmationStatus in goToSuccessScreen 1- ", sentOrderConfirmationStatus);
    if (sentOrderConfirmationStatus === OrderConfirmationStatus.SUCCESS && orderNumber) {
      // console.log("order number in goToSuccessScreen 2- ", orderNumber);
      // console.log("sentOrderConfirmationStatus in goToSuccessScreen 2- ", sentOrderConfirmationStatus);

      let goToOrderDetailScreen = false;

      cartData.forEach((itemInCart) => {
        if (itemInCart.EarImpression == "Mail in an impression") {
          goToOrderDetailScreen = true;
        }
      })
      //Clear cart
      addOrderToCart();
      if (goToOrderDetailScreen && orderNumber) {
        let url = `/profile/order-history/${orderNumber}`;
        resetOrderNumber();
        loadOrderConfirmation();
        history.push({ pathname: `${url}` });
      }
      else {
        history.push({ pathname: "/order-confirmation/success" });
      }
    }
  }


  const parseDate = (input) => {
    // Try different date formats here based on your requirements
    const formats = ['yyyy-MM-dd', 'yyyyMMdd', 'yyyy/MM/dd', 'yyyy.MM.dd', 'MM/dd/yyyy', 'MMddyyyy', 'MM-dd-yyyy', 'MM.dd.yyyy', 'dd/MM/yyyy', 'ddMMyyyy', 'dd-MM-yyyy', 'dd.MM.yyyy', 'yyyy/MM/dd', 'yyyy-MM-dd', 'yyyy.MM.dd', 'dd/MM/yyyy', 'dd-MM-yyyy', 'dd.MM.yyyy', 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX', 'yyyy-MM-dd\'T\'HH:mm:ss', 'yyyy-MM-dd HH:mm:ss', 'MMMM d, yyyy', 'd MMMM yyyy',];
    for (let format of formats) {
      const parsed = parse(input, format, new Date());
      if (isValid(parsed)) {
        return parsed;
      }
    }
    return null;
  };
  const handleDateOfBirthPaste = (e) => {
		const pastedText = e.clipboardData.getData('text');
		//console.log(pastedText);
		const parsedDate = parseDate(pastedText);
		if (parsedDate) {
			e.preventDefault();
			setDateOfBirth(format(parsedDate, 'yyyy-MM-dd'));
		}
	};
  const handleRequiredByDatePaste = (e) => {
		const pastedText = e.clipboardData.getData('text');
		//console.log(pastedText);
		const parsedDate = parseDate(pastedText);
		if (parsedDate) {
			e.preventDefault();
			setRequiredByDate(format(parsedDate, 'yyyy-MM-dd'));
		}
	};

  //If API returns a success show a success screen
  useEffect(() => {
    goToSuccessScreen();
  }, [sentOrderConfirmationStatus, orderNumber]);

  if (sentOrderConfirmationStatus === OrderConfirmationStatus.LOADING) {
    return (
      <Col>
        <div className="">
          <Loading />
        </div>
      </Col>
    );
  }

  return (
    <div className="order-confirmation-main-div">
      <div className="order-confirmation-title">
        <h1>Checkout</h1>
        <p>
          Please check the following information is correct and fill out the
          available fields if applicable.
        </p>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} className="order-confirmation-sub-heading">
            <h2>Processing Information</h2>
          </Col>
          <Col xs={5}>
            <table className="table-full-width">
              {profileData && profileData.FirstName && profileData.LastName && (
                <tr>
                  <th>Name</th>
                  <td>
                    {profileData.FirstName} {profileData.LastName}
                  </td>
                </tr>
              )}
              {profileData && profileData.OrganisationCode && (
                <tr>
                  <th>Organisation</th>
                  <td>{profileData.OrganisationName}</td>
                </tr>
              )}
              {siteList && siteList.length == 1 && (
                <tr>
                  <th>Site</th>
                  <td>{siteList[0] && siteList[0].SiteName}</td>
                </tr>
              )}
              <tr>
                <th>First Name *</th>
                <td>
                  <Form.Control
                    type="text"
                    name="firstName"
                    className="me-2"
                    aria-label="firstName"
                    required
                  />
                </td>
              </tr>
              <tr>
                <th>Last Name *</th>
                <td>
                  <Form.Control
                    type="text"
                    name="lastName"
                    className="me-2"
                    aria-label="lastName"
                    required
                  />
                </td>
              </tr>
              <tr>
                <th>DOB</th>
                <td>
                  <Form.Control
                    type="date"
                    name="dateOfBirth"
                    className="me-2"
                    aria-label="dateOfBirth"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    onPaste={handleDateOfBirthPaste}
                  />
                </td>
              </tr>
              <tr>
                <th>Required By </th>
                <td>
                  <Form.Control
                    type="date"
                    name="requiredByDate"
                    className="me-2"
                    aria-label="requiredByDate"
                    value={requiredByDate}
                    onChange={(e) => setRequiredByDate(e.target.value)}
                    onPaste={handleRequiredByDatePaste}
                  />
                </td>
              </tr>
              <tr>
                <th>Purchase Order #</th>
                <td>
                  <Form.Control
                    type="text"
                    name="purchaseOrderNumber"
                    className="me-2"
                    aria-label="purchaseOrderNumber"
                    required={isHAFSorderChecked}
                  />
                </td>
              </tr>
              <tr>
                <th>HAFS <br /><span className="hafs-title">{"("}Hearing Aid Funding Scheme{")"}</span></th>
                <td>
                  <Form.Control
                    type="checkbox"
                    name="HAFSorder"
                    className="me-2 hafs-checkbox"
                    aria-label="HAFSorder"
                    onChange={handleHAFSorderChange}
                  />
                </td>
              </tr>
              <tr>
                <th>Tamper Proof Required</th>
                <td>
                  <Form.Control
                    type="checkbox"
                    name="tamperProofRequired"
                    className="me-2 hafs-checkbox"
                    aria-label="tamperProofRequired"
                  />
                </td>
              </tr>
            </table>
            {/* <p>{AccountId} / {AccountName}</p> */}
          </Col>

          <Col xs={12}>
            {siteList && siteList.length > 1 && (
              <table className="shipping-options-top-input">
                <tr>
                  <th>SITE:</th>
                  {siteList.map((site, index) => {
                    return (
                      <td>
                        <input
                          onChange={handleSiteChange}
                          id={`site-${index}`}
                          type="radio"
                          value={index}
                          checked={selectedSite == index}
                          name="site"
                        />
                        {site.SiteName}
                      </td>
                    );
                  })}
                </tr>
              </table>
            )}
            {siteError && (
              <div className="alert alert-danger">
                <p>{siteError}</p>
              </div>
            )}
          </Col>
        </Row>
        {/*
        <Row>
          <Col xs={12} className="order-confirmation-sub-heading">
            <h2>Shipping Information</h2>
          </Col>
          <Col md={8} xs={12} className="shipping-options-div">
            <Row>
              <Col xs={3}>
                <th className="pt-0">PURCHASE OPTIONS: </th>
              </Col>
              <Col xs={9}>
                <div className="shipping-options-top-input">
                  <input
                    onChange={handleChange}
                    id="shipping-rate-1"
                    type="radio"
                    value={"rush"}
                    name="shipping-rate"
                  />
                  Rush order 24h - with charge
                </div>
                <div>
                  <input
                    defaultChecked={true}
                    onChange={handleChange}
                    id="shipping-rate-2"
                    type="radio"
                    value={"standard"}
                    name="shipping-rate"
                  />
                  Standard order
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
           */}
        <Row className="order-summary-title-row pb-0">
          <Col xs={12} className="order-confirmation-sub-heading">
            <h2>Items</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Product Code</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {cartData &&
                  cartData.map((productInCart, index) => {
                    return (
                      <tr key={index}>
                        <th>
                          {productInCart.Name} {productInCart.Side}
                        </th>
                        <td>
                          {productInCart.Code &&
                            `Material #: ${productInCart.Code}`}
                        </td>
                        <td>
                          {productInCart.Quantity
                            ? `${productInCart.Quantity}`
                            : "1"}
                        </td>
                        {/* <Col xs={12}>
                     <Row className="pb-0">
                      <Col xs={3}>
                        <div className="product-in-cart-content">
                          
                        </div>
                      </Col>
                      <Col md={6}>
                        <div>
                          <td></td>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <td className="float-right">
                          
                        </td>
                      </Col>
                    </Row>
                  </Col> */}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="order-summary-title-row">
          <Col
            xs={12}
            className="order-confirmation-sub-heading special-instructions-title"
          >
            <h2>Special Instructions for Order</h2>
          </Col>
          <Col xs={12} className="special-instructions-div">
            <textarea
              id="specialInstructions"
              name="specialInstructions"
              rows="8"
            ></textarea>
          </Col>
          {/* <Form.Control
              type="message"
              name="specialInstructions"
              className="me-2"
              aria-label="specialInstructions"
            /> */}
        </Row>
        <Row>
          {sentOrderConfirmationStatus === OrderConfirmationStatus.ERROR && (
            <Col xs={12}>
              <div className="alert alert-danger">
                <p>
                  An error occured with submitting your order. Please try again
                </p>
              </div>
            </Col>
          )}
          <Col xs={2}>
            <Button onClick={startGoBack}>Back</Button>
          </Col>
          <Col xs={2} className="offset-8">
            <Button type="submit" className="btn btn-primary float-right">
              Submit Order
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

OrderConfirmation.propTypes = {
  submitOrder: PropTypes.func,
  orderConfirmationStatus: PropTypes.string,
  sentOrderConfirmationStatus: PropTypes.string,
  orderNumber: PropTypes.string,
  cartData: PropTypes.array,
  addOrderToCart: PropTypes.func,
  loadOrderConfirmation: PropTypes.func,
  resetOrderNumber: PropTypes.func,
  profileData: PropTypes.object,
};

export default OrderConfirmation;
