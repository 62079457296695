import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import { ChangePasswordStatus } from './store/constants';
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import Loading from '../../components/Loading/Loading';
import { get } from 'lodash';

import(/* webpackChunkName: "changePassword" */'./changePassword.scss');

const ChangePassword = ({
  changePasswordStatus,
  changePassword
}) => {
  const history = useHistory();
  let { userId, organisationCode, siteCode } = useParams();

  const [userData, setUserData] = useState({
    userId: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [isMatching, setIsMatching] = useState(true);

  useEffect(() => {
    if (userId) {
      setUserData({
        ...userData,
        userId: userId
      });
    }
  }, [userId]);

  function handleSubmit(e) {
    e.preventDefault();
    //console.log(userData);

    if (userData.newPassword != userData.confirmPassword) {
      setIsMatching(false);
    } else {
      setIsMatching(true);
      changePassword(userData);

      history.push(organisationCode ? `/admin/organisation/${organisationCode}/users/${userId}/detail` : 
      siteCode ? `/admin/site/${siteCode}/users/${userId}/detail` :
      `/admin/adminusers/${userId}/detail`);
    }
    // changePassword();
    // history.push('/admin/organisation')
  }

  function handleChange(e) {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value 
    });
  
  }

  if (changePasswordStatus === ChangePasswordStatus.LOADING) {
    return (
      <Col>
        <div>
          <Loading />
        </div>
      </Col>
    )
  }

  return (
    <div>
      <h1 className='mt-5'>{`Change Password`}</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Row>
                <Col className="pb-4" xs={3}>
                  <Form.Label>New Password</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="password"
                    name="newPassword"
                    // defaultValue={""}
                    value={userData.newPassword}
                    className="me-2"
                    aria-label="newPassword"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col className="pb-4" xs={3}>
                  <Form.Label>Confirm Password</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    // defaultValue={""}
                    value={userData.confirmPassword}
                    className="me-2"
                    aria-label="confirmPassword"
                    onChange={handleChange}
                    required
                  />
                  {
                    !isMatching &&
                    <p className='mt-3 text-danger'>Confirm Password must match New Password.</p>
                  }
                </Col>

              </Row>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <div className="float-right">
              <Button type="submit" 
                variant="success mt-2"
              >
                Save
              </Button>
              <Link to={organisationCode ? `/admin/organisation/${organisationCode}/users/${userId}/detail` : 
                siteCode ? `/admin/site/${siteCode}/users/${userId}/detail` :
                `/admin/adminusers/${userId}/detail`}>
                <Button variant="warning mt-2 ml-2">
                  Cancel
                </Button>
              </Link>
            </div>
          </Col>
          
        </Row>
        
      </Form>
      
    </div>
  )
};

ChangePassword.propTypes = {
  changePasswordStatus: PropTypes.string,
  changePassword: PropTypes.func
};

export default ChangePassword;
