import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { OrganisationDetailStatus } from '../../store/constants';
import Loading from '../../../../components/Loading/Loading';
import { get } from 'lodash';
import { Link, Redirect, useParams, useHistory } from 'react-router-dom';
import(/* webpackChunkName: "organisationDetail" */'./organisationDetail.scss');

const OrganisationDetail = ({
    organisationDetailStatus,
    organisationDetailData,
    loadOrganisationDetailData
  }) => {
    const { organisationCode } = useParams();
  
    useEffect(() => {
      if (loadOrganisationDetailData) {
        loadOrganisationDetailData(organisationCode);
      }
    }, [loadOrganisationDetailData]);

    const history = useHistory();

    function handleClick(path) {
      history.push(path);
    }


    const OrganisationUserTableRow = ({ user }) => {
      const firstName = get(user, "FirstName", "default");
      const lastName = get(user, "LastName", "default");
      const emailAddress = get(user, "EmailAddress", "default");
      const roleName = get(user, "RoleName", "default");
      const siteCode = get(user, "SiteCode", "default");
      const siteName = get(user, "SiteName")
      const userId = get(user, 'UserId', 'default');

      return (
        <tr>
        <td><Link to={`/admin/organisation/${organisationCode}/users/${userId}/detail`}>{`${firstName} ${lastName}`}</Link></td>
        <td>{roleName}</td>
        <td>{emailAddress}</td>
        {/* <td><Link to={`/admin/site/${siteCode}/detail`}>{siteName}</Link></td> */}
      </tr>
      );
    };

    const OrganisationSiteTableRow = ({ site }) => {
      const siteCode = get(site, "SiteCode", "default");
      const siteName = get(site, "SiteName", "default");

      return (
        <tr>
          {/* <td>{siteCode}</td> */}
          <td><Link to={`/admin/site/${siteCode}/detail`} >{siteName}</Link></td>
        </tr>
      );
    };

    if (organisationDetailStatus === OrganisationDetailStatus.LOADING) {
      return (
        <Col>
          <div>
            <Loading />
          </div>
        </Col>
      )
    }

    if (organisationDetailStatus === OrganisationDetailStatus.ERROR) {
      return (
        <Redirect to='/' />
      );
    }

    return (
      
      <div>
        {/* Dashboard: <span>{organisationDetailStatus}</span> */}
        {
          organisationDetailData && 
          <>
            <Row className="mt-5">
              <Col xs={12} className='mb-3'>
                  <Link to= {`/admin/organisation`}
                  >
                    Back to Organisations
                  </Link>
                
              </Col>
              <Col xs={12}>
                <h1 style={{display: 'inline-block', 'marginRight': '1rem'}}>{organisationDetailData['OrganisationName']}</h1>
                {
                  organisationDetailData['CanEdit'] &&
                  <Button
                    onClick={() => handleClick(`/admin/organisation/${organisationDetailData['OrganisationCode']}/edit`)}
                  >
                    Edit
                  </Button>
                }
              </Col>
              

              {/* <Col xs={3}>Catalogue</Col>
              <Col xs={9}>{organisationDetailData['PhonakCatalogueName'] ?? 'Catalogue not set'}</Col> */}
              {/* <Col xs={3}>Unitron Catalogue</Col>
              <Col xs={9}>{organisationDetailData['UnitronCatalogueCode']}</Col> */}
            </Row>
            <Row>
              <Col xs={12}>
                <table className="userDetailTable mt-3">
                  <tbody>
                    <tr>
                      <td>Catalogue</td>
                      <td>{organisationDetailData['PhonakCatalogueName'] ?? 'Catalogue not set'}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>    
            <Row className="mt-3">
              <Col xs={12}>
                <h2 style={{display: 'inline-block', 'marginRight': '1rem'}}>Users</h2>
                { 
                  organisationDetailData['CanEdit'] &&
                  <Button
                    onClick={() => handleClick(`/admin/organisation/${organisationDetailData['OrganisationCode']}/users/add`)}
                  >
                  Add
                  </Button>
                }
              </Col>
              <Col xs={12} className="mt-2">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Email</th>
                      {/* <th>Site</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      organisationDetailData && 
                      organisationDetailData["Users"].map((user, index) => (
                        <OrganisationUserTableRow key={index} user={user} />
                      ))
                    }
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col xs={12}>

                <h2 style={{display: 'inline-block', 'marginRight': '1rem'}}>Sites</h2>
                {
                  organisationDetailData['CanEdit'] &&
                  <Button
                    onClick={() => handleClick(`/admin/organisation/${organisationDetailData['OrganisationCode']}/sites/add`)}
                    // onClick={() => handleClick(`/admin/site/add`)}
                  >
                    Add
                  </Button>
                }
              </Col>
              <Col xs={12} className="mt-2">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>Site Code</th> */}
                      <th>Site Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      organisationDetailData && 
                      organisationDetailData["Sites"].map((site, index) => (
                        <OrganisationSiteTableRow key={index} site={site} />
                      ))
                    }
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        }
        
      </div>
      
    )
};

OrganisationDetail.propTypes = {
  loadOrganisationDetailData: PropTypes.func,
  organisationDetailStatus: PropTypes.string,
  organisationDetailData: PropTypes.object
};

export default OrganisationDetail;
