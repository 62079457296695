import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { OrganisationListStatus, OrganisationUserListStatus, OrgUserDetailStatus, AddOrgUserStatus, OrganisationDetailStatus } from './constants';

// List related
const organisationListStatus = createReducer({
  [actions.SET_ORGANISATIONLIST_STATUS]: (state, { payload }) => payload
}, OrganisationListStatus.IDLE);

const organisationListData = createReducer({
  [actions.SET_ORGANISATIONLIST_DATA]: (state, { payload }) => payload
}, OrganisationListStatus.IDLE);

const organisationUserListStatus = createReducer({
  [actions.SET_ORGANISATIONUSERLIST_STATUS]: (state, { payload }) => payload
}, OrganisationUserListStatus.IDLE);

const organisationUserListData = createReducer({
  [actions.SET_ORGANISATIONUSERLIST_DATA]: (state, { payload }) => payload
}, OrganisationUserListStatus.IDLE);

// User detail related
const orgUserDetailStatus = createReducer({
  [actions.SET_ORGUSER_STATUS]: (state, { payload }) => payload
}, OrgUserDetailStatus.IDLE);

const orgUserDetailData = createReducer({
  [actions.SET_ORGUSER_DATA]: (state, { payload }) => payload
}, OrgUserDetailStatus.IDLE);

const saveOrgUserStatus = createReducer({
  [actions.SET_SAVE_ORGUSER_STATUS]: (state, { payload }) => payload
}, AddOrgUserStatus.IDLE);

const errorStatus = createReducer({
  [actions.SET_ERROR_STATUS]: (state, { payload }) => payload
}, AddOrgUserStatus.IDLE);

const organisationDetailStatus = createReducer({
  [actions.SET_ORGANISATIONDETAIL_STATUS]: (state, { payload }) => payload
}, OrganisationDetailStatus.IDLE);

const organisationDetailData = createReducer({
  [actions.SET_ORGANISATIONDETAIL_DATA]: (state, { payload }) => payload
}, OrganisationDetailStatus.IDLE);

export default combineReducers({
  organisationListStatus,
  organisationListData,
  organisationUserListStatus,
  organisationUserListData,
  orgUserDetailStatus,
  orgUserDetailData,
  saveOrgUserStatus,
  organisationDetailData,
  organisationDetailStatus,
  errorStatus
});
