import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { OrderConfirmationStatus } from './constants';

const orderConfirmationStatus = createReducer({
  [actions.SET_ORDER_CONFIRMATION_STATUS]: (state, { payload }) => payload
}, OrderConfirmationStatus.IDLE);

const sentOrderConfirmationStatus = createReducer({
  [actions.SET_SENT_ORDER_CONFIRMATION_STATUS]: (state, { payload }) => payload
}, OrderConfirmationStatus.IDLE);

const orderNumber = createReducer({
  [actions.SET_ORDER_NUMBER]: (state, { payload }) => payload
}, OrderConfirmationStatus.IDLE);

export default combineReducers({
  orderConfirmationStatus,
  sentOrderConfirmationStatus,
  orderNumber
});
