import { connect } from 'react-redux';
import OrganisationList from './organisationList';
import { getOrganisationListStatus, getOrganisationListData } from '../../store/selectors';
import { getProfileData } from '../../../login/store/selectors';
import { loadOrganisationListData } from '../../store/action-creators';


const mapStateToProps = (state) => ({
  organisationListStatus: getOrganisationListStatus(state),
  organisationListData: getOrganisationListData(state),
  profileData: getProfileData(state)
});

const mapDispatchToProps = {
  loadOrganisationListData
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationList);