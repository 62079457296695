
export const LOAD_ORGANISATION_DATA = 'addEditOrganisation/LOAD_ORGANISATION_DATA';
export const SET_ORGANISATION_STATUS = 'addEditOrganisation/SET_ORGANISATION_STATUS';
export const SET_ORGANISATION_DATA = 'addEditOrganisation/SET_ORGANISATION_DATA';

export const SAVE_ORGANISATION_DATA = 'addEditOrganisation/SAVE_ORGANISATION_DATA';
export const SET_SAVE_ORGANISATION_STATUS = 'addEditOrganisation/SET_SAVE_ORGANISATION_STATUS';

export const LOAD_CATALOGUELIST_DATA = 'addEditOrganisation/LOAD_CATALOGUELIST_DATA';
export const SET_CATALOGUELIST_STATUS = 'addEditOrganisation/SET_CATALOGUELIST_STATUS';
export const SET_CATALOGUELIST_DATA = 'addEditOrganisation/SET_CATALOGUELIST_DATA';
