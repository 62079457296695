import { create } from 'lodash';
import { createSelector } from 'reselect';

// State is the application wide state
const organisations = state => state.modules.organisations; // this is from what you named the reducer in the global reducers.js

// organisation list related
export const getOrganisationListStatus = createSelector(
  organisations,
  state => state.organisationListStatus
);

export const getOrganisationListData = createSelector(
  organisations,
  state => state.organisationListData
);

// organisation detail related
export const getOrganisationDetailStatus = createSelector(
  organisations,
  state => state.organisationDetailStatus
);

export const getOrganisationDetailData = createSelector(
  organisations,
  state => state.organisationDetailData
);

// user list related
export const getOrganisationUserListStatus = createSelector(
  organisations,
  state => state.organisationUserListStatus
);

export const getOrganisationUserListData = createSelector(
  organisations,
  state => state.organisationUserListData
);

// user detail related
export const getOrgUserDetailData = createSelector(
  organisations,
  state => state.orgUserDetailData
);

export const getOrgUserDetailStatus = createSelector(
  organisations,
  state => state.orgUserDetailStatus
);

export const getSaveOrgUserStatus = createSelector(
  organisations,
  state => state.saveOrgUserStatus
);

export const getErrorStatus = createSelector(
  organisations,
  state => state.errorStatus
);
