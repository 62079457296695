export const LOAD_ORGANISATIONLIST_DATA = 'organisation/LOAD_ORGANISATIONLIST_DATA';
export const SET_ORGANISATIONLIST_STATUS = 'organisation/SET_ORGANISATIONLIST_STATUS';
export const SET_ORGANISATIONLIST_DATA = 'organisation/SET_ORGANISATIONLIST_DATA';

export const LOAD_ORGANISATIONUSERLIST_DATA = 'organisation/LOAD_ORGANISATIONUSERLIST_DATA';
export const SET_ORGANISATIONUSERLIST_STATUS = 'organisation/SET_ORGANISATIONUSERLIST_STATUS';
export const SET_ORGANISATIONUSERLIST_DATA = 'organisation/SET_ORGANISATIONUSERLIST_DATA';

export const LOAD_ORGUSER_DATA = 'organisation/LOAD_ORGUSER_DATA';
export const SET_ORGUSER_STATUS = 'organisation/SET_ORGUSER_STATUS';
export const SET_ORGUSER_DATA = 'organisation/SET_ORGUSER_DATA';

export const SAVE_ORGUSER_DATA = 'organisation/SAVE_ORGUSER_DATA';
export const SET_SAVE_ORGUSER_STATUS = 'organisation/SET_SAVE_ORGUSER_STATUS';
export const SET_ERROR_STATUS = 'organisation/SET_ERROR_STATUS';

export const LOAD_ORGANISATIONDETAIL_DATA = 'organisation/LOAD_ORGANISATIONDETAIL_DATA';
export const SET_ORGANISATIONDETAIL_STATUS = 'organisation/SET_ORGANISATIONDETAIL_STATUS';
export const SET_ORGANISATIONDETAIL_DATA = 'organisation/SET_ORGANISATIONDETAIL_DATA';

export const RESET_SAVEORGUSER_STATUS = 'organisation/RESET_SAVEORGUSER_STATUS';

