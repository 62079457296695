import { connect } from 'react-redux';

import ProductSummary from './productSummary';
import { getCartData, getOrderBuilderData } from '../orderBuilder/store/selectors';
import { addOrderToCart } from '../orderBuilder/store/action-creators';

const mapStateToProps = (state) => ({
  cartData: getCartData(state),
  orderBuilderData: getOrderBuilderData(state),
});

const mapDispatchToProps = {
  addOrderToCart
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSummary);