import { connect } from 'react-redux';

import ForgotPassword from './forgotPassword';
import { getForgotPasswordStatus } from './store/selectors';
import { startForgotPassword } from './store/action-creators';


const mapStateToProps = (state) => ({
  forgotPasswordStatus: getForgotPasswordStatus(state),
});

const mapDispatchToProps = {
  startForgotPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);