import { connect } from 'react-redux';

import OrderConfirmation from './orderConfirmation';
import { getOrderConfirmationStatus, getSentOrderConfirmationStatus, getOrderNumber } from './store/selectors';
import { getCartData } from '../orderBuilder/store/selectors';
import { getProfileData } from '../login/store/selectors';
import { addOrderToCart } from '../orderBuilder/store/action-creators';
import { submitOrder, loadOrderConfirmation, resetOrderNumber } from './store/action-creators';


const mapStateToProps = (state) => ({
  orderConfirmationStatus: getOrderConfirmationStatus(state),
  sentOrderConfirmationStatus: getSentOrderConfirmationStatus(state),
  orderNumber: getOrderNumber(state),
  cartData: getCartData(state),
  profileData: getProfileData(state),
});

const mapDispatchToProps = {
  submitOrder,
  addOrderToCart,
  loadOrderConfirmation,
  resetOrderNumber
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);