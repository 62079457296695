import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Loading from "../../components/Loading/Loading";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { find, uniqBy, map, filter, get } from "lodash";
import { ProductSummaryStatus } from "./store/constants";
import { Row, Col, Button, Form } from "react-bootstrap";
import testModel from "../../assets/img/ModelTest.png";
import { CategoryContstants } from "../../utilities/categoryConstants";
import(/* webpackChunkName: "productSummary" */ "./productSummary.scss");

const ProductSummary = ({
  loadProductSummary,
  productSummaryStatus,
  productSummaryData,
  addOrderToCart,
  cartData,
  orderBuilderData,
}) => {
  let history = useHistory();
  const location = useLocation();
  const { productCode } = useParams();
  const [productAddedToCart, setProductAddedToCart] = useState();
  const [leftItemInCart, setLeftItemInCart] = useState();
  const [rightItemInCart, setRightItemInCart] = useState();
  const [leftCustomOptions, setLeftCustomOptions] = useState();
  const [rightCustomOptions, setRightCustomOptions] = useState();
  const [leftCustomOptionsArray, setLeftCustomOptionsArray] = useState([]);
  const [rightCustomOptionsArray, setRightCustomOptionsArray] = useState([]);
  const [uniqueProductCategories, setUniqueProductCategories] = useState();
  const [activeRelatedProductCategory, setActiveRelatedProductCategory] =
    useState();
  const [productSummaryDataByCategory, setProductSummaryDataByCategory] =
    useState();
  const [productsForCart, setProductsForCart] = useState();
  const [specialInstructions, setSpecialInstructions] = useState("");

  const [displayModelNameLeft, setDisplayModelNameLeft] = useState("");
  const [displayModelNameRight, setDisplayModelNameRight] = useState("");




  useEffect(() => {
    // console.log(
    //   "location.state.productsForCart",
    //   location.state.productsForCart
    // );
    if (location.state.productsForCart) {
      setProductsForCart(location.state.productsForCart);
    }
  }, [location]);

  useEffect(() => {
    if (productsForCart) {
      let uniqueModelTypes = get(orderBuilderData, "Models", []);
      let uniqueEarpieces = get(orderBuilderData, "Earpieces", []);
      //console.log("orderBuilderData", orderBuilderData);




      //Item code for ITE
      const productForCartLeft = [...productsForCart];
      //console.log("productForCartLeft", productForCartLeft);

      let leftProduct = find(
        productForCartLeft,
        { Side: "L", ItemCode: productCode },
        null
      );
      if (!leftProduct) {
        //Code for earpieces
        leftProduct = find(
          productForCartLeft,
          { Side: "L", Code: productCode },
          null
        );
      }
      if (!leftProduct) {
        //Code for earpieces
        leftProduct = find(
          productForCartLeft,
          { Side: "L" },
          null
        );
      }
      if (leftProduct && leftProduct.ModelTypeCode) {
        let modelTypeObject = find(uniqueModelTypes, { "Code": leftProduct.ModelTypeCode });
        if (modelTypeObject) {
          let modelName = modelTypeObject.Name ? modelTypeObject.Name : leftProduct.ModelTypeCode;
          setDisplayModelNameLeft(modelName);
        }
        else {
          let earpieceTypeObject = find(uniqueEarpieces, { "ModelTypeCode": leftProduct.ModelTypeCode });
          if (earpieceTypeObject) {
            let modelName = earpieceTypeObject.Name ? earpieceTypeObject.Name : leftProduct.ModelTypeCode;
            setDisplayModelNameLeft(modelName);
          }
        }
      }
      setLeftItemInCart(leftProduct);
      //console.log("leftProduct", leftProduct);

      const customOptionsLeft = get(leftProduct, "CustomOptions", {});
      setLeftCustomOptions(customOptionsLeft);

      const productForCartRight = [...productsForCart];
      //console.log("productForCartRight", productForCartRight);
      //Item code for ITE
      let rightProduct = find(
        productForCartRight,
        { Side: "R", ItemCode: productCode },
        null
      );
      if (!rightProduct) {
        //Code for earpieces
        rightProduct = find(
          productForCartRight,
          { Side: "R", Code: productCode },
          null
        );
      }
      if (!rightProduct) {
        //Code for earpieces
        rightProduct = find(
          productForCartRight,
          { Side: "R" },
          null
        );
      }
      if (rightProduct && rightProduct.ModelTypeCode) {
        let modelTypeObject = find(uniqueModelTypes, { "Code": rightProduct.ModelTypeCode });
        if (modelTypeObject) {
          let modelName = modelTypeObject.Name ? modelTypeObject.Name : rightProduct.ModelTypeCode;
          setDisplayModelNameRight(modelName);
        }
        else {
          let earpieceTypeObject = find(uniqueEarpieces, { "ModelTypeCode": rightProduct.ModelTypeCode });
          if (earpieceTypeObject) {
            let modelName = earpieceTypeObject.Name ? earpieceTypeObject.Name : rightProduct.ModelTypeCode;
            setDisplayModelNameRight(modelName);
          }
        }
      }
      //console.log("rightProduct", rightProduct);

      setRightItemInCart(rightProduct);
      const customOptionsRight = get(rightProduct, "CustomOptions", {});
      setRightCustomOptions(customOptionsRight);
    }
  }, [productsForCart]);

  useEffect(() => {
    //Split out custom options object into an array for looping over later
    if (rightCustomOptions) {
      const tempArray = Object.keys(rightCustomOptions);
      setRightCustomOptionsArray(tempArray);
    }
    if (leftCustomOptions) {
      const tempArray = Object.keys(leftCustomOptions);
      setLeftCustomOptionsArray(tempArray);
    }
  }, [rightCustomOptions, leftCustomOptions]);

  // On submit add special instructions to items
  const handleSubmit = (event) => {
    event.preventDefault();
    const { specialInstructions } = document.forms[0];

    let currentCart = [];
    currentCart = [...cartData];
    //Either adding order to cart if it's not already in the cart.
    //Or increasing quantity by 1 if the same item is already in the cart.

    currentCart = cartData.map((obj) => {
      if (obj && obj.Code === productCode) {
        obj.specialInstructions = specialInstructions.value;
        return obj;
      }

      return obj;
    });

    addOrderToCart(currentCart);

    if (
      (rightItemInCart && rightItemInCart.CategoryCode === "Earpieces") ||
      (leftItemInCart && leftItemInCart.CategoryCode === "Earpieces")
    ) {
      history.push({
        pathname: `/cart/`,
      });
    }

    if (rightItemInCart) {
      history.push({
        pathname: `/related-products/${rightItemInCart.ItemCode}`,
        state: { productsAddedToCart: productsForCart },
      });
    }
    if (leftItemInCart) {
      history.push({
        pathname: `/related-products/${leftItemInCart.ItemCode}`,
        state: { productsAddedToCart: productsForCart },
      });
    }
  };

  useEffect(() => {
    //console.log(
    //   "rightItemInCart, leftItemInCart",
    //   rightItemInCart,
    //   leftItemInCart
    // );
  }, [rightItemInCart, leftItemInCart]);

  return (
    <div className="product-summary-main-div">
      {(rightItemInCart || leftItemInCart) && (
        <Form onSubmit={handleSubmit}>
          <div>
            <Row>
              <Col xs={12}>
                <h1>
                  Product Summary
                  <Button type="submit" className="go-to-cart-button">
                    Add to Cart
                  </Button>
                </h1>
              </Col>
            </Row>
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Right Side</th>
                  <th>Left Side</th>
                </tr>
              </thead>
              <tbody>

              {(rightItemInCart && (rightItemInCart.ModelTypeCode || rightItemInCart.ModelTypeName) ||
                (leftItemInCart && (leftItemInCart.ModelTypeCode || leftItemInCart.ModelTypeName))) && (
              
				          <tr>
                    <td>Instrument Model</td>
                    <td>
                      {" "}
                      {displayModelNameRight ? displayModelNameRight : (rightItemInCart
                        ? (rightItemInCart.ModelTypeName ? 
                          rightItemInCart.ModelTypeName : (rightItemInCart.ModelTypeCode ? rightItemInCart.ModelTypeCode : "")) : "")}
                    </td>
                    <td>
                      {displayModelNameLeft ? displayModelNameLeft : (leftItemInCart
                        ? (leftItemInCart.ModelTypeName ?
                          leftItemInCart.ModelTypeName : (leftItemInCart.ModelTypeCode ? leftItemInCart.ModelTypeCode : "")) : "")}
                    </td>
                  </tr>
              )}
              {((rightItemInCart &&
                rightItemInCart.ConfigurationGroup &&
                rightItemInCart.ConfigurationGroup.ShellStyle) ||
                (leftItemInCart &&
                  leftItemInCart.ConfigurationGroup &&
                  leftItemInCart.ConfigurationGroup.ShellStyle)) && (
                    
                    <tr>
                      <td>Shell Style</td>
                      <td>
                        {rightItemInCart ? (rightItemInCart.ConfigurationGroup.ShellStyle.Name ? rightItemInCart.ConfigurationGroup.ShellStyle.Name : rightItemInCart.ConfigurationGroup.ShellStyle) : ""}
                      </td>
                      <td>
                        {leftItemInCart ? (leftItemInCart.ConfigurationGroup.ShellStyle.Name ? leftItemInCart.ConfigurationGroup.ShellStyle.Name : leftItemInCart.ConfigurationGroup.ShellStyle) : ""}
                      </td>
                    </tr>
              )}
              {((rightItemInCart &&
                rightItemInCart.ConfigurationGroup &&
                rightItemInCart.ConfigurationGroup.PowerLevel &&
                rightItemInCart.ConfigurationGroup.PowerLevel.Name) ||
                (leftItemInCart &&
                  leftItemInCart.ConfigurationGroup &&
                  leftItemInCart.ConfigurationGroup.PowerLevel &&
                  leftItemInCart.ConfigurationGroup.PowerLevel.Name)) && (

                  <tr>
                    <td>Power Level</td>
                    <td>{rightItemInCart
                      ? rightItemInCart.ConfigurationGroup.PowerLevel.Name
                      : ""}</td>
                    <td>{leftItemInCart
                      ? leftItemInCart.ConfigurationGroup.PowerLevel.Name
                      : ""}</td>
                  </tr>


              )}
              {((rightItemInCart &&
                rightItemInCart.ConfigurationGroup &&
                rightItemInCart.ConfigurationGroup.ShellColour &&
                rightItemInCart.ConfigurationGroup.ShellColour.Name) ||
                (leftItemInCart &&
                  leftItemInCart.ConfigurationGroup &&
                  leftItemInCart.ConfigurationGroup.ShellColour &&
                  leftItemInCart.ConfigurationGroup.ShellColour.Name)) && (

                  <tr>
                    <td>Shell Colour</td>
                    <td>{rightItemInCart
                      ? rightItemInCart.ConfigurationGroup.ShellColour
                        .Name
                      : ""}</td>
                    <td>{leftItemInCart
                      ? leftItemInCart.ConfigurationGroup.ShellColour.Name
                      : ""}</td>
                  </tr>
              )}
              {((rightItemInCart &&
                rightItemInCart.ConfigurationGroup &&
                rightItemInCart.ConfigurationGroup.FaceplateColour &&
                rightItemInCart.ConfigurationGroup.FaceplateColour.Name) ||
                (leftItemInCart &&
                  leftItemInCart.ConfigurationGroup &&
                  leftItemInCart.ConfigurationGroup.FaceplateColour &&
                  leftItemInCart.ConfigurationGroup.FaceplateColour.Name)) && (

                  <tr>
                    <td>Faceplate Colour</td>
                    <td>{rightItemInCart
                      ? rightItemInCart.ConfigurationGroup.FaceplateColour
                        .Name
                      : ""}</td>
                    <td> {leftItemInCart
                      ? leftItemInCart.ConfigurationGroup.FaceplateColour
                        .Name
                      : ""}</td>
                  </tr>

              )}
              {/* {rightCustomOptionsArray.length > 0 &&
                leftCustomOptionsArray.length > 0 &&
                rightCustomOptionsArray.map((customOption, index) => {
                  return (
                    // <Row key={index}>
                    //   <Col xs={5}>{rightCustomOptions[customOption]}</Col>
                    //   <Col xs={2}>{customOption}</Col>
                    //   <Col xs={5}>{leftCustomOptions[customOption]}</Col>
                    // </Row>
                    <tr>
                      <td>{customOption}</td>
                      <td>{rightCustomOptions[customOption]}</td>
                      <td>{leftCustomOptions[customOption]}</td>
                    </tr>

                  );
              })}
              {leftCustomOptionsArray.length <= 0 &&
                rightCustomOptionsArray.length > 0 &&
                rightCustomOptionsArray.map((customOption, index) => {
                  return (
                    <tr>
                      <td>{customOption}</td>
                      <td>{rightCustomOptions[`${customOption}`]}</td>
                      <td></td>
                    </tr>

                  );
              })}
                {leftCustomOptionsArray.length > 0 &&
                  rightCustomOptionsArray.length <= 0 &&
                  leftCustomOptionsArray.map((customOption, index) => {
                    return (
                      <tr>
                        <td>{customOption}</td>
                        <td></td>
                        <td>{leftCustomOptionsArray[`${customOption}`]}</td>
                      </tr>
  
                    );
                })} */}
            {((rightItemInCart &&
              rightItemInCart.ConfigurationGroup &&
              rightItemInCart.ConfigurationGroup.PowerLevel &&
              rightItemInCart.ConfigurationGroup.PowerLevel.Name) ||
              (leftItemInCart &&
                leftItemInCart.ConfigurationGroup &&
                leftItemInCart.ConfigurationGroup.PowerLevel &&
                leftItemInCart.ConfigurationGroup.PowerLevel.Name)) && (

                      <tr>
                <td>Power Level</td>
                <td>{rightItemInCart
                            ? rightItemInCart.ConfigurationGroup.PowerLevel.Name
                            : ""}</td>
                <td>{leftItemInCart
                            ? leftItemInCart.ConfigurationGroup.PowerLevel.Name
                            : ""}</td>
              </tr>

         
                )}
            {((rightItemInCart &&
              rightItemInCart.ConfigurationGroup &&
              (rightItemInCart.ConfigurationGroup.ShellColour || 
              rightItemInCart.ConfigurationGroup.ShellColourName)) ||
              (leftItemInCart &&
                leftItemInCart.ConfigurationGroup &&
                (leftItemInCart.ConfigurationGroup.ShellColour ||
                leftItemInCart.ConfigurationGroup.ShellColourName))) && (
     
          
                    
                      <tr>
                <td>Shell Colour</td>
                <td>{rightItemInCart
                            ? (rightItemInCart.ConfigurationGroup.ShellColourName ? rightItemInCart.ConfigurationGroup.ShellColourName : rightItemInCart.ConfigurationGroup.ShellColour)
                            : ""}</td>
                <td>{leftItemInCart
                            ? (leftItemInCart.ConfigurationGroup.ShellColourName ? leftItemInCart.ConfigurationGroup.ShellColourName : leftItemInCart.ConfigurationGroup.ShellColour)
                            : ""}</td>
              </tr>

      
                )}
            {((rightItemInCart &&
              rightItemInCart.ConfigurationGroup &&
              (rightItemInCart.ConfigurationGroup.FaceplateColour ||
                rightItemInCart.ConfigurationGroup.FaceplateColourName)) ||
              (leftItemInCart &&
                leftItemInCart.ConfigurationGroup &&
                (leftItemInCart.ConfigurationGroup.FaceplateColour ||
                leftItemInCart.ConfigurationGroup.FaceplateColourName))) && (
                  <tr>
                  <td>Faceplate Colour</td>
                  <td>
                    {rightItemInCart
                      ? (rightItemInCart.ConfigurationGroup.FaceplateColourName ? rightItemInCart.ConfigurationGroup.FaceplateColourName : rightItemInCart.ConfigurationGroup.FaceplateColour)
                      : ""}
                  </td>
                  <td>
                    {leftItemInCart
                      ? (leftItemInCart.ConfigurationGroup.FaceplateColourName ? leftItemInCart.ConfigurationGroup.FaceplateColourName : leftItemInCart.ConfigurationGroup.FaceplateColour)
                      : ""}
                  </td>
                </tr>
                )}
                
                {rightCustomOptionsArray.length > 0 &&
                  leftCustomOptionsArray.length > 0 &&
                  rightCustomOptionsArray.map((customOption, index) => {
                    return (
                      <tr>
                        <td>{customOption}</td>
                        <td>{rightCustomOptions[customOption]}</td>
                        <td>{leftCustomOptions[customOption]}</td>
                      </tr>
                    );
                  })}
                {leftCustomOptionsArray.length <= 0 &&
                  rightCustomOptionsArray.length > 0 &&
                  rightCustomOptionsArray.map((customOption, index) => {
                    return (
                      <tr>
                        <td>{customOption}</td>
                        <td>{rightCustomOptions[`${customOption}`]}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                {leftCustomOptionsArray.length > 0 &&
                  rightCustomOptionsArray.length <= 0 &&
                  leftCustomOptionsArray.map((customOption, index) => {
                    return (
                      <tr>
                        <td>{customOption}</td>
                        <td></td>
                        <td>{leftCustomOptions[`${customOption}`]}</td>
                      </tr>
                    );
                  })}
                {((rightItemInCart && rightItemInCart.EarImpression) ||
                  (leftItemInCart && leftItemInCart.EarImpression)) && (
                  <tr>
                    <td>Ear Impression Scan</td>
                    <td>
                      {" "}
                      {rightItemInCart
                        ? rightItemInCart.EarImpression
                        : ""}
                    </td>
                    <td>
                      {leftItemInCart
                        ? leftItemInCart.EarImpression
                        : ""}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Row>
            <Col xs={12} className="special-instructions-div">
              <p>Special instructions for this item</p>
              <textarea
                id="specialInstructions"
                name="specialInstructions"
                rows="8"
              ></textarea>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

ProductSummary.propTypes = {
  cartData: PropTypes.array,
  orderBuilderData: PropTypes.object,
  addOrderToCart: PropTypes.func,
};

export default ProductSummary;
