import { createSelector } from 'reselect';

const relatedProducts = state => state.modules.relatedProducts;

export const getRelatedProductsStatus = createSelector(
  relatedProducts,
  state => state.relatedProductsStatus
);

export const getRelatedProductsData = createSelector(
  relatedProducts,
  state => state.relatedProductsData
);