import { createSelector } from 'reselect';

const orderBuilder = state => state.modules.orderBuilder;

export const getOrderBuilderStatus = createSelector(
  orderBuilder,
  state => state.orderBuilderStatus
);

export const getOrderBuilderData = createSelector(
  orderBuilder,
  state => state.orderBuilderData
);

export const getConfigurationGroupStatus = createSelector(
  orderBuilder,
  state => state.configurationGroupStatus
);

export const getConfigurationGroupData = createSelector(
  orderBuilder,
  state => state.configurationGroupData
);

export const getConfigurationGroupLeft = createSelector(
  orderBuilder,
  state => state.configurationGroupLeft
);

export const getConfigurationGroupRight = createSelector(
  orderBuilder,
  state => state.configurationGroupRight
);

export const getCustomOptionsGroupStatus = createSelector(
  orderBuilder,
  state => state.customOptionsGroupsStatus
);

export const getCustomOptionsGroupData = createSelector(
  orderBuilder,
  state => state.customOptionsGroupData
);

export const getCustomOptionsGroupLeft = createSelector(
  orderBuilder,
  state => state.customOptionsGroupLeft
);

export const getCustomOptionsGroupRight = createSelector(
  orderBuilder,
  state => state.customOptionsGroupRight
);

export const getCartStatus = createSelector(
  orderBuilder,
  state => state.cartStatus
);

export const getCartData = createSelector(
  orderBuilder,
  state => state.cartData
);