// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import * as actionCreators from './action-creators';
import { LOAD_ORDER_DETAIL_DATA } from './actions';
import { OrderDetailStatus } from './constants';
import api from '../../../utilities/api';
import { get } from "lodash";
import Cookies from 'universal-cookie';

export function* rootSaga() {
  yield takeLatest(LOAD_ORDER_DETAIL_DATA, loadOrderDetail);
}

export function* loadOrderDetail(orderIdPayload) {
  try {
    yield put(actionCreators.setOrderDetailStatus(OrderDetailStatus.LOADING));
    const orderId = get(orderIdPayload, "payload", {});

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };
    const response = yield call(api.get, `/api/order/${orderId}`, {headers:headerParams});
    //console.log("Order Detail", response.data);
    yield put(actionCreators.setOrderDetailData(response.data));
    yield put(actionCreators.setOrderDetailStatus(OrderDetailStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setOrderDetailStatus(OrderDetailStatus.ERROR));
  }
}