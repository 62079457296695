import { create } from 'lodash';
import { createSelector } from 'reselect';

// State is the application wide state
const sites = state => state.modules.sites; // this is from what you named the reducer in the global reducers.js

// organisation detail related
export const getSiteDetailStatus = createSelector(
  sites,
  state => state.siteDetailStatus
);

export const getSiteDetailData = createSelector(
  sites,
  state => state.siteDetailData
);

export const getSaveSiteStatus = createSelector(
  sites,
  state => state.saveSiteStatus
);

export const getSaveSiteUserStatus = createSelector(
  sites,
  state => state.saveSiteUserStatus
);

