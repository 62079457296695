import { create } from '../../../utilities/action-helpers';
import * as actions from './actions';

export const loadProfile = create(actions.LOAD_PROFILE_DATA);
export const setProfileStatus = create(actions.SET_PROFILE_STATUS);
export const setProfileData = create(actions.SET_PROFILE_DATA);
export const setOrderData = create(actions.SET_ORDER_DATA);
export const startEditProfile = create(actions.START_EDIT_PROFILE);
export const startChangePassword = create(actions.START_CHANGE_PASSWORD);
export const setChangePasswordStatus = create(actions.SET_CHANGE_PASSWORD_STATUS);
