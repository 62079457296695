import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { DashboardStatus } from './constants';

const dashboardStatus = createReducer({
  [actions.SET_DASHBOARD_STATUS]: (state, { payload }) => payload
}, DashboardStatus.IDLE);

export default combineReducers({
  dashboardStatus
});
