import { connect } from 'react-redux';

import orderSuccess from './orderSuccess';
import { addOrderToCart } from '../orderBuilder/store/action-creators';
import { loadOrderConfirmation } from '../orderConfirmation/store/action-creators';

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = {
  addOrderToCart,
  loadOrderConfirmation
}

export default connect(mapStateToProps, mapDispatchToProps)(orderSuccess);