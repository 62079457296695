import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table } from "react-bootstrap";
import { OrganisationUserListStatus } from '../../store/constants';
import Loading from '../../../../components/Loading/Loading';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import(/* webpackChunkName: "organisationUserList" */'./organisationUserList.scss');

const OrganisationUserList = ({
    organisationUserListStatus,
    organisationUserListData,
    loadOrganisationUserListData
  }) => {
    const { organisationId } = useParams();
  
    useEffect(() => {
      if (loadOrganisationUserListData) {
        loadOrganisationUserListData(organisationId);
      }
    }, [loadOrganisationUserListData]);

    // hard coded data
    // organisationListData = [
    //   {
    //     OrganisationCode: 'code',
    //     OrganisationName: 'name',
    //     PhonakCatalogueCode: 'phcatcode',
    //     UnitronCatalogueCode: 'uncatcode'
    //   }
    // ];

    const OrganisationUserTableRow = ({ user }) => {
      const firstName = get(user, "FirstName", "default");
    const lastName = get(user, "LastName", "default");
    const emailAddress = get(user, "EmailAddress", "default");
    const organisationCode = get(user, "OrganisationCode", "default");
    const siteId = get(user, "SiteId", "default");

      return (
        <tr>
        <td>{`${firstName} ${lastName}`}</td>
        <td>{emailAddress}</td>
        <td>{organisationCode}</td>
        <td>{siteId}</td>
      </tr>
      );
    };

    if (organisationUserListStatus === OrganisationUserListStatus.LOADING) {
      return (
        <Col>
          <div>
            <Loading />
          </div>
        </Col>
      )
    }
    return (
      
      <div className="dashboard-title">
        Dashboard: <span>{organisationUserListStatus}</span>
        <Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Organisation Code</th>
                <th>Site Id</th>
              </tr>
            </thead>
            <tbody>
              {
                organisationUserListData && 
                organisationUserListData.map((user, index) => (
                  <OrganisationUserTableRow key={index} user={user} />
                ))
              }
            </tbody>
          </Table>
        </Row>
        
      </div>
      
    )
};

OrganisationUserList.propTypes = {
  loadOrganisationUserListData: PropTypes.func,
  organisationUserListStatus: PropTypes.string,
  organisationUserListData: PropTypes.array
};

export default OrganisationUserList;
