// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import * as actionCreators from './action-creators';
import { LOAD_PRODUCTS_DATA } from './actions';
import { ProductsStatus } from './constants';
import { uniqBy, map, filter,find } from 'lodash';
import api from '../../../utilities/api';
import Cookies from 'universal-cookie';

export function* rootSaga() {
  yield takeLatest(LOAD_PRODUCTS_DATA, loadProducts);
}

export function* loadProducts() {
  try {
    yield put(actionCreators.setProductsStatus(ProductsStatus.LOADING));
    // yield delay(2600);
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken? This will logout if there is no token. Handled in the private layout
    const headerParams = {
      Authorization: `Bearer ${token}`
    };
    const response = yield call(api.get, '/api/category', {headers:headerParams});
    //console.log(response.data);

    let productDataArray = [];
    productDataArray = filter(response.data, item => item.ParentCode == null && item.InverseParentCodeNavigation.length > 0);
    const earpiecesType = find(response.data, item => item.Type == "Earpiece");
    productDataArray.push(earpiecesType);
    //console.log("productDataArray", productDataArray);

    yield put(actionCreators.setProductsData(productDataArray));
    yield put(actionCreators.setProductsStatus(ProductsStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setProductsStatus(ProductsStatus.ERROR));
  }
}