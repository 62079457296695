import combineReducers from "../../../utilities/combine-reducers";
import createReducer from '../../../utilities/create-reducer';
import * as actions from './actions';
import { SiteDetailStatus, AddEditSiteStatus, SaveSiteUserStatus } from './constants';

// site detail related
const siteDetailStatus = createReducer({
  [actions.SET_SITEDETAIL_STATUS]: (state, { payload }) => payload
}, SiteDetailStatus.IDLE);

const siteDetailData = createReducer({
  [actions.SET_SITEDETAIL_DATA]: (state, { payload }) => payload
}, SiteDetailStatus.IDLE);

const saveSiteStatus = createReducer({
  [actions.SET_SAVE_SITE_STATUS]: (state, { payload }) => payload
}, AddEditSiteStatus.IDLE);

const saveSiteUserStatus = createReducer({
  [actions.SET_SAVE_SITEUSER_STATUS]: (state , { payload }) => payload
}, SaveSiteUserStatus.IDLE);

export default combineReducers({
  siteDetailStatus,
  siteDetailData,
  saveSiteStatus,
  saveSiteUserStatus
});
