import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import(/* webpackChunkName: "order-builder-how-many" */ "./howMany.scss");

const HowMany = ({ howManyForOrder, setHowManyForOrder, leftorRight, setLeftOrRight }) => {
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);
  
  // //Sets quantity of item based on selection
  // const handleChange = (event) => {
  //   const myValue = event.target.value;
  //   setHowManyForOrder(myValue);
  // };
  
  //Sets quantity of item based on selection
  const handleChangeLeft = () => {
    setLeft(!left);
  };

  //Sets quantity of item based on selection
  const handleChangeRight = () => {
    setRight(!right);
  };

  useEffect(() => {
    let tempLeftOrRight = "";
    if(left && right) {
      tempLeftOrRight = "B";
      setLeftOrRight(tempLeftOrRight);
      return;
    } else if(left) {
      tempLeftOrRight = "L";
      setLeftOrRight(tempLeftOrRight);
      return;
    } else if (right) {
      tempLeftOrRight = "R";
      setLeftOrRight(tempLeftOrRight);
      return;
    }
    else {
      tempLeftOrRight = "";
      setLeftOrRight(tempLeftOrRight);
      return;
    }
  }, [left, right])

  return (
    <div className="how-many-component-main-div order-builder-component">
      <h2 className="order-builder-component-title">{'Select side(s)'}</h2>
      <div className="how-many-div">
        <div className="how-many-form">
          <div>
            <Row>              
              <Col md={2}>
                <input
                  className="large-checkbox"
                  onChange={handleChangeRight}
                  id="right"
                  type="checkbox"
                  value={right}
                />{" "}
                Right
              </Col>
              <Col md={2}>
                <input
                  className="large-checkbox"
                  onChange={handleChangeLeft}
                  id="left"
                  type="checkbox"
                  value={left}
                />{" "}
                Left
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowMany;


{/* 
  <Col md={2}>
    <input
      defaultChecked={true}
      onChange={handleChange}
      id="how-many-1"
      type="radio"
      value={1}
      name="howMany"
    />{" "}
    One
  </Col>
  <Col md={2}>
    <input
      onChange={handleChange}
      id="how-many-2"
      type="radio"
      value={2}
      name="howMany"
    />{" "}
    Two
  </Col> 
*/}