export const LOAD_ORDER_BUILDER_DATA = 'orderBuilder/LOAD_ORDER_BUILDER_DATA';
export const SET_ORDER_BUILDER_STATUS = 'orderBuilder/SET_ORDER_BUILDER_STATUS';
export const SET_ORDER_BUILDER_DATA = 'orderBuilder/SET_ORDER_BUILDER_DATA';

export const LOAD_CONFIGURATION_GROUP_DATA = 'orderBuilder/LOAD_CONFIGURATION_GROUP_DATA';
export const SET_CONFIGURATION_GROUP_STATUS = 'orderBuilder/SET_CONFIGURATION_GROUP_STATUS';
export const SET_CONFIGURATION_GROUP_DATA = 'orderBuilder/SET_CONFIGURATION_GROUP_DATA';
export const SET_CONFIGURATION_GROUP_LEFT = 'orderBuilder/SET_CONFIGURATION_GROUP_LEFT';
export const SET_CONFIGURATION_GROUP_RIGHT = 'orderBuilder/SET_CONFIGURATION_GROUP_RIGHT';

export const LOAD_CUSTOM_OPTIONS_GROUP_DATA = 'orderBuilder/LOAD_CUSTOM_OPTIONS_GROUP_DATA';
export const SET_CUSTOM_OPTIONS_GROUP_STATUS = 'orderBuilder/SET_CUSTOM_OPTIONS_GROUP_STATUS';
export const SET_CUSTOM_OPTIONS_GROUP_DATA = 'orderBuilder/SET_CUSTOM_OPTIONS_GROUP_DATA';
export const SET_CUSTOM_OPTIONS_GROUP_LEFT = 'orderBuilder/SET_CUSTOM_OPTIONS_GROUP_LEFT';
export const SET_CUSTOM_OPTIONS_GROUP_RIGHT = 'orderBuilder/SET_CUSTOM_OPTIONS_GROUP_RIGHT';

export const ADD_ORDER_TO_CART = 'cart/ADD_ORDER_TO_CART';
export const SET_CART_STATUS = 'cart/SET_CART_STATUS';
export const SET_CART_DATA = 'cart/SET_CART_DATA';