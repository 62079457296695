// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import * as actionCreators from './action-creators';
import { SUBMIT_ORDER, LOAD_ORDER_CONFIRMATION, RESET_ORDER_NUMBER } from './actions';
import { get } from 'lodash';
import { OrderConfirmationStatus } from './constants';
import api from '../../../utilities/api';
import moment from 'moment';

export function* rootSaga() {
  yield takeLatest(SUBMIT_ORDER, submitOrder);
  yield takeLatest(LOAD_ORDER_CONFIRMATION, loadOrderConfirmation);
  yield takeLatest(RESET_ORDER_NUMBER, resetOrderNumber);
  
}

export function* loadOrderConfirmation() {
  try {
    yield put(actionCreators.setOrderConfirmationStatus(OrderConfirmationStatus.LOADING));
    yield put(actionCreators.setSentOrderConfirmationStatus(OrderConfirmationStatus.IDLE));

    yield put(actionCreators.setOrderConfirmationStatus(OrderConfirmationStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setOrderConfirmationStatus(OrderConfirmationStatus.ERROR));
  }
}

export function* resetOrderNumber() {
  try {
    // yield console.log("reset order number STARTT")
    yield put(actionCreators.setOrderNumber(""));
    // yield console.log("reset order number END")

  }
  catch(error) {
    yield put(actionCreators.setOrderConfirmationStatus(OrderConfirmationStatus.ERROR));
  }
}

export function* submitOrder(data) {
  try {
    yield put(actionCreators.setSentOrderConfirmationStatus(OrderConfirmationStatus.LOADING));
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');
    const headerParams = {
      Authorization: `Bearer ${token}`
    };
    // console.log('data passed in to saga', data);

    const orderId = get(data, "", 0);
    const orderNumber = get(data, "", "");
    const userId = get(data, "", 0);
    const organisationCode = get(data, "3", "");
    const firstName = get(data, "5", "");
    const lastName = get(data, "6", "");
    const orderType = get(data, "", "");
    const orderDate = get(data, "", moment().tz("Pacific/Auckland").format());
    const isStock = get(data, "", true);
    const ponumber = get(data, "1", "");
    const dateOfBirth = get(data, "7", "");
    const requiredByDate = get(data, "8", "");
    const hafsOrder = get(data, "9", "");
    const tamperProofRequired = get(data, "10", "");
    const siteId = get(data, "4", 0);
    const customShippingInfo = get(data, "0", "");
    const gensolveId = get(data, "", "");
    const orderNotes = get(data, "", ""); 
    const specialInstructions = get(data, "2", "");
    const trackingStatus = get(data, "", "");
    const trackingNumber = get(data, "", "");
    const deliveryDate = get(data, "", moment().tz("Pacific/Auckland").format());
    const invoiceNumber = get(data, "", "");
    const invoiceDate = get(data, "", moment().tz("Pacific/Auckland").format());
    const invoiceAmount = get(data, "", 0);
    const orderItems = get(data, "payload", []);

    const body = {
      OrderId: orderId,
      OrderNumber: orderNumber,
      UserId: userId,
      OrganisationCode: organisationCode,
      ClientFirstName: firstName,
      ClientLastName: lastName,
      OrderType: orderType,
      OrderDate: orderDate,
      IsStock: isStock,
      Ponumber: ponumber,
      ClientDateOfBirth: dateOfBirth,
      RequiredByDate: requiredByDate,
      SiteCode: siteId,
      CustomShippingInfo: customShippingInfo,
      GensolveId: gensolveId,
      OrderNotes: orderNotes,
      TrackingStatus: trackingStatus,
      TrackingNumber: trackingNumber,
      DeliveryDate: deliveryDate,
      InvoiceNumber: invoiceNumber,
      InvoiceDate: invoiceDate,
      InvoiceAmount: invoiceAmount,
      OrderItems: orderItems, 
      SpecialInstructions: specialInstructions,
      IsHafs: hafsOrder,
      TamperProofRequired: tamperProofRequired,
    };

    // console.log(body);

    const response = yield call(api.post, '/api/order', body, {headers:headerParams});
    // console.log("order confirmation response", response);
    if(response.data){
      yield put(actionCreators.setOrderNumber(response.data));
      // console.log("setOrderNumber");
    }
    yield put(actionCreators.setSentOrderConfirmationStatus(OrderConfirmationStatus.SUCCESS));
  }
  catch(error) {
    // console.log("order confirmation response", error);
    yield put(actionCreators.setSentOrderConfirmationStatus(OrderConfirmationStatus.ERROR));
  }
}