import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Loading from "../../components/Loading/Loading";
import { useParams, useHistory, Link ,useLocation} from "react-router-dom";
import { find, uniqBy, map, filter, includes, concat, orderBy ,findIndex} from "lodash";
import { RelatedProductsStatus } from "./store/constants";
import { Row, Col, Button ,Form} from "react-bootstrap";
import DoneIcon from '@mui/icons-material/Done';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import(/* webpackChunkName: "relatedProducts" */ "./relatedProducts.scss");

const RelatedProducts = ({
  loadRelatedProducts,
  relatedProductsStatus,
  relatedProductsData,
  allProductsData,
  addOrderToCart,
  cartData,
}) => {
  let history = useHistory();
  const location = useLocation();
  const { productId } = useParams();
  const [productAddedToCart, setProductAddedToCart] = useState();
  const [uniqueProductCategories, setUniqueProductCategories] = useState();
  const [localRelatedProductsData, setLocalRelatedProductsData] = useState();
  const [activeRelatedProductCategory, setActiveRelatedProductCategory] = useState();
  const [relatedProductsDataByCategory, setRelatedProductsDataByCategory] = useState();
  const [alreadyClickProductArray, setAlreadyClickProductArray] = useState([]);
  const [inCartTotalsAdded, setInCartTotalsAdded] = useState(false);
  const [productsAddedToCart, setProductsAddedToCart] = useState();
  const [otherProductsAddedToCart, setOtherProductsAddedToCart] = useState();
  const [productsForCart, setProductsForCart] = useState();
  const [hasChargers, setHasChargers] = useState(false);

  useEffect(() => {
    //console.log(
    //   "location.state.productsForCart",
    //   location.state.productsAddedToCart
    // );
    if (location.state.productsAddedToCart) {
      setProductsAddedToCart(location.state.productsAddedToCart);
    }
    if (location.state.productsForCart) {
      setProductsForCart(location.state.productsForCart);
    }
  }, [location]);


  useEffect(() => {
    if (loadRelatedProducts&&productsAddedToCart) {
      //const product = find(cartData, { Code: productId });
      const product = find(productsAddedToCart, { Code: productId });
      //console.log("productAddedToCart:",product);
      setProductAddedToCart(product);

      if(productsAddedToCart.length > 0){

        const otherItems = productsAddedToCart.filter((item) => {
          if(item){
            //console.log(productsAddedToCart);
            return item.Code !== productId;
          }
        })
        
        setOtherProductsAddedToCart(otherItems);
        
        if (product) {
          if (product.ItemCode) {
            loadRelatedProducts(product.ItemCode);
          }
        }
      }
    }
  }, [loadRelatedProducts,productsAddedToCart]);
 
  useEffect(() => {
    if (relatedProductsData) {
      
      //Get all related products
      let tempRelatedProductsArray = [...relatedProductsData];

      //Get uniqueProductCategories
      let uniqueCategoryNames = uniqBy(
        tempRelatedProductsArray,
        "CategoryName"
      );
      //console.log("uniqby uniqueCategoryNames", uniqueCategoryNames);

      uniqueCategoryNames = map(uniqueCategoryNames, "CategoryName");
      //console.log("map uniqueCategoryNames", uniqueCategoryNames);

      //Removes nulls
      uniqueCategoryNames = filter(uniqueCategoryNames, (v) => v);
      //console.log("filter uniqueCategoryNames", uniqueCategoryNames);

      //Set uniqueProductCategories

      uniqueCategoryNames = uniqueCategoryNames.sort();

      setUniqueProductCategories(uniqueCategoryNames);
      setActiveRelatedProductCategory(uniqueCategoryNames[0]);
      //console.log("uniqueCategoryNames", uniqueCategoryNames);

      // set up localRelatedProductsData with AddToCartQuantity && QuantityInCart
      var tempLocalRelatedProductsData = relatedProductsData.map( x => {
        x.AddToCartQuantity=1;
        x.QuantityInCart=0;
          return x
          });
      if(cartData)
      {
        tempLocalRelatedProductsData = tempLocalRelatedProductsData.map( x => {
              x.AddToCartQuantity=1;
              x.QuantityInCart=getQuantityInCart(x);
                return x
          });
          setInCartTotalsAdded(true);
          
        };
      setLocalRelatedProductsData(tempLocalRelatedProductsData);
      const chargers = uniqueCategoryNames.includes('Chargers')
      //console.log("chargers", chargers);
      if(chargers) {
        setHasChargers(true);
      }
      else {
        setHasChargers(false);
      }
    }
  }, [relatedProductsData]);
  // Update productsByCategory when selected category is changed
  useEffect(() => {
    if (localRelatedProductsData) {
      let tempRelatedProductsDataByCategory = [...localRelatedProductsData];
      tempRelatedProductsDataByCategory = filter(
        tempRelatedProductsDataByCategory,
        { CategoryName: activeRelatedProductCategory }
      );
      setRelatedProductsDataByCategory(tempRelatedProductsDataByCategory);
    }
  }, [activeRelatedProductCategory]);
  const getQuantityInCart = (product)=>
  {
    let quantityInCart=0;
    var index = findIndex(cartData, { Code: product.Code });
    if(index>0)
    {
      quantityInCart = cartData[index].Quantity;
    }
    return quantityInCart;

  };
  const goToCart = () => {
    history.push({ pathname: "/cart" });
  };
  // handle add to cart
  const handleOnClick = (product) => {
    //console.log("START ADD TO CART");
    let tempCartData = [...cartData];
    let quantityInCart=0;
    if (tempCartData.some((e) => e.Code === product.Code)) {
      tempCartData = cartData.map((obj) => {
        if (obj.Code === product.Code) {
          obj.Quantity = obj.Quantity + Number(product.AddToCartQuantity);
          quantityInCart=obj.Quantity;
          //console.log("Existing - New Qty:",obj.Quantity);
          return obj;
        }
        return obj;
      });
    } else {
      product.Quantity = Number(product.AddToCartQuantity);
      //console.log("New Qty:",product.Quantity);
      quantityInCart = product.Quantity;
      product.ItemCode = product.Code;
      tempCartData.push(product);
    }
    addOrderToCart(tempCartData);
    updateQuantityInCart(product,quantityInCart);
    const newAlreadyClickProductArray = concat(alreadyClickProductArray, product.Code);
    setAlreadyClickProductArray(newAlreadyClickProductArray);
    //console.log("FINISHED ADD TO CART");
  };
  // handle change in AddToCartQuantity for item
    const startChangeQuantity = (event, item) => {
      let tempLocalRelatedProductsData = [...localRelatedProductsData];
      // Find item index in array of items in cart by using the item code of the item passed into function
      var index = findIndex(tempLocalRelatedProductsData, { Code: item.Code });
      let tempItem = item;
      // Update the quanitity to value inputed by user
      tempItem.AddToCartQuantity = event.target.value;
      //console.log("Changing AddToCartQuantity:", index,tempItem);
      // Replace item at index using native splice
      tempLocalRelatedProductsData.splice(index, 1, tempItem);
      // set updated array to state
      setLocalRelatedProductsData(tempLocalRelatedProductsData);
      //console.log("localRelatedProductsData: ",localRelatedProductsData);
    };
    const updateQuantityInCart = (item,quantityInCart) => {
      let tempLocalRelatedProductsData = [...localRelatedProductsData];
      // Find item index in array of items in cart by using the item code of the item passed into function
      var index = findIndex(tempLocalRelatedProductsData, { Code: item.Code });
      let tempItem = item;
      // Update the quanitity to value inputed by user
      tempItem.QuantityInCart =Number(quantityInCart);
      // Replace item at index using native splice
      tempLocalRelatedProductsData.splice(index, 1, tempItem);
      // set updated array to state
      setLocalRelatedProductsData(tempLocalRelatedProductsData);
    };
  const handleConfigureOnClick = (product) => {
    //console.log("productAddedToCart",productAddedToCart)
    const earpiecesPath = "/store/Earpieces/" + productAddedToCart.CategoryCode + "/" + productAddedToCart.ModelTypeCode;
    history.push({
      pathname: earpiecesPath,
      state: { product: product },
    });
    // history.push({
    //   pathname: "/store/Earpieces/Earpiece",
    //   state: { product: product },
    // });
  };

  return (
    <div className="related-products-main-div">
      {relatedProductsStatus === RelatedProductsStatus.LOADING &&
        !productAddedToCart && <Loading />}
      {relatedProductsStatus === RelatedProductsStatus.SUCCESS &&
        productAddedToCart && (
          <div>
            <Row>
              <Col xs={10}>
                <h1>The items have been added to your cart</h1>
              </Col>
              <Col xs={2}>
                <div className="go-to-cart-button-div">
                  <Button className="" onClick={goToCart}>
                    Checkout
                  </Button>
                  <p>
                    <Link className="" to="/">
                      {"Continue Shopping >"}
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="item-details-row">
              <Col xs={3}>
                <img src={productAddedToCart.ImagePath} className="img-fluid" />
                {productAddedToCart.ConfigurationGroup &&
                  productAddedToCart.ConfigurationGroup.ShellColour &&
                  productAddedToCart.ConfigurationGroup.ShellColour.Rgb && (
                    <div
                      className="color-selected-ite"
                      style={{
                        backgroundColor:
                          productAddedToCart.ConfigurationGroup.ShellColour.Rgb,
                      }}
                    ></div>
                  )}
                <p>Item Code: {productAddedToCart.Code}</p>
                {productAddedToCart.ColourRgb && productAddedToCart.ColourName && 
                <div className="colour-component-main-div order-builder-component">
                  <div className="colour-box " 
                  style={{ backgroundColor: "rgb(" + productAddedToCart.ColourRgb + ")"}}
                  >

                  </div>
                  <div className="colour-box-text"><p>{productAddedToCart.ColourName}</p></div>
                  </div>
                }
                {/* <img src={productAddedToCart.ImagePath} className='img-fluid' /> */}
              </Col>
              <Col xs={9}>
                <h3>{productAddedToCart.Name}</h3>
                {hasChargers && <p>Default charger option provided as standard. If additional charger options are required, please click on the chargers tab below</p>}
                <p>{productAddedToCart.Description}</p>
                <div>
                  
                  
                  {otherProductsAddedToCart && 
                (
                  <ul className="list-unstyled">
                  {
                    otherProductsAddedToCart.map((itemAddedToCart, index) => {
                    return (
                      
                      
                    <li>{itemAddedToCart.Quantity } x {itemAddedToCart.Name } </li>  
                    )})
                    
                }
                </ul>

                    )
                    
                    
                  }
                    </div>
              </Col>
            </Row>
            {localRelatedProductsData && (
              <div>
                <Row className="bottom-section-row">
                  <Col xs={12}>
                    <h2 className="bottom-section-title">
                      Your clients might also be interested in these items that
                      work with {productAddedToCart.Name} :
                    </h2>
                  </Col>
                </Row>
                <Row className="related-item-categories-row">
                  {uniqueProductCategories.map((productName, index) => {
                    return (
                      <Col
                        md={2}
                        xs={12}
                        key={index}
                        className={
                          activeRelatedProductCategory === productName
                            ? "active related-item-category-div"
                            : "related-item-category-div"
                        }
                        onClick={() =>
                          setActiveRelatedProductCategory(productName)
                        }
                      >
                        <h3>{productName}</h3>
                      </Col>
                    );
                  })}
                </Row>
                <Row className="related-product-cards">
                  {activeRelatedProductCategory == "Chargers" ? <Col xs={12}><p>If no charger selected, default charger will be provided</p></Col> : ""}
                  {relatedProductsDataByCategory &&
                    relatedProductsDataByCategory.map((product, index) => {
                      return (
                        <Col
                          md={2}
                          xs={12}
                          key={product.Code}
                          className="related-product-cols"
                        >
                          <div className="related-product-card">
                            <div className="related-product-title-div">
                              <h3 className="related-product-category-title">
                                {product.Name}  
                              </h3>
                            </div>
                            <div className="related-product-img">
                              <span className="helper"></span>
                              <img
                                src={product.ImagePath}
                                className="img-fluid"
                              />
                            </div>
                            <p>{product.Code}     {product.QuantityInCart>0? <span><ShoppingCartIcon />{product.QuantityInCart}</span>:""}</p>
                            {product.CategoryCode == "Earpieces" ? (
                              <a
                                onClick={() => handleConfigureOnClick(product)}
                                className="btn btn-secondary related-product-button"
                              >
                                Configure
                              </a>
                            ) : (
                              <div className="add-related-to-cart">
                                <Form.Control
                                  type="number"
                                  name="quantity"
                                  defaultValue={product.AddToCartQuantity}
                                  onChange={(event) =>
                                    startChangeQuantity(event, product)
                                  }
                                  min="1"
                                  className="me-2"
                                  aria-label="Stock"
                                />
                              <a
                                onClick={() => handleOnClick(product)}
                                className={`btn btn-primary related-product-button `}
                              >Add to cart</a>
                              </div>
                            )}
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            )}
            <Row className="go-to-cart-button-button-div">
              <Col xs={12}>
                <div className="go-to-cart-button-div">
                  <Button className="" onClick={goToCart}>
                    Checkout
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        )}
    </div>
  );
};

RelatedProducts.propTypes = {
  loadRelatedProducts: PropTypes.func,
  relatedProductsStatus: PropTypes.string,
  relatedProductsData: PropTypes.array,
  allProductsData: PropTypes.array,
  cartData: PropTypes.array,
  addOrderToCart: PropTypes.func,
};

export default RelatedProducts;
