import React, { useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

import(
  /* webpackChunkName: "privacy-policy-unitron" */ "./privacyPolicyUnitron.scss"
);

const PrivacyPolicyUnitron = ({}) => {
  return (
    <Row>
      <Col xs={12}>
        <div className="privacy-policy-main-div">
          <section className="content-block">
            <div className="row">
              <section>
                <div className="small-12 column">
                  <h1>Data Protection Statement</h1>
                </div>
              </section>

              <div className="small-12 column end ">
                <div className="rte ">
                  <p>
                    <b>General Information</b>
                  </p>
                  <p>
                    Sonova AG is incorporated under the laws of Switzerland, as
                    a data controller, with its registered address at
                    Laubisrütistrasse 28, 8712 Stäfa, Switzerland, and operates
                    with its affiliates located around the world
                    {
                      "(collectively referred to as the “Company” or “we” or “our”)"
                    }
                    .&nbsp;
                  </p>
                  <p>
                    As the Company processes Personal Data in its day-to-day
                    business, this Global Privacy Policy {"(“Policy”)"} has been
                    drafted and implemented in order to describe the Company's
                    practices regarding the use of Personal Data about its
                    customers, contractors and partners {"(“Data Subjects”)"}.
                    The Company pays particular attention to the respect of
                    privacy and Personal Data and is committed to complying with
                    this Policy, in accordance with applicable local laws.&nbsp;
                  </p>
                  <p>
                    By “Personal Data” we mean any information relating to an
                    identified or identifiable natural person.
                  </p>
                  <p>
                    By “processing” we mean any operation or set of operations
                    which is performed on Personal Data or on sets of Personal
                    Data, whether or not by automated means, such as collection,
                    recording, organization, structuring, storage, adaptation or
                    alteration, retrieval, consultation, use, disclosure by
                    transmission, dissemination or otherwise making available,
                    alignment or combination, restriction, erasure or
                    destruction.&nbsp;
                  </p>
                  <p>
                    <b>Policy's Applicable Law</b>
                  </p>
                  <p>
                    The Company undertakes to comply with the applicable data
                    protection law {"(“Applicable Law”)"}. Thus, depending on
                    the countries where the Company is established, the
                    processing of Personal Data will be subject to the local
                    Applicable Law. Although certain requirements may vary from
                    one country to another, the Company is particularly
                    concerned about the privacy of Data Subjects, and this
                    Policy constitutes a global guideline to which the Company
                    is committed.&nbsp;
                  </p>
                  <p>
                    In particular, the Company is committed to complying with
                    the following laws, where applicable:&nbsp;
                  </p>
                  <ul>
                    <li>
                      The Regulation {"(EU)"} 2016/679 of the European
                      Parliament and of the Council of 27 April 2016 on the
                      protection of natural persons with regard to the
                      processing of personal data and on the free movement of
                      such data, and repealing Directive 95/46/EC{" "}
                      {"(General Data Protection Regulation)"} {"(“GDPR”)"}. The
                      GDPR aims to harmonize and frame the rules relating to the
                      processing of Personal Data on the territory of the
                      European Union in order to provide a single legal
                      framework for professionals, and seeks to strengthen
                      control by citizens of the use that may be made of
                      Personal Data concerning them. This regulation, applies to
                      the processing of Personal Data for UE citizens or
                      residents and for the activity of a controller or a
                      processor in the UE territory.&nbsp;
                    </li>
                    <li>
                      The Swiss law Federal Act on Data Protection of June 19,
                      1992 {"(“FADP”)"}, modified in 2020 in order to adapt to
                      current technological and align with the GDPR and other
                      recent European regulations.&nbsp;
                    </li>
                    <li>
                      The California Consumer Privacy Act of 2018 {"(“CCPA”)"}{" "}
                      which aims to provide more transparency and to guarantee
                      more rights to consumers residing in California whose
                      Personal Data are processed by companies.&nbsp;
                    </li>
                    <li>
                      The Health Insurance Portability and Accountability Act of
                      1996 {"(“HIPAA”)"} which defines the United States rules
                      for the electronic processing of health data by health
                      actors and business partners.&nbsp;
                    </li>
                  </ul>
                  <p>
                    <b>Personal Data Collected</b>
                  </p>
                  <p>
                    The Company may process the following Personal Data:&nbsp;
                  </p>
                  <ul>
                    <li>
                      Identity data: last name, first name, nationality and date
                      of birth&nbsp;&nbsp;
                    </li>
                    <li>
                      Contact details data: postal address, private phone
                      number, private email address and emergency contact&nbsp;
                    </li>
                    <li>
                      Social security reference number and insurance
                      company&nbsp;&nbsp;
                    </li>
                    <li>
                      Financial data: means of payment, financial institution,
                      IBAN&nbsp;
                    </li>
                    <li>
                      Data relating to the user health : weight, height, medical
                      trouble, doctor's prescription, hearing capacity, physical
                      activity tracking{" "}
                      {"(step count, exercise intensity, exercise minutes)"},
                      fitness data{" "}
                      {"(heart rate, energy expenditure, blood pressure)"}&nbsp;
                    </li>
                    <li>
                      Data relating to the user behavior on the website&nbsp;
                    </li>
                    <li>
                      Data relating to the product purchased by the customer:
                      model, serial number, usage data&nbsp;
                    </li>
                    <li>Data relating to the service provided&nbsp;</li>
                    <li>
                      Data relating to the feedback the customer provides on our
                      products and services: comments and notes&nbsp;
                    </li>
                  </ul>
                  <p>
                    In addition, as our activity is mainly focused on
                    manufacturing innovative solutions for hearing aids, we may
                    be required to collect sensitive Personal Data and more
                    specifically health data. Depending on the country where the
                    Data Subject reside, those sensitive Personal Data may
                    benefit from special protection, particularly in terms of
                    security and confidentiality measures implemented.&nbsp;
                  </p>
                  <p>
                    <b>Purposes of processing Personal Data</b>
                    <br />{" "}
                  </p>
                  <p>
                    The following legal bases constitute the foundation on which
                    the Company relies to carry out the processing of Personal
                    Data. Other legal bases may be used depending on where the
                    Data Subject resides and the relevant Applicable Law.&nbsp;
                  </p>
                  <p>
                    Some processing of Personal Data may be based on the consent
                    of Data Subjects. The processing of Personal Data for this
                    purpose may involve:&nbsp;
                  </p>
                  <ul>
                    <li>
                      Marketing purposes such as sending newsletters and
                      information about products and services offered by the
                      Company&nbsp;
                    </li>
                    <li>To improve the performance of our website&nbsp;</li>
                    <li>
                      To advise and interact with you : for the creation of your
                      account, to contact us via the contact form, for Sonova to
                      respond to users, to take an online hearing
                      test&nbsp;&nbsp;
                    </li>
                  </ul>
                  <p>
                    The processing of Personal Data that the Company carries out
                    may also be based on the execution of a contract or
                    pre-contractual measures with Data Subjects. The processing
                    of Personal Data for this purpose may involve:&nbsp;
                  </p>
                  <ul>
                    <li>
                      Fulfillment of our contractual obligations towards Data
                      Subjects&nbsp;&nbsp;
                    </li>
                    <li>
                      Provision of after-sales service after the purchase of a
                      product by a customer&nbsp;
                    </li>
                    <li>Social Security / insurance processing&nbsp;</li>
                    <li>Claims management&nbsp;</li>
                  </ul>
                  <p>
                    The Company may also process Personal Data based on its
                    legitimate interest, in particular in order to improve our
                    products and services, customer experience and internal
                    processes. The processing of Personal Data for this purpose
                    may involve:&nbsp;
                  </p>
                  <ul>
                    <li>Conducting statistical/usage analysis&nbsp;</li>
                    <li>Performing internal administrative functions&nbsp;</li>
                    <li>Processing customer requests&nbsp;</li>
                    <li>
                      Prevent fraudulent activity and improve security&nbsp;
                    </li>
                    <li>Relationship management with Data Subjects&nbsp;</li>
                    <li>
                      Evaluation of the relevance of our products and
                      services&nbsp;
                    </li>
                  </ul>
                  <p>
                    The Company may also process Personal Data in order to
                    respond to legal requirements. Processing based on legal
                    requirement depends on the Applicable law.&nbsp;
                  </p>
                  <p>
                    <b>Retention of Personal Data</b>
                    <br />{" "}
                  </p>
                  <p>
                    Personal Data will not be kept longer than necessary for the
                    above-mentioned purposes. This means that Personal Data will
                    be deleted as soon as the purpose of the processing of
                    Personal Data has been achieved. However, the Company may
                    retain Personal Data longer if necessary to comply with
                    Applicable Law, or if necessary to protect or exercise our
                    rights, to the extent permitted by applicable data
                    protection law.&nbsp;
                  </p>
                  <p>
                    At the end of the retention period, the Company may also
                    need to archive Personal Data, to comply with Applicable
                    Law, for a limited period of time and with limited
                    access.&nbsp;
                  </p>
                  <p>
                    These retention periods may vary depending on the country
                    where the Data Subjects reside and in accordance with
                    Applicable Law.&nbsp;
                  </p>
                  <p>
                    <b>Disclosure of Personal Data</b>
                    <br />{" "}
                  </p>
                  <p>
                    The Company may share Personal Data, subject to your consent
                    or other relevant legal basis, with the following
                    third-parties:&nbsp;
                  </p>
                  <ul>
                    <li>
                      Other companies of our group such as subsidiaries and
                      affiliated companies&nbsp;
                    </li>
                    <li>
                      Trusted business partners providing services on our
                      behalf, such as for technical support, for marketing
                      purposes or for other types of service delivery&nbsp;
                    </li>
                    <li>
                      Governmental authorities and public authorities, as far as
                      this is necessary to provide any services that have been
                      requested or authorized, to protect customers, contractor
                      and partners' rights, or our or others' rights, property
                      or safety, to maintain the security of our services or if
                      we are required to do so because of Applicable Law, court
                      or other governmental regulations, or if such disclosure
                      is otherwise necessary in support of any legal or criminal
                      investigation or legal proceeding&nbsp;
                    </li>
                  </ul>
                  <p>
                    Depending on Applicable Law, we implement contracts with
                    some third-parties to ensure that Personal Data are
                    processed based on our instructions and in compliance with
                    this Policy and any other appropriate confidentiality and
                    security measures.&nbsp;
                  </p>
                  <p>
                    <b>Transfers of Personal Data</b>
                    <br />{" "}
                  </p>
                  <p>
                    The above-mentioned third-parties such as affiliates and
                    subsidiaries, as well as business partners, public
                    authorities to whom we may disclose Personal Data, may be
                    located outside of a Data Subject's country of domicile,
                    potentially including countries whose data protection laws
                    may differ from those in the country in which Data Subjects
                    are located.&nbsp;
                  </p>
                  <p>
                    If Personal Data are processed within the European
                    Union/European Economic Area, and in the event Personal Data
                    are disclosed to third parties in a country not considered
                    as providing an adequate level of protection according to
                    the European Commission, the Company will ensure:&nbsp;
                  </p>
                  <ul>
                    <li>
                      The implementation of adequate procedures to comply with
                      Applicable Law, and in particular when a request for
                      authorization from the competent supervisory authority is
                      necessary&nbsp;
                    </li>
                    <li>
                      The implementation of appropriate organizational,
                      technical and legal safeguards to govern the said transfer
                      and to ensure the necessary and adequate level of
                      protection under Applicable Law&nbsp;
                    </li>
                    <li>
                      If necessary, the implementation of Standard Contractual
                      Clauses as adopted by the European Commission&nbsp;
                    </li>
                    <li>
                      If necessary, take supplementary measures such as
                      completing a data transfer adequacy assessment if, after
                      evaluation of the circumstances of the transfer, and after
                      evaluation of the legislation of the third country, it is
                      necessary for the protection of the transferred Personal
                      Data.&nbsp;
                    </li>
                  </ul>
                  <p>
                    If Personal Data are not processed within the European
                    Union/European Economic Area, and in the event Personal Data
                    are disclosed to third parties located outside the Data
                    Subject's jurisdiction, the Company will ensure that
                    appropriate safeguards are in place to protect Personal Data
                    by implementing appropriate legal mechanisms. Those
                    mechanisms may differ depending on the country and relevant
                    Applicable Law.&nbsp;
                  </p>
                  <p>
                    <b>Personal Data Security</b>
                    <br />{" "}
                  </p>
                  <p>
                    The Company implements a variety of security measures,
                    according to Applicable Law, in order to protect Personal
                    Data from security incidents or unauthorized disclosure, and
                    more generally from a Personal Data breach. These security
                    measures are recognized as appropriate security standards in
                    the industry and include, inter alia, access controls,
                    password, encryption and regular security assessments.&nbsp;
                  </p>
                  <p>
                    If a Personal Data breach occurs, and in particular if there
                    is a breach of security resulting, accidentally or
                    unlawfully, in the destruction, loss, alteration,
                    unauthorized disclosure or access to Personal Data
                    transmitted, stored or otherwise processed, the Company will
                    take appropriate measures such as:&nbsp;
                  </p>
                  <ul>
                    <li>
                      Investigating and analyzing in order to determine the
                      consequences of the Personal Data Breach and in particular
                      whether it is likely to create a risk for the rights and
                      freedoms of those affected&nbsp;
                    </li>
                    <li>
                      If the analysis shows that there is a risk to the rights
                      and freedoms of those affected, the Company will notify
                      the competent authority and, in case of high risk,
                      communicate to those affected&nbsp;
                    </li>
                    <li>
                      Implement as soon as possible the measures necessary to
                      remediate and mitigate the Personal Data breach&nbsp;
                    </li>
                    <li>
                      Document the Personal Data breach in order to ensure its
                      traceability&nbsp;
                    </li>
                  </ul>
                  <p>
                    Appropriate measures and procedures in the event of a
                    Personal Data breach may differ depending on the country
                    where it occurs, the type of breach and depending on the
                    relevant Applicable Law.&nbsp;
                  </p>
                  <p>
                    <b>Privacy rights related to Personal Data</b>
                    <br />{" "}
                  </p>
                  <p>
                    As may vary based on relevant Applicable Law, Data Subjects
                    have rights related to their Personal Data, such as the
                    right to request access, rectification, erasure of their
                    Personal Data, restriction of processing, object to
                    processing, request data portability, to be informed and
                    withdraw their consent for processing of Personal Data based
                    on their consent. Data Subjects may also object to automated
                    individual decision-making if they are concerned by such
                    processing.&nbsp;&nbsp;
                  </p>
                  <p>
                    In addition, in some jurisdictions you may provide
                    instructions relating to the retention, communication and
                    erasure of your Personal Data posthumously.&nbsp;&nbsp;
                  </p>
                  <p>
                    The exercise of such rights is not absolute and is subject
                    to the limitations provided by Applicable Law.&nbsp;&nbsp;
                  </p>
                  <p>
                    Data Subjects may have the right to lodge a complaint with
                    the local supervisory authority or the competent regulator
                    if they consider that the processing of their Personal Data
                    infringes Applicable Law.&nbsp;
                  </p>
                  <p>
                    To exercise those privacy rights, Data Subjects may contact
                    us as described in the section “How to contact us” below. We
                    may ask proof of identity in order to respond to the
                    request. If we can't satisfy your request{" "}
                    {"(refusal or limitation)"}, we will motivate our decision
                    in writing.&nbsp;
                  </p>
                  <p>
                    <b>Updates to this Policy</b>
                    <br />{" "}
                  </p>
                  <p>
                    If necessary, we may from time to time need to update this
                    Policy in order to reflect new or different privacy
                    practices. In this case, we will post updated versions of
                    this Policy on this page. A revised Policy will apply only
                    to data collected subsequent to its effective date. We
                    encourage you to periodically review this page for the
                    latest information on our privacy practices.&nbsp;
                  </p>
                  <p>
                    <b>How to contact us</b>
                    <br />{" "}
                  </p>
                  <p>
                    For any questions, comments, or concerns about this Policy,
                    or in order to exercise the privacy rights permitted by
                    Applicable Law related to Personal Data, please contact our
                    Data Protection Officer at the following address: Sonova AG,
                    Laubisruetistrasse 28, 8712 Stäfa, Switzerland or by sending
                    an e-mail at:{" "}
                    <a href="mailto:privacy@sonova.com">
                      privacy@sonova.com&nbsp;
                    </a>
                  </p>
                  <p>
                    Effective Date:&nbsp;February 2022
                    <br />{" "}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Col>
    </Row>
  );
};

export default PrivacyPolicyUnitron;
