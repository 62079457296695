import { connect } from 'react-redux';

import TopLevelCategory from './topLevelCategory';
import { getProductsStatus, getProductsData } from '../../store/selectors';
import { loadProducts } from '../../store/action-creators';


const mapStateToProps = (state) => ({
  productsStatus: getProductsStatus(state),
  productsData: getProductsData(state),
});

const mapDispatchToProps = {
  loadProducts
}

export default connect(mapStateToProps, mapDispatchToProps)(TopLevelCategory);