// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import * as actionCreators from './action-creators';
import * as adminFormActionCreators from '../../administrators/store/action-creators';
import { LOAD_ORGANISATIONLIST_DATA, LOAD_ORGANISATIONUSERLIST_DATA, LOAD_ORGUSER_DATA, SAVE_ORGUSER_DATA, LOAD_ORGANISATIONDETAIL_DATA, RESET_SAVEORGUSER_STATUS } from './actions';
import { OrganisationListStatus, OrganisationUserListStatus, AddOrgUserStatus, OrgUserDetailStatus, OrganisationDetailStatus } from './constants';
import { AddAdminStatus } from '../../administrators/store/constants';
import api from '../../../utilities/api';
import { get } from 'lodash';
import { SAVE_ADMIN_DATA } from '../../administrators/store/actions';

export function* rootSaga() {
  yield takeLatest(LOAD_ORGANISATIONLIST_DATA, loadOrganisationList);
  yield takeLatest(LOAD_ORGANISATIONUSERLIST_DATA, loadOrganisationUserList);
  yield takeLatest(LOAD_ORGUSER_DATA, loadOrgUserData);
  yield takeLatest(SAVE_ORGUSER_DATA, saveOrgUserData);
  yield takeLatest(LOAD_ORGANISATIONDETAIL_DATA, loadOrganisationDetailData);
  yield takeLatest(RESET_SAVEORGUSER_STATUS, resetSaveOrgUserStatus);
}

// reset addOrgUser status
export function* resetSaveOrgUserStatus() {
  try {
    // reset form status and form org user detail (may change this)
    yield put(actionCreators.setSaveOrgUserStatus(AddOrgUserStatus.IDLE));
    yield put(actionCreators.setOrgUserData());
    yield put(actionCreators.setErrorStatus(''));
    //console.log('reset everything');
  }
  catch(error) {
    yield put(actionCreators.setSaveOrgUserStatus(AddOrgUserStatus.ERROR));
  }
}


// list related
export function* loadOrganisationList() {
  try {
    yield put(actionCreators.setOrganisationListStatus(OrganisationListStatus.LOADING));

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken?
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    yield delay(300);
    const response = yield call(api.get, '/api/organisation', {headers:headerParams});
    yield put(actionCreators.setOrganisationListData(response.data));
    yield put(actionCreators.setOrganisationListStatus(OrganisationListStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setOrganisationListStatus(OrganisationListStatus.ERROR));
  }
}

export function* loadOrganisationUserList(organisationIdPayload) {
  try {
    yield put(actionCreators.setOrganisationUserListStatus(OrganisationUserListStatus.LOADING));
    const organisationId = get(organisationIdPayload, "payload", {});

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken?
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    yield delay(300);
    const response = yield call(api.get, `/api/organisation/${organisationId}/users`, {headers:headerParams});
    // //console.log(response.data);
    yield put(actionCreators.setOrganisationUserListData(response.data));
    yield put(actionCreators.setOrganisationUserListStatus(OrganisationListStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setOrganisationUserListStatus(OrganisationListStatus.ERROR));
  }
}

// organisation detail related
export function* loadOrganisationDetailData(orgCodePayload) {
  try {
    // //console.log(orgCodePayload);
    yield put(actionCreators.setOrganisationDetailStatus(OrganisationDetailStatus.LOADING));
    
    // set admin detail to idle
    yield put(adminFormActionCreators.setSaveAdminStatus(AddAdminStatus.IDLE));
    
    const orgCode = get(orgCodePayload, "payload", {});
    
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken?
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    yield delay(300);
    const response = yield call(api.get, `/api/organisation/${orgCode}`, {headers:headerParams});
    //console.log(response.data);
    
    yield put(actionCreators.setOrganisationDetailData(response.data));
    yield put(actionCreators.setOrganisationDetailStatus(OrganisationDetailStatus.SUCCESS));
  }
  catch(error) {
    //console.log('error loading org detail data', error);
    yield put(actionCreators.setOrganisationDetailStatus(OrganisationDetailStatus.ERROR));
  }
}

// user detail related
export function* loadOrgUserData(orgUserIdPayload) {
  try {
    // //console.log(orgUserIdPayload);
    yield put(actionCreators.setOrgUserStatus(OrgUserDetailStatus.LOADING));
    // //console.log(orgUserIdPayload);
    const orgUserId = get(orgUserIdPayload, "payload", {});
    
    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken?
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    yield delay(300);
    const response = yield call(api.get, `/api/account/${orgUserId}`, {headers:headerParams});
    //console.log('org user data', response.data);
    
    yield put(actionCreators.setOrgUserData(response.data));
    yield put(actionCreators.setOrgUserStatus(OrgUserDetailStatus.SUCCESS));
  }
  catch(error) {
    yield put(actionCreators.setOrgUserStatus(OrgUserDetailStatus.ERROR));
  }
}

export function* saveOrgUserData(data) {
  try {
    yield put(actionCreators.setSaveOrgUserStatus(AddOrgUserStatus.LOADING));
    let orgDetail = get(data, "0", {});
    let userData = get(data, "payload", {});
    let body;
    if (userData.isEditing) {
      body = {
        'FirstName': get(userData, "firstName", ""),
        'LastName': get(userData, "lastName", ""),
        'EmailAddress': get(userData, "email", ""),
        'OrganisationCode': get(userData, "organisationCode", ""),
        'UserId': get(userData, "userId", ""),
        'SiteCodes': get(userData, "siteCodes", ""),
        'RoleName': get(userData, "roleName", ""),
      };
    } else {
      body = {
        'FirstName': get(userData, "firstName", ""),
        'LastName': get(userData, "lastName", ""),
        'EmailAddress': get(userData, "email", ""),
        'OrganisationCode': get(userData, "organisationCode", ""),
        'SiteCodes': get(userData, "siteCodes", ""),
        'Password': get(userData, "password", ""),
        'RoleName': get(userData, "roleName", ""),
      };
    }
    

    const cookies = new Cookies();
    const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken?
    const headerParams = {
      Authorization: `Bearer ${token}`
    };

    // //console.log(body);
    let response;
    if (!userData.isEditing) {
      response = yield call(api.post, '/api/organisation/register', body, {headers:headerParams});
      orgDetail['Users'].push(response.data);
    } else {
      response = yield call(api.put, '/api/organisation/register', body, {headers:headerParams});
      let userList = orgDetail['Users'].map(x => {
        if (x.UserId == response.data.UserId) {
          return response.data;
        }
        return x;
      });
      orgDetail['Users'] = userList;
    }


    yield put(actionCreators.setOrganisationDetailData(orgDetail));
    yield put(actionCreators.setSaveOrgUserStatus(AddOrgUserStatus.SUCCESS));
    
  }
  catch (error) {
    //console.log(error.response);
    yield put(actionCreators.setErrorStatus(error.response.data));
    yield put(actionCreators.setSaveOrgUserStatus(AddOrgUserStatus.ERROR));
  }
}

